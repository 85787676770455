const getIntegerOptions = (start: number, end: number): string[] => {
  const options: string[] = [];
  for (let i = start; i <= end; i++) {
    options.push(i.toString());
  }
  return options;
};

export const optionsDynamicQuestionnaireSelectInputs = {
  92: getIntegerOptions(0, 9),
};
