import { ApolloError } from '@apollo/client';
import { providerDataResponseEducation } from 'components/dynamicQuestionnaire/interfaces/signUpProviderResponse.interface';
import {
  MultiFactorResolver,
  UserCredential,
  MultiFactorInfo,
} from 'firebase/auth';
import { User } from 'firebase/auth';
import {
  GenerateCustomTokenMutation,
  ValidateCustomTokenMutation,
  GenerateFirebaseCustomTokenMutation,
  ProviderOutput,
  Users,
} from 'graphql/generated/hasura';
import { Dispatch, SetStateAction } from 'react';

export type SignInProvider = 'password' | 'google.com' | 'apple.com' | null;

export interface GoogleUserData {
  email: string;
  displayName: string;
  uid: string;
  token: string;
  logedInWith: SignInProvider;
}
export interface AppleUserData {
  email: string;
  uid: string;
  token: string;
}

export enum Roles {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  ADMIN_OBFUSCATED = 'admin-obfuscated',
  PATIENT = 'patient',
  PROVIDER = 'provider',
}

export enum FIREBASE_ERROR_CODES {
  AUTH_MULTI_FACTOR_AUTH_REQUIRED = 'auth/multi-factor-auth-required',
  AUTH_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  AUTH_INVALID_EMAIL = 'auth/invalid-email',
  AUTH_OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed',
  AUTH_WEAK_PASSWORD = 'auth/weak-password',
  AUTH_INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
  AUTH_REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  AUTH_TOO_MANY_REQUESTS = 'auth/too-many-requests',
  AUTH_INVALID_VERIFICATION_CODE = 'auth/invalid-verification-code',
  AUTH_MISSING_CODE = 'auth/missing-code',
  AUTH_USER_MISMATCH = 'auth/user-mismatch',
  AUTH_WRONG_PASSWORD = 'auth/wrong-password',
  AUTH_USER_NOT_FOUND = 'auth/user-not-found',
  AUTH_SECOND_FACTOR_ALREADY_IN_USE = 'auth/second-factor-already-in-use',
  AUTH_MFA_INFO_NOT_FOUND = 'auth/multi-factor-info-not-found',
  AUTH_MFA_MISSING_CODE = 'auth/missing-code',
  AUTH_CAPTCHA_CHECK_FAILED = 'auth/captcha-check-failed',
  AUTH_TOKEN_INVALID = 'auth/user-token-expired',
}

export interface OptionalUserData {
  dob?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  gender?: string;
  country?: string;
}

export interface GenerateFirebaseCustomTokenArgs {
  handleGenerateFirebaseCustomToken: () => Promise<void>;
  data: GenerateFirebaseCustomTokenMutation | null | undefined;
  error: ApolloError | undefined;
}

export interface GenerateCustomTokenArgs {
  handleGenerateCustomToken: () => Promise<void>;
  data: GenerateCustomTokenMutation | null | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}

export interface ValidateCustomTokenArgs {
  handleValidateCustomToken: (token: string) => Promise<void>;
  data: ValidateCustomTokenMutation | null | undefined;
  error: ApolloError | undefined;
}

export type SignInWithCustomTokenArgs = [
  string,
  (token: string) => Promise<boolean>,
];

export type GoogleSignUpWithCredentialsProvider = [
  Record<string, unknown>,
  string,
  boolean,
  string,
  (
    email: string,
    password: string,
    providerData?: ProviderData,
  ) => Promise<void>,
];

export interface FirebaseLocale {
  emailInUse: string;
  invalidEmail: string;
  notAllowed: string;
  weakPassword: string;
  defaultError: string;
  invalidVerificationCode: string;
  unexpectedError: string;
  failedToSendPasswordEmail: string;
  failedToConfirmPasswordReset: string;
  failedToSendVerificationEmail: string;
  failedToCheckActionCode: string;
  failedToGenerateCustomToken: string;
  invalidPhoneNumber: string;
  requiresRecentLogin: string;
  tooManyRequests: string;
  captchaCheckFailed: string;
  verificationIDFail: string;
  mfaInfoNotFound: string;
  mfaCodeNotProvided: string;
  mfaAuthUserTokenInvalid: string;
}

export interface FirebaseSmsMfa {
  isVerified: boolean;
  setVerified: Dispatch<SetStateAction<boolean>>;
  isCaptchaResolved: boolean;
  handleSendVerificationCode: (
    phoneNumber: string,
    recaptchaContainer: string,
  ) => Promise<void>;
  handleSendVerificationCodeResolver: (
    recaptchaContainer: string,
  ) => Promise<void>;
  handleVerifyCode: (verificationCode: string) => Promise<void>;
  handleVerifyCodeResolver: (
    verificationCode: string,
    deleteAccount: boolean,
  ) => Promise<void | UserCredential>;
  handleVerifyPhoneNumber: (
    currentUser: User,
    phoneNumber: string,
    recaptchaContainer: string,
  ) => Promise<string>;
  handleVerifyPhoneNumberResolver: (
    resolver: MultiFactorResolver,
    recaptchaContainer: string,
  ) => Promise<string>;
  handleResolveSignIn: (
    resolver: MultiFactorResolver,
    verificationCode: string,
    verificationId: string,
  ) => Promise<UserCredential>;
  multifactorEnroll: (
    currentUser: User,
    verificationId: string,
    verificationCode: string,
  ) => Promise<void>;
  errorMsg: string | undefined;
}

export interface CheckMFA {
  getEnrolledFactors: () => false | MultiFactorInfo[];
}

export interface ProviderData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  nationalProviderIdentifier: string;
  gender: string;
  languagesOfCommunication: string[];
  credentials: {
    titles: string[];
    states: string[];
    certifications: string[];
  };
  education: providerDataResponseEducation[];
  office_information: {
    officeName: string;
    email: string;
    phone: string;
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
  };
  address_information: {
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
  };
  about?: string;
}

export interface UserHasuraClaims {
  'x-hasura-allowed-roles'?: string[];
  'x-hasura-default-role'?: string;
}

export interface UserClaims {
  'https://hasura.io/jwt/claims'?: UserHasuraClaims;
}

export interface UserCustomClaims {
  [key: string]: string;
}

export interface CustomClaimsResult {
  hasCustomClaims: boolean;
  role: string;
}

export interface UserDataForProvider {
  email: string;
  password: string;
  providerData: ProviderData;
}

export interface UsersWithRole extends Users {
  role: Roles;
  enabled?: boolean;
}

export interface ProviderOutputWithRole extends ProviderOutput {
  SENSITIVE_COUNTRY: string;
  role: Roles;
}

export interface FirebaseErrorCustomData {
  displayName: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  fullName: string;
}
