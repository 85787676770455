import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  applyActionCode,
  confirmPasswordReset,
  sendEmailVerification,
  signOut,
  sendPasswordResetEmail,
  signInWithCustomToken,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorError,
  OAuthProvider,
  reauthenticateWithPopup,
  MultiFactorResolver,
  User,
  UserCredential,
  updateProfile,
  verifyPasswordResetCode,
} from 'firebase/auth';
import {
  firebaseAuth,
  firebaseGoogleAuthProvider,
  firebaseAppleAuthProvider,
} from './config';
import {
  GoogleUserData,
  AppleUserData,
  Roles,
  OptionalUserData,
  GenerateCustomTokenArgs,
  SignInWithCustomTokenArgs,
  CheckMFA,
  FirebaseLocale,
  FirebaseSmsMfa,
  FIREBASE_ERROR_CODES,
  ValidateCustomTokenArgs,
  GenerateFirebaseCustomTokenArgs,
  GoogleSignUpWithCredentialsProvider,
  ProviderOutputWithRole,
  UsersWithRole,
  CustomClaimsResult,
  FirebaseErrorCustomData,
  UserHasuraClaims,
} from './interfaces';
import {
  useAccountDeletionMutation,
  useGenerateFirebaseCustomTokenMutation,
  useGenerateCustomTokenMutation,
  useGetUsersByEmailLazyQuery,
  User_Auth_Provider_Types_Enum,
  Users,
  useSignupUserMutation,
  useValidateCustomTokenMutation,
  useSignupProviderMutation,
} from 'graphql/generated/hasura';
import { LegalDocument } from 'graphql/generated/strapi';
import { useState, useCallback, useRef, useEffect, useContext } from 'react';
import {
  BLOCKED_LOGIN_EXPIRY_TIME,
  IFRAME_RECAPTCHA_CONTENT,
  LOCAL_STORAGE_LOGIN_BLOCKED_TIME,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { FirebaseError } from 'firebase/app';
import {
  ALLOWED_ROLES_CUSTOM_CLAIM,
  HASURA_USER_STORAGE_KEY,
  JWT_CUSTOM_CLAIMS,
} from './constants';
import { AuthContext, useAuth } from 'auth/context/AuthContext';
import { useAcceptLegalDocument } from 'hooks/legalDocuments/useAcceptLegalDocument';
import { useRegisterAuthProvider } from 'hooks/authProvider/useRegisterAuthProvider';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  AUTH_ACCOUNT_DELETED,
  AUTH_LOGIN,
  AUTH_MFA_REQUIRES_RECENT_LOGIN,
  AUTH_MFA_SETUP,
  AUTH_MFA_VERIFY,
  DASHBOARD,
  MY_ACCOUNT_MFA_ACCOUNT_DELETION,
} from 'utilities/routes';
import { AUTH_SEND_VERIFICATION_EMAIL } from 'utilities/routes';
import { REGISTER_AUTH_PROVIDER_ERRORS } from 'hooks/authProvider/interfaces/authProvider.interfaces';
import {
  useGetFhirPatientbyEmailLazyQuery,
  useGetFhirProviderbyEmailLazyQuery,
  useGetProvidersByPatientIdLazyQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useStrapi } from 'apollo/LocalesProvider';
import { useDataProvider } from 'apollo/DataProvider';
import StorageService from '../utilities/storageService';
import { useRootConfig } from '../utilities/rootConfig';
import queryString from 'query-string';
import { APPOINTMENT_STORAGE } from '../app/my-appointments/components/scheduled-a-appointment/routePath';

const storageBlockTime = new StorageService(LOCAL_STORAGE_LOGIN_BLOCKED_TIME);

export const useFetchUserByEmail = () => {
  const [
    fetchUserByEmail,
    {
      data: hasuraUserData,
      loading: hasuraUserLoading,
      error: hasuraUserError,
    },
  ] = useGetUsersByEmailLazyQuery({
    fetchPolicy: 'network-only',
  });

  const fetchUserByEmailPromise = async (
    email: string,
  ): Promise<Partial<Users>> => {
    const data = await fetchUserByEmail({ variables: { email } });
    if (!data.data?.users.length) {
      throw new Error('User not found in hasura');
    }
    const hasuraUser = data.data.users[0];
    return {
      id: hasuraUser.id,
      SENSITIVE_email: hasuraUser.SENSITIVE_email,
      SENSITIVE_profile_picture_id: hasuraUser.SENSITIVE_profile_picture_id,
      SENSITIVE_lastname: hasuraUser.SENSITIVE_lastname,
      SENSITIVE_firstname: hasuraUser.SENSITIVE_firstname,
      SENSITIVE_phone: hasuraUser.SENSITIVE_phone,
      SENSITIVE_country: hasuraUser.SENSITIVE_country,
    };
  };

  return {
    hasuraUserData,
    hasuraUserError,
    hasuraUserLoading,
    fetchUserByEmailPromise,
  };
};

export const useFetchUserFromHasuraOrFhir = () => {
  const [
    getFhirPatientByEmail,
    { data: fhirUserData, loading: fhirUserLoading, error: fhirUserError },
  ] = useGetFhirPatientbyEmailLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getFhirProviderByEmail] = useGetFhirProviderbyEmailLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getProviderByPatientId] = useGetProvidersByPatientIdLazyQuery({
    fetchPolicy: 'network-only',
  });

  const fetchProvidersByPatientId = async (id: string, role: Roles) => {
    if (role !== Roles.PATIENT) {
      return undefined;
    }
    const data = await getProviderByPatientId({
      variables: { patientCodexId: id },
    });

    if (!data.data?.getProvidersByPatientId?.providers?.length) {
      return undefined;
    }

    const fhirProvider = data.data.getProvidersByPatientId?.providers[0];
    return fhirProvider.id;
  };

  const fetchUserDataFromDifferentSource = async (
    email: string,
    role: Roles,
  ): Promise<Partial<UsersWithRole | ProviderOutputWithRole>> => {
    if (role === Roles.PROVIDER) {
      const data = await getFhirProviderByEmail({ variables: { email } });

      if (!data.data?.getFHIRProviderbyEmail?.providers?.length) {
        throw new Error('Provider not found in fhir');
      }

      const fhirProvider = data.data.getFHIRProviderbyEmail?.providers[0];

      return {
        id: fhirProvider.id,
        SENSITIVE_email: fhirProvider.SENSITIVE_email,
        SENSITIVE_profile_picture_id: fhirProvider.SENSITIVE_profile_picture_id,
        SENSITIVE_lastname: fhirProvider.SENSITIVE_lastname,
        SENSITIVE_firstname: fhirProvider.SENSITIVE_firstname,
        SENSITIVE_phone: fhirProvider.SENSITIVE_phone,
        SENSITIVE_gender: fhirProvider.SENSITIVE_gender,
        role: role,
        enabled: fhirProvider.enabled,
      } as unknown as ProviderOutputWithRole;
    } else {
      const data = await getFhirPatientByEmail({ variables: { email } });
      if (!data.data?.getFHIRPatientbyEmail?.users?.length) {
        throw new Error('Patient User not found in fhir');
      }

      const fhirPatient = data.data.getFHIRPatientbyEmail.users[0];
      return {
        id: fhirPatient.id,
        SENSITIVE_email: fhirPatient.SENSITIVE_email,
        SENSITIVE_profile_picture_id: fhirPatient.SENSITIVE_profile_picture_id,
        SENSITIVE_lastname: fhirPatient.SENSITIVE_lastname,
        SENSITIVE_firstname: fhirPatient.SENSITIVE_firstname,
        SENSITIVE_country: fhirPatient.SENSITIVE_country,
        SENSITIVE_phone: fhirPatient.SENSITIVE_phone,
        SENSITIVE_dob: fhirPatient.SENSITIVE_dob,
        SENSITIVE_gender: fhirPatient.SENSITIVE_gender,
        SENSITIVE_self_identity_gender:
          fhirPatient.SENSITIVE_self_identity_gender,
        role: role || Roles.PATIENT,
      };
    }
  };

  return {
    fhirUserData,
    fhirUserError,
    fhirUserLoading,
    fetchUserDataFromDifferentSource,
    fetchProvidersByPatientId,
  };
};

export const useSignupUserInHasura = () => {
  const [signupUsersMutation, { error: registerUserError }] =
    useSignupUserMutation();

  const signupUserInHasura = async (
    email: string,
    token: string,
    optionalUserData?: OptionalUserData,
  ): Promise<void> => {
    try {
      const response = await signupUsersMutation({
        variables: {
          email: email,
          firebaseToken: token,
          ...optionalUserData,
        },
      });
      if (!response.data) {
        throw new Error('Failed to create user');
      }
    } catch (error: unknown) {
      throw new Error('Failed to create user');
    }
  };

  return { signupUserInHasura, registerUserError };
};

export const useSignupProviderInHasura = () => {
  const [signupProviderMutation, { error: registerUserProviderError }] =
    useSignupProviderMutation();

  const signupProviderInHasura = async (
    email: string,
    firebaseToken: string,
  ): Promise<void> => {
    try {
      const response = await signupProviderMutation({
        variables: {
          email,
          firebaseToken: firebaseToken,
        },
      });
      if (!response.data) {
        throw new Error('Failed to create provider');
      }
    } catch (error: unknown) {
      console.error('Failed to create provider', error);
    }
  };

  return { signupProviderInHasura, registerUserProviderError };
};

export const useGoogleAuthSignInPopUp = (): [
  GoogleUserData | object,
  string,
  (documents: LegalDocument[]) => Promise<void>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [userData, setUserData] = useState<GoogleUserData | object>({});
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { signupUserInHasura, registerUserError } = useSignupUserInHasura();
  const { login, loginWithOutMFA, setUserUuid, setPhoneNumber, setCountry } =
    useAuth();
  const { acceptUnacceptedDocuments, acceptLegalDocumentError } =
    useAcceptLegalDocument();
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  const { registerAuthProvider, registerProviderError } =
    useRegisterAuthProvider();
  const navigate = useNavigate();
  const { setMfaResolver } = useContext(AuthContext);

  const handleGoogleSignIn = async (
    documents: LegalDocument[],
  ): Promise<void> => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const result = await signInWithPopup(
        firebaseAuth,
        firebaseGoogleAuthProvider,
      );

      if (result.user.email) {
        const tokenRes = await result.user.getIdTokenResult();
        setToken(tokenRes.token);
        setErrorMsg('');
        setUserData({
          displayName: result.user.displayName,
          email: result.user.email,
          uid: result.user.uid,
        });
        const isAlreadyRegistered = await userHasCustomClaims();
        if (!isAlreadyRegistered) {
          await signupUserInHasura(result.user.email, tokenRes.token);
          await result.user.getIdToken(true);
        }
        const user = await fetchUserByEmailPromise(result.user.email);
        await registerAuthProvider(User_Auth_Provider_Types_Enum.Google);
        await acceptUnacceptedDocuments(documents, user.id);
        setUserUuid(user.id);

        if (user.SENSITIVE_phone) {
          setPhoneNumber(user.SENSITIVE_phone);
        }

        if (user.SENSITIVE_country) {
          setCountry(user.SENSITIVE_country);
        }

        loginWithOutMFA(DASHBOARD);
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED: {
            const userEmail = (
              error?.customData?._serverResponse as FirebaseErrorCustomData
            ).email;
            const resolver = getMultiFactorResolver(
              firebaseAuth,
              error as MultiFactorError,
            );

            setMfaResolver(resolver);
            const deepLink = location.search;
            navigate(`${AUTH_MFA_VERIFY}${deepLink ? deepLink : ''}`, {
              state: { email: userEmail },
            });

            break;
          }
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        error.message ===
        REGISTER_AUTH_PROVIDER_ERRORS.AUTH_PROVIDER_ALREADY_EXISTS
          ? login(AUTH_MFA_SETUP)
          : setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
    if (registerUserError) {
      setErrorMsg(registerUserError.message);
    }
    if (acceptLegalDocumentError) {
      setErrorMsg(acceptLegalDocumentError.message);
    }
    if (registerProviderError) {
      setErrorMsg(registerProviderError.message);
    }
  };
  return [{ ...userData, token }, errorMsg, handleGoogleSignIn];
};

export const useGoogleAuthProviderSignInPopUp = (): [
  GoogleUserData | object,
  string,
  (documents: LegalDocument[]) => Promise<void>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [userData, setUserData] = useState<GoogleUserData | object>({});
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { registerUserProviderError, signupProviderInHasura } =
    useSignupProviderInHasura();
  const { loginWithOutMFA, setUserUuid, setPhoneNumber, setCountry } =
    useAuth();
  const { acceptUnacceptedDocuments, acceptLegalDocumentError } =
    useAcceptLegalDocument();
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  const { registerAuthProvider, registerProviderError } =
    useRegisterAuthProvider();
  const navigate = useNavigate();
  const { setMfaResolver } = useContext(AuthContext);

  const handleGoogleSignIn = async (
    documents: LegalDocument[],
  ): Promise<void> => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const result = await signInWithPopup(
        firebaseAuth,
        firebaseGoogleAuthProvider,
      );

      if (result.user.email) {
        const tokenRes = await result.user.getIdTokenResult();
        setToken(tokenRes.token);
        setErrorMsg('');
        setUserData({
          email: result.user.email,
          uid: result.user.uid,
        });
        const isAlreadyRegistered = await userHasCustomClaims();
        if (!isAlreadyRegistered) {
          await signupProviderInHasura(result.user.email, tokenRes.token);
          await result.user.getIdToken(true);
        }
        const user = await fetchUserByEmailPromise(result.user.email);
        await registerAuthProvider(User_Auth_Provider_Types_Enum.Google);
        await acceptUnacceptedDocuments(documents, user.id);
        setUserUuid(user.id);

        if (user.SENSITIVE_phone) {
          setPhoneNumber(user.SENSITIVE_phone);
        }

        if (user.SENSITIVE_country) {
          setCountry(user.SENSITIVE_country);
        }

        loginWithOutMFA(DASHBOARD);
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED: {
            const userEmail = (
              error?.customData?._serverResponse as FirebaseErrorCustomData
            ).email;
            const resolver = getMultiFactorResolver(
              firebaseAuth,
              error as MultiFactorError,
            );

            setMfaResolver(resolver);
            const deepLink = location.search;
            navigate(`${AUTH_MFA_VERIFY}${deepLink ? deepLink : ''}`, {
              state: { email: userEmail },
            });

            break;
          }
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        error.message ===
        REGISTER_AUTH_PROVIDER_ERRORS.AUTH_PROVIDER_ALREADY_EXISTS
          ? loginWithOutMFA(DASHBOARD)
          : setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
    if (registerUserProviderError) {
      setErrorMsg(registerUserProviderError.message);
    }
    if (acceptLegalDocumentError) {
      setErrorMsg(acceptLegalDocumentError.message);
    }
    if (registerProviderError) {
      setErrorMsg(registerProviderError.message);
    }
  };
  return [{ ...userData, token }, errorMsg, handleGoogleSignIn];
};

export const useGoogleSignupWithCredentials = (): [
  GoogleUserData | object,
  string,
  (
    email: string,
    password: string,
    documents: LegalDocument[],
    optionalUserData?: OptionalUserData,
  ) => Promise<void>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [userData, setUserData] = useState<GoogleUserData | object>({});
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { signupUserInHasura, registerUserError } = useSignupUserInHasura();
  const { acceptUnacceptedDocuments, acceptLegalDocumentError } =
    useAcceptLegalDocument();
  const [, handleSendVerificationEmail] = useSendVerificationEmail();
  const { registerAuthProvider, registerProviderError } =
    useRegisterAuthProvider();
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  let { baseUrl } = useRootConfig();
  const location = useLocation();
  const { fetchUserDataFromDifferentSource } = useFetchUserFromHasuraOrFhir();
  const hasuraUserStorage = new StorageService(HASURA_USER_STORAGE_KEY);

  const handleGoogleSignupWithCredentials = async (
    email: string,
    password: string,
    documents: LegalDocument[],
    optionalUserData?: OptionalUserData,
  ): Promise<void> => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password,
      );

      await updateProfile(userCredential.user, {
        displayName:
          optionalUserData?.firstname + ' ' + optionalUserData?.lastname,
      });

      const { source } = queryString.parse(location.search);
      if (source) {
        baseUrl = `${baseUrl}?source=${encodeURIComponent(source.toString())}`;
      }
      await sendEmailVerification(userCredential.user, {
        url: baseUrl,
        handleCodeInApp: true,
      });

      if (userCredential.user.email) {
        setUserData({
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          uid: userCredential.user.uid,
          ...optionalUserData,
        });
        setErrorMsg('');
        const tokenRes = await userCredential.user.getIdTokenResult();
        setToken(tokenRes.token);
        await signupUserInHasura(email, tokenRes.token, optionalUserData);
        await handleSendVerificationEmail();
        await userCredential.user.getIdToken(true);
        await registerAuthProvider(User_Auth_Provider_Types_Enum.Email);
        const user = await fetchUserByEmailPromise(email);
        const userDataFromSource = await fetchUserDataFromDifferentSource(
          userCredential.user.email || '',
          Roles.PATIENT,
        );
        hasuraUserStorage.setData({ ...user, ...userDataFromSource });
        await acceptUnacceptedDocuments(documents, user.id);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_EMAIL_ALREADY_IN_USE:
            setErrorMsg(locale?.emailInUse);
            break;
          case FIREBASE_ERROR_CODES.AUTH_INVALID_EMAIL:
            setErrorMsg(locale?.invalidEmail);
            break;
          case FIREBASE_ERROR_CODES.AUTH_OPERATION_NOT_ALLOWED:
            setErrorMsg(locale?.notAllowed);
            break;
          case FIREBASE_ERROR_CODES.AUTH_WEAK_PASSWORD:
            setErrorMsg(locale?.weakPassword);
            break;
          default:
            setErrorMsg(locale?.defaultError);
            break;
        }
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      }
    }
  };
  if (registerUserError) {
    setErrorMsg(registerUserError.message);
  }
  if (acceptLegalDocumentError) {
    setErrorMsg(acceptLegalDocumentError.message);
  }
  if (registerProviderError) {
    setErrorMsg(registerProviderError.message);
  }
  return [{ ...userData, token }, errorMsg, handleGoogleSignupWithCredentials];
};

export const useGoogleSignupWithCredentialsForProvider =
  (): GoogleSignUpWithCredentialsProvider => {
    const { data: locale } = useGetPage({
      locale: 'en',
      pageId: pageIds.FIREBASE_ERRORS,
    });
    const [userData, setUserData] = useState<Record<string, unknown>>();
    const [token, setToken] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [errorCode, setErrorCode] = useState<string>('');
    const { signupProviderInHasura, registerUserProviderError } =
      useSignupProviderInHasura();
    const [, handleSendVerificationEmail] = useSendVerificationEmail();
    const { registerAuthProvider, registerProviderError } =
      useRegisterAuthProvider();

    const [loading, setLoading] = useState(false);

    const handleGoogleSignupWithCredentials = async (
      email: string,
      password: string,
    ): Promise<void> => {
      try {
        setLoading(true);
        await setPersistence(firebaseAuth, browserLocalPersistence);
        const userCredential = await createUserWithEmailAndPassword(
          firebaseAuth,
          email,
          password,
        );
        if (userCredential.user.email) {
          setUserData({
            uid: userCredential.user.uid,
          });
          setErrorMsg('');
          setErrorCode('');
          const tokenRes = await userCredential.user.getIdTokenResult();
          setToken(tokenRes.token);
          await signupProviderInHasura(email, tokenRes.token);
          await handleSendVerificationEmail(true);
          await userCredential.user.getIdToken(true);
          await registerAuthProvider(User_Auth_Provider_Types_Enum.Email);
          setLoading(false);
        } else {
          setErrorMsg(locale?.unexpectedError);
          setLoading(false);
        }
      } catch (error: unknown) {
        setErrorMsg(locale?.unexpectedError);
        setLoading(false);
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case FIREBASE_ERROR_CODES.AUTH_EMAIL_ALREADY_IN_USE:
              setErrorMsg(locale?.emailInUse);
              break;
            case FIREBASE_ERROR_CODES.AUTH_INVALID_EMAIL:
              setErrorMsg(locale?.invalidEmail);
              break;
            case FIREBASE_ERROR_CODES.AUTH_OPERATION_NOT_ALLOWED:
              setErrorMsg(locale?.notAllowed);
              break;
            case FIREBASE_ERROR_CODES.AUTH_WEAK_PASSWORD:
              setErrorMsg(locale?.weakPassword);
              break;
            default:
              setErrorMsg(locale?.defaultError);
              console.error(error);
              break;
          }
          setErrorCode(error.code);
        } else if (error instanceof Error) {
          setErrorMsg(error.message);
        } else {
          setErrorMsg(locale?.unexpectedError);
        }
        setLoading(false);
      }
    };
    if (registerUserProviderError) {
      setErrorMsg(registerUserProviderError.message);
      setLoading(false);
    }
    if (registerProviderError) {
      setErrorMsg(registerProviderError.message);
      setLoading(false);
    }

    return [
      { ...userData, token },
      errorMsg,
      loading,
      errorCode,
      handleGoogleSignupWithCredentials,
    ];
  };

export const useGoogleSigninWithCredentials = (): [
  GoogleUserData | object,
  string,
  (email: string, password: string) => Promise<boolean>,
] => {
  const location = useLocation();
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [userData, setUserData] = useState<GoogleUserData | object>({});
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { login, loginWithOutMFA, setUserUuid, setPhoneNumber, setCountry } =
    useAuth();
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  const navigate = useNavigate();
  const { setMfaResolver } = useContext(AuthContext);
  const handleGoogleSigninWithCredentials = async (
    email: string,
    password: string,
  ): Promise<boolean> => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password,
      );

      if (userCredential.user.emailVerified) {
        if (userCredential.user.email) {
          setUserData({
            displayName: userCredential.user.displayName,
            email: userCredential.user.email,
            uid: userCredential.user.uid,
          });
          setErrorMsg('');
          const tokenRes = await userCredential.user.getIdTokenResult();
          setToken(tokenRes.token);
          const user = await fetchUserByEmailPromise(userCredential.user.email);
          setUserUuid(user.id);

          if (user.SENSITIVE_phone) {
            setPhoneNumber(user.SENSITIVE_phone);
          }

          if (user.SENSITIVE_country) {
            setCountry(user.SENSITIVE_country);
          }

          if (process.env.REACT_APP_IS_DEBUG_MODE) {
            loginWithOutMFA(DASHBOARD);
            return true;
          }

          login(AUTH_MFA_SETUP);
          return true;
        }
      } else {
        navigate(`${AUTH_SEND_VERIFICATION_EMAIL}?accountNotVerified=true`);
      }

      return false;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED: {
            const resolver = getMultiFactorResolver(
              firebaseAuth,
              error as MultiFactorError,
            );

            setMfaResolver(resolver);
            const deepLink = location.search;
            navigate(`${AUTH_MFA_VERIFY}${deepLink ? deepLink : ''}`, {
              state: { email },
            });

            break;
          }
          case FIREBASE_ERROR_CODES.AUTH_WRONG_PASSWORD:
          case FIREBASE_ERROR_CODES.AUTH_USER_NOT_FOUND:
            setErrorMsg(locale?.invalidCredentials);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS:
            storageBlockTime.setData(BLOCKED_LOGIN_EXPIRY_TIME);
            setErrorMsg(locale?.tooManyRequests);
            break;
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
      return false;
    }
  };
  return [{ ...userData, token }, errorMsg, handleGoogleSigninWithCredentials];
};

/**
 * Apple Auth Hooks
 *
 */

export const useAppleAuthSignInPopUp = (): [
  AppleUserData | object,
  string,
  (documents: LegalDocument[]) => Promise<void>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [userData, setUserData] = useState<GoogleUserData | object>({});
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { signupUserInHasura, registerUserError } = useSignupUserInHasura();
  const { login, loginWithOutMFA, setUserUuid, setPhoneNumber, setCountry } =
    useAuth();
  const { acceptUnacceptedDocuments, acceptLegalDocumentError } =
    useAcceptLegalDocument();
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  const { registerAuthProvider, registerProviderError } =
    useRegisterAuthProvider();
  const { setMfaResolver } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleAppleSignIn = async (
    documents: LegalDocument[],
  ): Promise<void> => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const result = await signInWithPopup(
        firebaseAuth,
        firebaseAppleAuthProvider,
      );

      if (result.user.email) {
        const tokenRes = await result.user.getIdTokenResult();
        setToken(tokenRes.token);
        setErrorMsg('');
        setUserData({
          email: result.user.email,
          uid: result.user.uid,
        });

        const isAlreadyRegistered = await userHasCustomClaims();
        if (!isAlreadyRegistered) {
          await signupUserInHasura(result.user.email, tokenRes.token);
          await result.user.getIdToken(true);
        }
        const user = await fetchUserByEmailPromise(result.user.email);
        await registerAuthProvider(User_Auth_Provider_Types_Enum.Apple);
        await acceptUnacceptedDocuments(documents, user.id);
        setUserUuid(user.id);

        if (user.SENSITIVE_phone) {
          setPhoneNumber(user.SENSITIVE_phone);
        }

        if (user.SENSITIVE_country) {
          setCountry(user.SENSITIVE_country);
        }

        loginWithOutMFA(DASHBOARD);
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED: {
            const userEmail = (
              error?.customData?._serverResponse as FirebaseErrorCustomData
            ).email;
            const resolver = getMultiFactorResolver(
              firebaseAuth,
              error as MultiFactorError,
            );

            setMfaResolver(resolver);
            const deepLink = location.search;
            navigate(`${AUTH_MFA_VERIFY}${deepLink ? deepLink : ''}`, {
              state: { email: userEmail },
            });

            break;
          }
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        error.message ===
        REGISTER_AUTH_PROVIDER_ERRORS.AUTH_PROVIDER_ALREADY_EXISTS
          ? login(AUTH_MFA_SETUP)
          : setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
    if (registerUserError) {
      setErrorMsg(registerUserError.message);
    }
    if (acceptLegalDocumentError) {
      setErrorMsg(acceptLegalDocumentError.message);
    }
    if (registerProviderError) {
      setErrorMsg(registerProviderError.message);
    }
  };
  return [{ ...userData, token }, errorMsg, handleAppleSignIn];
};

export const useAppleAuthProviderSignInPopUp = (): [
  AppleUserData | object,
  string,
  (documents: LegalDocument[]) => Promise<void>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [userData, setUserData] = useState<GoogleUserData | object>({});
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { registerUserProviderError, signupProviderInHasura } =
    useSignupProviderInHasura();
  const { loginWithOutMFA, setUserUuid, setPhoneNumber, setCountry } =
    useAuth();
  const { acceptUnacceptedDocuments, acceptLegalDocumentError } =
    useAcceptLegalDocument();
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  const { registerAuthProvider, registerProviderError } =
    useRegisterAuthProvider();
  const { setMfaResolver } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleAppleSignIn = async (
    documents: LegalDocument[],
  ): Promise<void> => {
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence);
      const result = await signInWithPopup(
        firebaseAuth,
        firebaseAppleAuthProvider,
      );

      if (result.user.email) {
        const tokenRes = await result.user.getIdTokenResult();
        setToken(tokenRes.token);
        setErrorMsg('');
        setUserData({
          email: result.user.email,
          uid: result.user.uid,
        });

        const isAlreadyRegistered = await userHasCustomClaims();
        if (!isAlreadyRegistered) {
          await signupProviderInHasura(result.user.email, tokenRes.token);
          await result.user.getIdToken(true);
        }
        const user = await fetchUserByEmailPromise(result.user.email);
        await registerAuthProvider(User_Auth_Provider_Types_Enum.Apple);
        await acceptUnacceptedDocuments(documents, user.id);
        setUserUuid(user.id);

        if (user.SENSITIVE_phone) {
          setPhoneNumber(user.SENSITIVE_phone);
        }

        if (user.SENSITIVE_country) {
          setCountry(user.SENSITIVE_country);
        }

        loginWithOutMFA(DASHBOARD);
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED: {
            const userEmail = (
              error?.customData?._serverResponse as FirebaseErrorCustomData
            ).email;
            const resolver = getMultiFactorResolver(
              firebaseAuth,
              error as MultiFactorError,
            );

            setMfaResolver(resolver);
            const deepLink = location.search;
            navigate(`${AUTH_MFA_VERIFY}${deepLink ? deepLink : ''}`, {
              state: { email: userEmail },
            });

            break;
          }
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        error.message ===
        REGISTER_AUTH_PROVIDER_ERRORS.AUTH_PROVIDER_ALREADY_EXISTS
          ? loginWithOutMFA(DASHBOARD)
          : setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
    if (registerUserProviderError) {
      setErrorMsg(registerUserProviderError.message);
    }
    if (acceptLegalDocumentError) {
      setErrorMsg(acceptLegalDocumentError.message);
    }
    if (registerProviderError) {
      setErrorMsg(registerProviderError.message);
    }
  };
  return [{ ...userData, token }, errorMsg, handleAppleSignIn];
};

export const usePasswordResetEmail = (
  skipNotFoundUserError?: boolean,
): [string, (email: string) => Promise<boolean>, boolean] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handlePasswordResetEmail = async (email: string): Promise<boolean> => {
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(firebaseAuth, email, {
        url: `${window.location.origin}?email=${email}`,
      });
      setErrorMsg('');
      return true;
    } catch (error: unknown) {
      const code = error instanceof FirebaseError ? error.code : '';

      if (code === FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS) {
        setErrorMsg(locale?.exceededLimit);
        return false;
      }

      if (
        code === FIREBASE_ERROR_CODES.AUTH_USER_NOT_FOUND &&
        skipNotFoundUserError
      ) {
        return false;
      }

      setErrorMsg(locale?.failedToSendPasswordEmail);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  return [errorMsg, handlePasswordResetEmail, isLoading];
};

export const useConfirmPasswordReset = (): [
  string,
  (code: string, newPassword: string) => Promise<boolean>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleConfirmPasswordReset = async (
    code: string,
    newPassword: string,
  ): Promise<boolean> => {
    try {
      await confirmPasswordReset(firebaseAuth, code, newPassword);
      return true;
    } catch (error: unknown) {
      setErrorMsg(locale?.failedToConfirmPasswordReset);
      return false;
    }
  };
  return [errorMsg, handleConfirmPasswordReset];
};

export const useFirebaseSignOut = () => {
  const navigate = useNavigate();
  const { client: strapi } = useStrapi();
  const { client: hasura } = useDataProvider();
  const appointmentStorage = new StorageService(
    APPOINTMENT_STORAGE,
    sessionStorage,
  );
  const signOutUser = async () => {
    try {
      await hasura.clearStore();
      appointmentStorage.clear();
      strapi.clearStore();
      await signOut(firebaseAuth);
      navigate(AUTH_LOGIN);
    } catch (error: unknown) {
      console.error('Error signing out: ', error);
    }
  };

  return signOutUser;
};

export const getTokenFromFirebase = async () => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      return token;
    }
  } catch (error: unknown) {
    console.error('Error when try to fetch token from Firebase:', error);
  }
  return null;
};

export const userHasCustomClaims = async (): Promise<boolean> => {
  try {
    const user = firebaseAuth.currentUser;

    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      const claims = idTokenResult.claims;
      return JWT_CUSTOM_CLAIMS in claims;
    }
    return false;
  } catch (error: unknown) {
    console.error('Error when try to get id token:', error);
    return false;
  }
};

export const userHasCustomClaimsAndRole =
  async (): Promise<CustomClaimsResult> => {
    try {
      const user = firebaseAuth.currentUser;

      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;

        const customClaims = claims[JWT_CUSTOM_CLAIMS] as UserHasuraClaims;

        if (
          customClaims &&
          Object.prototype.hasOwnProperty.call(
            customClaims,
            ALLOWED_ROLES_CUSTOM_CLAIM,
          )
        ) {
          const allowedRoles = customClaims[ALLOWED_ROLES_CUSTOM_CLAIM] || [];
          const hasCustomClaims = JWT_CUSTOM_CLAIMS in claims;
          const role =
            allowedRoles.length > 0
              ? allowedRoles[allowedRoles.length - 1]
              : '';

          return { hasCustomClaims, role };
        }
      }
      return { hasCustomClaims: false, role: '' };
    } catch (error: unknown) {
      console.error('Error when trying to get id token:', error);
      return { hasCustomClaims: false, role: '' };
    }
  };

export const useSendVerificationEmail = (): [
  string,
  (isProvider?: boolean) => Promise<boolean>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleSendVerificationEmail = async (
    isProvider?: boolean,
  ): Promise<boolean> => {
    try {
      const user = firebaseAuth.currentUser;
      if (user) {
        isProvider
          ? await sendEmailVerification(user, {
              url: `${window.location.origin}/auth/sign-up-provider`,
            })
          : await sendEmailVerification(user);

        setErrorMsg('');
        return true;
      } else {
        setErrorMsg(locale?.failedToSendVerificationEmail);
        return false;
      }
    } catch (error: unknown) {
      setErrorMsg(locale?.failedToSendVerificationEmail);
      return false;
    }
  };
  return [errorMsg, handleSendVerificationEmail];
};

export const useVerifyActionCode = (): [
  string,
  (oobCode: string) => Promise<boolean>,
] => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleVerifyActionCode = async (oobCode: string): Promise<boolean> => {
    try {
      await applyActionCode(firebaseAuth, oobCode);
      setErrorMsg('');
      return true;
    } catch (error: unknown) {
      setErrorMsg(locale?.failedToCheckActionCode);
      return false;
    }
  };
  return [errorMsg, handleVerifyActionCode];
};

export const useVerifyPasswordResetCode = (): [
  (oobCode: string) => Promise<boolean>,
] => {
  const handleVerifyPasswordResetCode = async (
    oobCode: string,
  ): Promise<boolean> => {
    try {
      await verifyPasswordResetCode(firebaseAuth, oobCode);
      return true;
    } catch (error: unknown) {
      return false;
    }
  };
  return [handleVerifyPasswordResetCode];
};

export const useGenerateFirebaseCustomToken =
  (): GenerateFirebaseCustomTokenArgs => {
    const { data: locale } = useGetPage({
      locale: 'en',
      pageId: pageIds.FIREBASE_ERRORS,
    });
    const [generateFirebaseCustomTokenMutation, { data, error }] =
      useGenerateFirebaseCustomTokenMutation();

    const handleGenerateFirebaseCustomToken = useCallback(async () => {
      try {
        const user = firebaseAuth.currentUser;
        if (user) {
          const token = (await user.getIdTokenResult()).token;
          const response = await generateFirebaseCustomTokenMutation({
            variables: {
              firebaseToken: token,
            },
          });
          if (!response.data) {
            throw new Error(locale?.failedToGenerateCustomToken);
          }
        }
      } catch (error: unknown) {
        throw new Error(locale?.failedToGenerateCustomToken);
      }
    }, [generateFirebaseCustomTokenMutation, locale]);

    return { handleGenerateFirebaseCustomToken, data, error };
  };

export const useGenerateCustomToken = (): GenerateCustomTokenArgs => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [generateCustomTokenMutation, { data, error, loading }] =
    useGenerateCustomTokenMutation();

  const handleGenerateCustomToken = useCallback(async () => {
    try {
      const user = firebaseAuth.currentUser;
      if (user) {
        const response = await generateCustomTokenMutation();
        if (!response.data) {
          throw new Error(locale?.failedToGenerateCustomToken);
        }
      }
    } catch (error: unknown) {
      throw new Error(locale?.failedToGenerateCustomToken);
    }
  }, [generateCustomTokenMutation, locale]);

  return { handleGenerateCustomToken, data, error, loading };
};

export const useValidateCustomToken = (): ValidateCustomTokenArgs => {
  const [validateCustomTokenMutation, { data, error }] =
    useValidateCustomTokenMutation();

  const handleValidateCustomToken = useCallback(
    async (token: string) => {
      try {
        const response = await validateCustomTokenMutation({
          variables: {
            token,
          },
        });

        if (!response.data) {
          console.error('There was an error when validating custom token');
          throw new Error('Custom token validation failed');
        }
      } catch (error: unknown) {
        console.error('There was an error when validating custom token');
        throw new Error('Custom token validation failed');
      }
    },
    [validateCustomTokenMutation],
  );

  return { handleValidateCustomToken, data, error };
};

export const useSigninWithCustomToken = (): SignInWithCustomTokenArgs => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleSigninWithCustomToken = async (
    token: string,
  ): Promise<boolean> => {
    try {
      await signInWithCustomToken(firebaseAuth, token);
      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
      return false;
    }
  };
  return [errorMsg, handleSigninWithCustomToken];
};

export const userHasAllowedRole = async (
  allowedRoles: Roles[],
): Promise<boolean> => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      const claims = idTokenResult.claims;
      if (JWT_CUSTOM_CLAIMS in claims) {
        const customClaims = claims[JWT_CUSTOM_CLAIMS] as UserHasuraClaims;
        const userAllowedRoles = customClaims[ALLOWED_ROLES_CUSTOM_CLAIM];
        return allowedRoles.some((role) => userAllowedRoles?.includes(role));
      } else {
        return false;
      }
    }
    return false;
  } catch (error: unknown) {
    return false;
  }
};

export const userHasRequiredRole = async (
  requiredRole: Roles,
): Promise<boolean> => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      const claims = idTokenResult.claims;
      if (JWT_CUSTOM_CLAIMS in claims) {
        const customClaims = claims[JWT_CUSTOM_CLAIMS] as UserHasuraClaims;
        const userAllowedRoles = customClaims[ALLOWED_ROLES_CUSTOM_CLAIM] ?? [];
        return userAllowedRoles.includes(requiredRole);
      } else {
        return false;
      }
    }
    return false;
  } catch (error: unknown) {
    return false;
  }
};

export const useFirebaseSmsMfa = (
  locale: FirebaseLocale | undefined,
  errorCallback?: () => void,
): FirebaseSmsMfa => {
  const [isVerified, setVerified] = useState<boolean>(false);
  const [isCaptchaResolved, setIsCaptchaResolved] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string | undefined>('');
  const signOutUser = useFirebaseSignOut();
  const recaptchaVerifierInstance = useRef<RecaptchaVerifier | null>(null);
  const { mfaResolver: resolver } = useContext(AuthContext);
  const [accountDeletionMutation] = useAccountDeletionMutation({});

  const navigate = useNavigate();

  let intervalId: string | number | NodeJS.Timer | undefined;
  let observer: MutationObserver | null = null;

  const removeRecaptchaWindow = () => {
    const recaptchaIframes = document.getElementsByTagName('iframe');
    const recaptchaWindows = [...recaptchaIframes]?.filter((x) =>
      x.src.includes(IFRAME_RECAPTCHA_CONTENT),
    );

    recaptchaWindows.forEach((iframe) => {
      iframe.remove();
    });
  };

  const findRecaptchaWindow = () => {
    const recaptchaIframes = document.getElementsByTagName('iframe');
    const recaptchaWindow = [...recaptchaIframes]?.find((x) =>
      x.src.includes(IFRAME_RECAPTCHA_CONTENT),
    )?.parentNode?.parentNode as HTMLDivElement;

    return recaptchaWindow;
  };

  const handlePopState = () => {
    const recaptchaWindow = findRecaptchaWindow();

    if (intervalId) {
      clearInterval(+intervalId);
    }

    if (observer) {
      observer.disconnect();
    }

    if (recaptchaWindow) {
      recaptchaWindow.remove();
    }

    window.removeEventListener('popstate', handlePopState);
  };

  const initObserver = () => {
    let recaptchaWindow = findRecaptchaWindow();

    if (!recaptchaWindow) {
      intervalId = setInterval(() => {
        recaptchaWindow = findRecaptchaWindow();
        if (recaptchaWindow && intervalId) {
          clearInterval(+intervalId);
          setupMutationObserver(recaptchaWindow);
        }
      }, 500);
    } else {
      setupMutationObserver(recaptchaWindow);
    }

    window.addEventListener('popstate', handlePopState);
  };

  const setupMutationObserver = (recaptchaWindow: HTMLDivElement) => {
    if (recaptchaWindow) {
      observer = new MutationObserver(() => {
        if (
          recaptchaWindow.style.visibility !== 'visible' ||
          recaptchaWindow.style.opacity !== '1'
        ) {
          recaptchaWindow.style.opacity = '1';
          recaptchaWindow.style.visibility = 'visible';
        }
      });

      observer.observe(recaptchaWindow, {
        attributeFilter: ['style'],
      });
    }
  };

  const handleVerifyPhoneNumber = async (
    currentUser: User,
    phoneNumber: string,
    recaptchaContainer: string,
  ) => {
    if (recaptchaVerifierInstance.current === null) {
      removeRecaptchaWindow();

      recaptchaVerifierInstance.current = new RecaptchaVerifier(
        firebaseAuth,
        recaptchaContainer,
        {
          size: 'invisible',
          callback: () => {
            setIsCaptchaResolved(true);
          },
          'expired-callback': () => {
            setIsCaptchaResolved(false);
          },
        },
      );
    }

    const multiFactorSession = await multiFactor(currentUser).getSession();
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession,
    };

    const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);

    const verificationIdPromise = phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifierInstance.current,
    );

    initObserver();

    const verificationId = await verificationIdPromise;

    return verificationId;
  };

  const handleVerifyPhoneNumberResolver = async (
    resolver: MultiFactorResolver,
    recaptchaContainer: string,
  ) => {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints.at(-1),
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);

    if (recaptchaVerifierInstance.current === null) {
      removeRecaptchaWindow();

      recaptchaVerifierInstance.current = new RecaptchaVerifier(
        firebaseAuth,
        recaptchaContainer,
        {
          size: 'invisible',
          callback: () => {
            setIsCaptchaResolved(true);
          },
          'expired-callback': () => {
            setIsCaptchaResolved(false);
          },
        },
      );
    }

    const verificationIdPromise = phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifierInstance.current,
    );

    initObserver();

    const verificationId = await verificationIdPromise;

    return verificationId;
  };

  const handleSendVerificationCodeResolver = async (
    recaptchaContainer: string,
  ) => {
    try {
      if (!resolver) {
        signOutUser();
        navigate(AUTH_LOGIN, { state: { mfaInfoNotFound: true } });
      }
      if (
        resolver &&
        resolver.hints.length &&
        resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID
      ) {
        const verificationId = await handleVerifyPhoneNumberResolver(
          resolver,
          recaptchaContainer,
        );
        setVerificationId(verificationId);
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_INVALID_PHONE_NUMBER:
            setErrorMsg(locale?.invalidPhoneNumber);
            break;
          case FIREBASE_ERROR_CODES.AUTH_REQUIRES_RECENT_LOGIN:
            setErrorMsg(locale?.requiresRecentLogin);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS:
            storageBlockTime.setData(BLOCKED_LOGIN_EXPIRY_TIME);
            setErrorMsg(locale?.tooManyRequests);
            break;
          case FIREBASE_ERROR_CODES.AUTH_CAPTCHA_CHECK_FAILED:
            setErrorMsg(locale?.captchaCheckFailed);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOKEN_INVALID:
            setErrorMsg(locale?.mfaAuthUserTokenInvalid);
            break;
          default:
            setErrorMsg(locale?.defaultError);
            break;
        }
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
  };

  const handleSendVerificationCode = async (
    phoneNumber: string,
    recaptchaContainer: string,
  ): Promise<void> => {
    try {
      const currentUser = firebaseAuth.currentUser;

      if (!currentUser) {
        setErrorMsg(locale?.requiresRecentLogin);
        return;
      }

      const verificationId = await handleVerifyPhoneNumber(
        currentUser,
        phoneNumber,
        recaptchaContainer,
      );

      setVerificationId(verificationId);
    } catch (error: unknown) {
      errorCallback && errorCallback();
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_INVALID_PHONE_NUMBER:
            setErrorMsg(locale?.invalidPhoneNumber);
            break;
          case FIREBASE_ERROR_CODES.AUTH_REQUIRES_RECENT_LOGIN:
            setErrorMsg(locale?.requiresRecentLogin);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS:
            storageBlockTime.setData(BLOCKED_LOGIN_EXPIRY_TIME);
            setErrorMsg(locale?.tooManyRequests);
            break;
          case FIREBASE_ERROR_CODES.AUTH_CAPTCHA_CHECK_FAILED:
            setErrorMsg(locale?.captchaCheckFailed);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOKEN_INVALID:
            setErrorMsg(locale?.mfaAuthUserTokenInvalid);
            break;
          default:
            setErrorMsg(locale?.defaultError);
            break;
        }
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
  };

  const multifactorEnroll = async (
    currentUser: User,
    verificationId: string,
    verificationCode: string,
  ) => {
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );

    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);

    await multiFactor(currentUser).enroll(multiFactorAssertion);
  };

  const handleVerifyCode = async (verificationCode: string): Promise<void> => {
    try {
      if (verificationId) {
        const currentUser = firebaseAuth.currentUser;
        if (!currentUser) {
          return signOutUser();
        }

        await multifactorEnroll(currentUser, verificationId, verificationCode);

        setVerified(true);
      } else {
        setErrorMsg(locale?.verificationIDFail);
      }
    } catch (error) {
      console.error(`Something went wrong verifying MFA code: ${error}`);
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_INVALID_VERIFICATION_CODE:
            setErrorMsg(locale?.invalidVerificationCode);
            break;

          case FIREBASE_ERROR_CODES.AUTH_MFA_MISSING_CODE:
            setErrorMsg(locale?.mfaCodeNotProvided);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOKEN_INVALID:
            setErrorMsg(locale?.mfaAuthUserTokenInvalid);
            break;
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
  };

  const handleResolveSignIn = async (
    resolver: MultiFactorResolver,
    verificationCode: string,
    verificationId: string,
  ) => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    const response = await resolver.resolveSignIn(multiFactorAssertion);
    return response;
  };

  const handleVerifyCodeResolver = async (
    verificationCode: string,
    deleteAccount = false,
  ): Promise<void | UserCredential> => {
    try {
      if (resolver && verificationId) {
        const response = await handleResolveSignIn(
          resolver,
          verificationCode,
          verificationId,
        );
        if (deleteAccount) {
          const appleCredential = OAuthProvider.credentialFromResult(response);

          if (appleCredential) {
            const token = appleCredential?.accessToken;
            const user = firebaseAuth.currentUser;
            const response = await accountDeletionMutation({
              variables: {
                token: token ?? '',
                userId: user?.uid ?? '',
              },
            });
            if (!response.data) {
              setErrorMsg(locale?.unexpectedError);
              throw new Error('Failed to delete user');
            }
            signOutUser();
            navigate(AUTH_ACCOUNT_DELETED);
          }
        }

        return response;
      } else {
        if (!verificationId) return setErrorMsg(locale?.verificationIDFail);
        if (!resolver) {
          signOutUser();
          return navigate(AUTH_LOGIN, { state: {} });
        }
      }
    } catch (error) {
      console.error(`Something went wrong veryfing MFA code ${error}`);
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_INVALID_VERIFICATION_CODE:
            setErrorMsg(locale?.invalidVerificationCode);
            break;

          case FIREBASE_ERROR_CODES.AUTH_MFA_MISSING_CODE:
            setErrorMsg(locale?.mfaCodeNotProvided);
            break;

          case FIREBASE_ERROR_CODES.AUTH_MFA_INFO_NOT_FOUND:
            setErrorMsg(locale?.mfaInfoNotFound);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOKEN_INVALID:
            setErrorMsg(locale?.mfaAuthUserTokenInvalid);
            break;
          default:
            setErrorMsg(locale?.unexpectedError);
            break;
        }
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
  };

  useEffect(() => {
    if (errorMsg === locale?.requiresRecentLogin) {
      signOutUser();
      navigate(AUTH_MFA_REQUIRES_RECENT_LOGIN);
    } else if (errorMsg === locale?.tooManyRequests) {
      storageBlockTime.setData(BLOCKED_LOGIN_EXPIRY_TIME);
      signOutUser();
      navigate(`${AUTH_LOGIN}?mfaTooManyRequests=true`);
    }
  }, [errorMsg, signOutUser, navigate, locale]);

  return {
    isVerified,
    setVerified,
    isCaptchaResolved,
    handleSendVerificationCode,
    handleSendVerificationCodeResolver,
    handleVerifyCode,
    handleVerifyCodeResolver,
    handleVerifyPhoneNumber,
    handleVerifyPhoneNumberResolver,
    handleResolveSignIn,
    multifactorEnroll,
    errorMsg,
  };
};

export const useMFA = (): CheckMFA => {
  const getEnrolledFactors = () => {
    try {
      const user = firebaseAuth.currentUser;
      if (user) {
        const verifyUserMfa = multiFactor(user);
        return verifyUserMfa.enrolledFactors;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  return { getEnrolledFactors };
};

export const useUserProviders = () => {
  const [userProviders, setUserProviders] = useState<string[] | null>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchUserProviders = async () => {
      try {
        const user = firebaseAuth.currentUser;
        if (user) {
          const providerData = user.providerData;
          if (providerData.length > 0) {
            const providers = providerData.map((provider) => {
              return provider.providerId;
            });
            setUserProviders(providers);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        }
      }
    };

    fetchUserProviders();
  }, []);

  return { userProviders, error };
};

export const useAccountDeletion = () => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const navigate = useNavigate();
  const { setMfaResolver } = useContext(AuthContext);
  const signOutUser = useFirebaseSignOut();
  const [accountDeletionMutation, { error: accountDeletionError }] =
    useAccountDeletionMutation({});
  const [errorMsg, setErrorMsg] = useState<string>('');
  const accountDeletion = async () => {
    try {
      const user = firebaseAuth.currentUser;
      if (!user) {
        throw new Error('No user available');
      }

      const providerData = user.providerData;
      const providers = providerData.map((provider) => {
        return provider.providerId;
      });

      const token = await user.getIdToken();
      if (providers.includes('apple.com')) {
        await reauthenticateWithPopup(user, firebaseAppleAuthProvider);
      }

      const response = await accountDeletionMutation({
        variables: {
          token,
          userId: user.uid,
        },
      });
      if (!response.data) {
        setErrorMsg(locale?.unexpectedError);
        throw new Error('Failed to delete user');
      }
      signOutUser();
      return true;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === FIREBASE_ERROR_CODES.AUTH_USER_MISMATCH) {
          setErrorMsg(locale?.invalidEmail);
        }
        if (
          error.code === FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED
        ) {
          const resolver = getMultiFactorResolver(
            firebaseAuth,
            error as MultiFactorError,
          );

          setMfaResolver(resolver);
          navigate(MY_ACCOUNT_MFA_ACCOUNT_DELETION, {
            state: { deleteAccount: true },
          });
        }
      } else {
        setErrorMsg(locale?.unexpectedError);
      }
    }
    if (accountDeletionError) {
      setErrorMsg(accountDeletionError.message);
    }
  };
  return { accountDeletion, errorMsg };
};
