import {
  ProviderRequestStatus,
  useAcceptAppointmentByProviderMutation,
  useDeclineAppointmentByProviderMutation,
  useUpdateBecomePatientRequestStatusMutation,
} from 'graphql/generated/remote-schema-hasura';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HORMONE_QUESTIONNAIRE_FULL } from 'utilities/routes';

export enum CustomNotificationActions {
  UPDATE_PROVIDER_REQUEST = 'UPDATE_PROVIDER_REQUEST',
  GO_TO_QUESTIONNAIRE = 'GO_TO_QUESTIONNAIRE',
  ACCEPT_PROVIDER_APPOINTMENT = 'ACCEPT_PROVIDER_APPOINTMENT',
  DECLINE_PROVIDER_APPOINTMENT = 'DECLINE_PROVIDER_APPOINTMENT',
}

interface RequestBecomePatientParams {
  itemReference: { reference: string; type?: string };
  date: string;
  patientFhirId: string;
  requestStatus: ProviderRequestStatus;
}

type CustomActionHandler = (
  customActionParams: Record<string, unknown>,
  fromAlert: boolean,
) => Promise<void>;

export interface useHandleExtraActionsType {
  handler: Record<CustomNotificationActions, CustomActionHandler>;
  isLoading: boolean;
  error?: string;
}

export function useHandleExtraActions(): useHandleExtraActionsType {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [updateBecomePatientRequest] =
    useUpdateBecomePatientRequestStatusMutation({});
  const [acceptAppointment] = useAcceptAppointmentByProviderMutation({});
  const [declineAppointment] = useDeclineAppointmentByProviderMutation({});

  const handleAppointmentAccept = useCallback(
    async (params: Record<string, unknown>, fromAlert: boolean) => {
      setIsLoading(true);
      try {
        const { appointmentCodexId } = params;
        if (!appointmentCodexId) return;
        await acceptAppointment({
          variables: {
            appointmentCodexId: appointmentCodexId as string,
          },
        });

        !fromAlert && navigate(-1);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [acceptAppointment, navigate],
  );

  const handleAppointmentDecline = useCallback(
    async (params: Record<string, unknown>, fromAlert: boolean) => {
      setIsLoading(true);
      try {
        const { appointmentCodexId } = params;
        if (!appointmentCodexId) return;
        await declineAppointment({
          variables: {
            appointmentCodexId: appointmentCodexId as string,
          },
        });

        !fromAlert && navigate(-1);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [declineAppointment, navigate],
  );

  const handlePatientRequestUpdate = useCallback(
    async (params: Record<string, unknown>, fromAlert: boolean) => {
      try {
        const { itemReference, date, patientFhirId, requestStatus } =
          params as unknown as RequestBecomePatientParams;
        if (!itemReference || !date || !patientFhirId || !requestStatus) return;
        setIsLoading(true);
        await updateBecomePatientRequest({
          variables: {
            date: date,
            itemReferenceId: itemReference.reference,
            patientFhirId: patientFhirId,
            requestStatus,
            itemReferenceType: itemReference.type,
          },
        });
        setIsLoading(false);
        !fromAlert && navigate(-1);
      } catch (error) {
        setError((error as Error).message);
      }
    },
    [navigate, updateBecomePatientRequest],
  );

  const handlerMap = useMemo<
    Record<CustomNotificationActions, CustomActionHandler>
  >(
    () => ({
      [CustomNotificationActions.ACCEPT_PROVIDER_APPOINTMENT]:
        handleAppointmentAccept,
      [CustomNotificationActions.DECLINE_PROVIDER_APPOINTMENT]:
        handleAppointmentDecline,
      [CustomNotificationActions.UPDATE_PROVIDER_REQUEST]:
        handlePatientRequestUpdate,
      [CustomNotificationActions.GO_TO_QUESTIONNAIRE]: async (params) =>
        navigate(HORMONE_QUESTIONNAIRE_FULL, {
          state: {
            testKitData: params,
          },
        }),
    }),
    [
      handleAppointmentAccept,
      handleAppointmentDecline,
      handlePatientRequestUpdate,
      navigate,
    ],
  );

  return { handler: handlerMap, isLoading, error };
}
