import React, { useMemo } from 'react';
import DatePicker, {
  isToday,
  isYesterday,
} from 'components/datePicker/DatePicker';
import Time from './Time';
import { IQustionerySectionProps } from './SampleCollectionHeavyMetal';
import { useSampleCollectionHook } from './useSampleCollectionHook';
import DescriptionField from './DescriptionField';

const SampleHsCrpSection: React.FC<IQustionerySectionProps> = ({
  questionList,
  localFormData,
  handleDataChange,
  persistentData,
}) => {
  const [blood, time] = useMemo(() => questionList, [questionList]);
  const { handleChangeDate, handleTimeChange } = useSampleCollectionHook(
    localFormData,
    handleDataChange,
  );

  return (
    <div className="desktop:min-w-[565px] flex flex-col gap-4 my-[30px] desktop:pl-[120px]">
      <div className="flex flex-col desktop:flex-row gap-5">
        <div>
          <div className="mb-0.5 text-neutral-800 text-base font-semibold">
            {blood.questionnaire_questions_question.text}
            <span className="text-alert-negative">*</span>
          </div>
          <DatePicker
            onChange={(e) => {
              handleDataChange(
                e.target.value,
                null,
                blood.questionnaire_questions_question.id,
              );
              handleChangeDate(
                e.target.value,
                String(time.questionnaire_questions_question.response_property),
              );
            }}
            value={
              persistentData[blood.questionnaire_questions_question.id] ||
              undefined
            }
            isOpen={false}
            inputClassNames="min-w-[200px] h-[38px]"
            hasError={
              persistentData[blood.questionnaire_questions_question.id]
                ?.length === 0
            }
            isAllowedDateToSelect={(date) => {
              return isYesterday(date) || isToday(date);
            }}
          />
          <DescriptionField>Today or yesterday</DescriptionField>
        </div>
        <Time
          label={
            <>
              {String(time.questionnaire_questions_question.text)}
              <span className="text-alert-negative">*</span>
            </>
          }
          handleTimeChange={(timeValue) =>
            handleTimeChange(
              timeValue,
              persistentData[blood.questionnaire_questions_question.id] ||
                undefined,
              String(time.questionnaire_questions_question.response_property),
            )
          }
        />
      </div>
    </div>
  );
};

export default SampleHsCrpSection;
