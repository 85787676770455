// Auth
export const AUTH = '/auth';
export const AUTH_LOGIN = `${AUTH}/login`;
export const AUTH_ROUTES = `${AUTH}/*`;
export const AUTH_RESET_PASSWORD = `${AUTH}/reset-password`;
export const AUTH_RESET_PASSWORD_EXPIRED_LINK = `${AUTH}/reset-password-expired-link`;
export const AUTH_SIGN_UP = `${AUTH}/sign-up`;
export const AUTH_SEND_VERIFICATION_EMAIL = `${AUTH}/send-verification-email`;
export const AUTH_SEND_RESET_PASSWORD_EMAIL = `${AUTH}/send-reset-password-email`;
export const AUTH_MFA_SETUP = `${AUTH}/mfa-setup`;
export const AUTH_MFA_VERIFY = `${AUTH}/mfa-verify`;
export const AUTH_MFA_PROTECT = `${AUTH}/mfa-protect`;
export const AUTH_MFA_REQUIRES_RECENT_LOGIN = `${AUTH_LOGIN}?mfaRequiresRecentLogin=true`;
export const AUTH_MFA_NUMBER_CHANGED = `${AUTH_LOGIN}?mfaPhoneNumberChanged=true`;
export const AUTH_ACCOUNT_DELETED = `${AUTH_LOGIN}?accountDeletion=true`;
export const AUTH_SIGN_UP_PROVIDER = `${AUTH}/sign-up-provider`;
export const AUTH_CREATE_PROVIDER = `${AUTH}/create-provider`;
export const AUTH_SIGN_UP_PROVIDER_NPI_MODAL = `${AUTH_SIGN_UP_PROVIDER}?npi-info=true`;

// Dashboard
export const BASE_ROUTE = '/';
export const BASE_ROUTES = '/*';
export const NOT_FOUND_ROUTE = '/*';
export const LEGAL_DOCUMENTS_ROUTES = '/legal-documents/*';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_SCAN_QR = `${DASHBOARD}?scan-qr=true`;
export const DASHBOARD_NEW_SCAN = `${DASHBOARD}?new-scan=true`;
export const DASHBOARD_RESIZE_PHOTO = `${DASHBOARD}?resize-photo=true`;
export const DASHBOARD_SCAN_RESULTS = `${DASHBOARD}?scan-results=true`;
export const DASHBOARD_ANALIZE_PHOTO = `${DASHBOARD}?analize-photo=true`;
export const DASHBOARD_FACE_CAMERA = `${DASHBOARD}/face-camera`;
export const DASHBOARD_SUBMIT_PHOTO = `${DASHBOARD}/submit-photo`;
export const DASHBOARD_SCAN_COMPLETED = `${DASHBOARD}/scan-completed`;
export const QR_ACTION = '/qr-action';
export const PROVIDER_DASHBOARD_NEW_PATIENT_REQUEST = `${DASHBOARD}?new-patient-request=true`;

// Backoffice
export const BACK_OFFICE_ROUTES = '/backoffice/*';

// Notifications
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATIONS_ROUTES = `${NOTIFICATIONS}/*`;
export const NOTIFICATIONS_UNREAD_BASE = '/unread';
export const NOTIFICATIONS_UNREAD = `${NOTIFICATIONS}/unread`;
export const NOTIFICATIONS_MODAL = '/notifications?multiaction-modal=true';

// My profile
export const MY_ACCOUNT = '/my-account';
export const MY_ACCOUNT_ROUTES = `${MY_ACCOUNT}/*`;
export const MY_PROFILE = '/my-profile';
export const ACCOUNT_SETTINGS = '/account-settings';
export const MY_ACCOUNT_PROFILE = `${MY_ACCOUNT}${MY_PROFILE}`;
export const MY_PROFILE_ACCOUNT_SETTINGS = `${MY_ACCOUNT}${ACCOUNT_SETTINGS}`;
export const MY_ACCOUNT_DELETION = `${MY_ACCOUNT_PROFILE}?account-deletion=true`;
export const HEALTH_QUESTIONNAIRE = '/health-questionnaire';
export const MY_ACCOUNT_HEALTH_QUESTIONNAIRE = `${MY_ACCOUNT}${HEALTH_QUESTIONNAIRE}`;
export const MY_MEDIA_LIBRARY = '/my-media-library';
export const MY_ACCOUNT_MY_MEDIA_LIBRARY = `${MY_ACCOUNT}${MY_MEDIA_LIBRARY}`;
export const MY_ACCOUNT_PASSWORD_CHANGE = `${MY_ACCOUNT_PROFILE}?password-change=true`;
export const LOGOUT = '/logout';
export const MY_PROFILE_PROVIDER_NPI_MODAL = `${MY_ACCOUNT_PROFILE}?npi-info=true`;
export const MY_PROFILE_PATIENT_VALIDATE_INFORMATION = `${MY_ACCOUNT_PROFILE}?validate-information=true`;
export const MY_PROFILE_VALIDATE_PERSONA = `${MY_ACCOUNT_PROFILE}?validate-persona=true`;
export const MY_PROFILE_PATIENT_LOCKED_VERIFICATION = `${MY_ACCOUNT_PROFILE}?locked-verification=true`;

// Mfa
export const MFA_ACTION = '/mfa/action';
export const MFA_ACCOUNT_DELETION = '/mfa-account-deletion';
export const MY_ACCOUNT_MFA_ACCOUNT_DELETION = `${MY_ACCOUNT}${MFA_ACCOUNT_DELETION}`;

// My skin
export const MY_SKIN = '/my-skin';
export const MY_SKIN_ROUTES = `${MY_SKIN}/*`;
export const MY_DERMSCORE = '/my-dermscore';
export const MY_VISIT_SUMMARIES = '/my-visit-summaries';
export const MY_MEDICATION_HISTORY = '/my-medication-history';
export const MY_SKIN_DERMSCORE = `${MY_SKIN}${MY_DERMSCORE}`;
export const MY_SKIN_MY_VISIT_SUMMARIES = `${MY_SKIN}${MY_VISIT_SUMMARIES}`;
export const MY_SKIN_MY_MEDICATION_HISTORY = `${MY_SKIN}${MY_MEDICATION_HISTORY}`;
export const MY_DERMSCORE_SCAN_QR = `${MY_SKIN_DERMSCORE}?scan-qr=true`;
export const MY_DERMSCORE_NEW_SCAN = `${MY_SKIN_DERMSCORE}?new-scan=true`;
export const MY_DERMSCORE_RESIZE_PHOTO = `${MY_SKIN_DERMSCORE}?resize-photo=true`;
export const MY_DERMSCORE_SCAN_RESULTS = `${MY_SKIN_DERMSCORE}?scan-results=true`;
export const MY_SKIN_ANALIZE_PHOTO = `${MY_SKIN_DERMSCORE}?analize-photo=true`;
export const MY_SKIN_FACE_CAMERA = `${MY_SKIN_DERMSCORE}/face-camera`;
export const MY_SKIN_SUBMIT_PHOTO = `${MY_SKIN_DERMSCORE}/submit-photo`;
export const MY_SKIN_SCAN_COMPLETED = `${MY_SKIN_DERMSCORE}/scan-completed`;

// Manage test kits
export const TEST_KITS_MANAGEMENT = '/manage-test-kits';
export const TEST_KITS_MANAGEMENT_ROUTES = `${TEST_KITS_MANAGEMENT}/*`;
export const REGISTER_TEST_KIT = '/register-test-kit';
export const TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT = `${TEST_KITS_MANAGEMENT}${REGISTER_TEST_KIT}`;
export const TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT_SCAN_QR = `${TEST_KITS_MANAGEMENT}?scan-test-qr=true`;
export const RESULTS_PRIVACY_STATEMENT = `${TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT}?results-privacy-statement=true`;

export const TEST_KITS_MANAGEMENT_TEST_KITS = TEST_KITS_MANAGEMENT;
export const QUESTIONNAIRE = '/questionnaire';
export const HORMONE_QUESTIONNAIRE = '/hormone-questionnaire';
export const SELECT_A_PROVIDER = '/select-a-provider';
export const TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL = `${TEST_KITS_MANAGEMENT}/questionnaire`;
export const TEST_KITS_MANAGEMENT_SELECT_A_PROVIDER = `${TEST_KITS_MANAGEMENT}${SELECT_A_PROVIDER}`;
export const HORMONE_QUESTIONNAIRE_FULL = `${TEST_KITS_MANAGEMENT}/hormone-questionnaire`;
export const VISIT_SUMMARY = `${TEST_KITS_MANAGEMENT_TEST_KITS}?visit-summary=true`;
export const SCHEDULE_DNA_VISIT = `${TEST_KITS_MANAGEMENT_TEST_KITS}?schedule-dna-visit=true`;
export const DNA_VISIT_TERMS_OF_USE = `${TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL}?dna-visit-terms-of-use=true`;
export const HORMONE_VISIT_TERMS_OF_USE = `${HORMONE_QUESTIONNAIRE_FULL}?hormone-visit-terms-of-use=true`;
export const TEST_KITS_MANAGEMENT_BARCODE_CAMERA = `${TEST_KITS_MANAGEMENT}/barcode-camera`;
export const TEST_KITS_MANAGEMENT_BARCODE_SCANNED = `${TEST_KITS_MANAGEMENT}/test-kit-barcode-scanned`;
export const TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED = `${TEST_KITS_MANAGEMENT}/barcode-scan-completed`;

// Super admin
export const SUPER_ADMIN = '/super-admin';
export const SUPER_ADMIN_ROUTES = `${SUPER_ADMIN}/*`;
export const SUPER_ADMIN_LATEST_TESTS = '/latest-tests';
export const SUPER_ADMIN_TESTS = '/tests';
export const SUPER_ADMIN_PATIENTS = '/patients';
export const SUPER_ADMIN_PROVIDERS = '/providers';
export const SUPER_ADMIN_APPOINTMENTS = '/appointments';

// My appointments
export const APPOINTMENT_DEEP_LINK_PARAM = 'acuity-appointment-id';
export const MY_APPOINTMENTS = '/my-appointments';
export const MY_APPOINTMENTS_PARAMS = '/my-appointments?appointment';
export const MY_APPOINTMENTS_VIDEO_CONSULTATION = `${MY_APPOINTMENTS}?appointment-video-consultation=true`;
export const MY_APPOINTMENTS_CHAT_CONSULTATION = `${MY_APPOINTMENTS}?appointment-chat-consultation=true`;
export const SCHEDULE_AN_APPOINTMENT = '/schedule-an-appointment';
export const ALTERNATIVE_SCHEDULE_AN_APPOINTMENT =
  '/alternative-schedule-an-appointment';
export const RESCHEDULE_AN_APPOINTMENT = '/reschedule-an-appointment';
export const PAYMENT_APPOINTMENT_SUCCESS = '/payment-appointment-success';
export const PAYMENT_APPOINTMENT_FAILED = '/payment-appointment-failed';
export const MY_FAVORITE_PROVIDERS = '/my-favorite-providers';
export const MEET_OUT_PROVIDERS = '/meet-out-providers';
export const APPOINTMENT_DEEP_LINK = '/appointment-deep-link';
export const MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT = `${MY_APPOINTMENTS}${SCHEDULE_AN_APPOINTMENT}`;
export const MY_APPOINTMENTS_RESCHEDULE_AN_APPOINTMENT = `${MY_APPOINTMENTS}${RESCHEDULE_AN_APPOINTMENT}`;
export const FACE_PHOTO = '/face-photo';
export const APPOINTMENT_UPLOAD_PHOTO = `${MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT}${FACE_PHOTO}`;
export const APPOINTMENT_SUBMIT_PHOTO = `${MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT}/submit-photo`;
export const APPOINTMENT_SUBMIT_PHOTO_SUCCESS = `${MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT}/photo-success`;
export const MY_APPOINTMENTS_SELECT_A_PROVIDER = `${MY_APPOINTMENTS}${SELECT_A_PROVIDER}`;
export const MY_APPOINTMENTS_TERMS_OF_USE = `${MY_APPOINTMENTS}?accept_terms_of_use=true`;
export const MY_APPOINTMENTS_MY_FAVORITE_PROVIDERS = `${MY_APPOINTMENTS}${MY_FAVORITE_PROVIDERS}`;
export const MY_APPOINTMENTS_MEET_OUT_PROVIDERS = `${MY_APPOINTMENTS}${MEET_OUT_PROVIDERS}`;
export const EDIT_APPOINTMENT = `${MY_APPOINTMENTS}?edit_appointment=true`;
export const MY_APPOINTMENT_ROUTES = `${MY_APPOINTMENTS}/*`;
export const MY_APPOINTMENTS_PROVIDER_MYVISIT_SUMMARY_MODAL = `${MY_APPOINTMENTS}?my-visit-summary-modal-provider=true`;
export const MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL = `${MY_APPOINTMENTS}?my-visit-summary-modal-patient=true`;
export const MY_APPOINTMENTS_PROVIDER_OVERVIEW = `${MY_APPOINTMENTS}?appointment-details-modal=true`;
export const MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT_PRE_WORK_PRE_CAPTURE = `${MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT}?pre-capture-modal=true`;
export const MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT_CAMERA = `${MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT}?camera=true`;

//Sign up - Provider
export const SIGN_UP_PROVIDER = '/sign-up-provider';
export const CREATE_PROVIDER = '/create-provider';

// Vonage routes
export const VONAGE_WAITING_ROOM = '/waiting-room';
export const VONAGE_VIDEO_ROOM = '/appointment-room';
export const VONAGE_WAITING_ROOM_ROUTES = `${MY_APPOINTMENTS}${VONAGE_WAITING_ROOM}`;
export const VONAGE_WAITING_ROOM_ROUTES_TEST = `${MY_APPOINTMENTS}${VONAGE_WAITING_ROOM}?appointment-test=true`;

export const VONAGE_VIDEO_ROOM_ROUTES = `${MY_APPOINTMENTS}${VONAGE_VIDEO_ROOM}`;

// My patients
export const MY_PATIENTS = '/my-patients';
export const MY_PATIENTS_ROUTES = `${MY_PATIENTS}/*`;
export const MY_PATIENTS_BIO_INFO = 'bio-info';
export const MY_PATIENTS_BIO_INFO_ROUTES = ':patientId/bio-info/*';
export const MY_PATIENTS_BIO_INFO_PATIENT_INFO = 'patient-info';
export const MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY = 'appointment-history';
export const MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY = 'dermscore-history';
export const MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS = 'test-kit-results';
export const MY_PATIENTS_BIO_INFO_MEDICATION_HISTORY = 'medication-history';
export const MY_PATIENTS_BIO_INFO_SHARED_MEDIA = 'shared-media';
export const VIRTUAL_PHARMACIES_MODAL = `${MY_PATIENTS_BIO_INFO_PATIENT_INFO}?virtual-pharmacies=true`;

// Providers View & Selection
export const PROVIDERS = '/providers';
export const PROVIDERS_ROUTES = `${PROVIDERS}/*`;
export const PAST_PROVIDERS = '/past-providers';
export const FIND_A_PROVIDER = '/find-a-provider';
export const PROVIDER_BIO = '/provider-bio';
export const PROVIDERS_PROVIDER_BIO = `${PROVIDERS}${PROVIDER_BIO}`;
export const PROVIDERS_APPOINTMENTS = `${PROVIDERS}/appointments`;
export const PROVIDERS_FIND_A_PROVIDER = `${PROVIDERS}${FIND_A_PROVIDER}`;
export const PROVIDERS_PAST_PROVIDERS = `${PROVIDERS}${PAST_PROVIDERS}`;

// Fullscript link
export const FULLSCRIPT = '/fullscript';
export const FULLSCRIPT_ROUTES = `${FULLSCRIPT}/*`;
export const FULLSCRIPT_LINK = `${FULLSCRIPT}/link`;
export const UNLINK = '/unlink';
