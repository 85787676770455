import React, { useState, useMemo, useContext, useEffect, useRef } from 'react';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetCountriesQuery } from 'graphql/generated/hasura';

import { AuthContext } from 'auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { min7OnlyDigitsPattern } from 'utilities/variables';
import {
  normalizePhoneNumber,
  normalizePhoneNumberByUserRole,
} from 'utilities/functions';
import { useFirebaseSignOut } from '../../../firebase/hooks';
import { AUTH_LOGIN } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import MfaSmsComponent from 'components/multiFactorAuth/MfaSmsComponent';
import { SelectWithFlagsValues } from 'components/inputComponent';
import MultiFactorAuthVerifyModal from './MultiFactorAuthVerifyModal';

export const MultiFactorAuthSetup = () => {
  const { setPhoneNumber, setCountry, user } = useContext(AuthContext);
  const [phoneError, setPhoneError] = useState<string>('');
  const [localPhoneNumber, setLocalPhoneNumber] = useState<string>('');
  const [localCountry, setLocalCountry] = useState<string>(() =>
    !!user?.country && user.country !== 'null' ? user.country : '1',
  );
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [isOpenVerifyModal, setIsOpenVerifyModal] = useState<boolean>(false);
  const signOutUser = useFirebaseSignOut();
  const navigate = useNavigate();
  const recaptchaContainerWrapper = useRef<HTMLDivElement>(null);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });

  const { data } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const handleOnCountryChange = (country: SelectWithFlagsValues) => {
    setLocalCountry(country.value);
  };

  const validatePhoneNumber = (phoneNumber: string) =>
    new RegExp(min7OnlyDigitsPattern).test(phoneNumber);

  const handleOnPhoneNumberChange = (phoneNumber: string) => {
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
    const validePhoneNumber = validatePhoneNumber(normalizedPhoneNumber);
    setPhoneError(!validePhoneNumber ? locale.phoneError : '');
    setDisableButton(!validePhoneNumber);
    setLocalPhoneNumber(normalizedPhoneNumber);
  };

  const handleSavePhoneNumber = async () => {
    if (!validatePhoneNumber(localPhoneNumber)) {
      setPhoneError(locale.phoneError);
      return;
    }

    if (localCountry) {
      setCountry(localCountry);
    }

    if (localPhoneNumber) {
      setPhoneNumber(localPhoneNumber);
    }

    setIsOpenVerifyModal(true);
  };

  useEffect(() => {
    if (!user || !user.email) {
      (async () => {
        await signOutUser();
        navigate(AUTH_LOGIN);
      })();
    }
  }, [user, signOutUser, navigate]);

  useEffect(() => {
    if (user && user.phoneNumber) {
      !!user.country &&
        user.country !== 'null' &&
        setLocalCountry(user.country);
      setLocalPhoneNumber(
        normalizePhoneNumberByUserRole(user.phoneNumber, user.role),
      );
      setDisableButton(false);
    }
  }, [user]);

  if (loading && !locale) return null;

  if (data?.countries.length && !localCountry) {
    setLocalCountry(data.countries[0].code);
  }

  return (
    <>
      <div className="bg-transparent shadow desktop:rounded-lg desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto">
        <div className="w-full bg-white bg-border rounded-t-lg desktop:basis-3/5">
          <div className="flex flex-col gap-2.5 border-b desktop:flex-row desktop:items-center desktop:justify-between px-5 py-5 desktop:px-[60px] desktop:py-[15px]">
            <h3 className="text-h4 text-charcoal-gray font-semibold">
              {locale?.protectAccount}
            </h3>
          </div>
          <div className="flex flex-col gap-2.5 px-5 py-[30px] desktop:px-[60px]">
            <p className="text-base text-med-gray-3 font-medium">
              {locale?.securityImportant}
            </p>
            <p className="text-base text-med-gray-3 font-medium mt-[30px]">
              {locale?.note}
            </p>

            <MfaSmsComponent
              countries={mappedCountriesValues}
              country={localCountry}
              onCountryChange={handleOnCountryChange}
              onPhoneNumberChange={handleOnPhoneNumberChange}
              phoneNumber={localPhoneNumber}
              currentPhoneNumber={normalizePhoneNumber(user?.phoneNumber ?? '')}
              phoneError={phoneError}
            >
              <ButtonComponent
                disabled={disableButton}
                onClick={handleSavePhoneNumber}
                className="!rounded w-full desktop:w-[88px] h-[44px] flex-shrink-0 !text-sm mt-3 desktop:mt-[2px]"
              >
                {locale?.saveButton}
              </ButtonComponent>
            </MfaSmsComponent>
          </div>
        </div>
        <div className="flex justify-center" ref={recaptchaContainerWrapper}>
          <div id="recaptcha-container" />
        </div>
      </div>

      <MultiFactorAuthVerifyModal
        isOpen={isOpenVerifyModal}
        localCountry={localCountry}
        recaptchaContainerWrapper={recaptchaContainerWrapper}
        onClose={() => setIsOpenVerifyModal(false)}
      />
    </>
  );
};
