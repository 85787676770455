import {
  OrderByDirections,
  SkintelligentOrderByCriterias,
} from 'graphql/generated/hasura';
import { CustomNotificationActions } from 'hooks/useHandleExtraActions';

export interface RootConfigSchema {
  environment: string;
  baseUrl: string;
  isTest: boolean;
  analyticsProviders: string[];
}
export interface FirebaseConfigSchema {
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  firebaseProjectId: string;
  firebaseStorageBucket: string;
  firebaseMessagingSenderId: string;
  firebaseAppId: string;
  firebaseMeasurementId: string;
}

export interface HasuraConfigSchema {
  hasuraGraphQlApi: string;
  hasuraGraphQlWs: string;
}

export interface StrapiConfigSchema {
  strapiGraphQlApi: string;
}

export interface WeatherConfigSchema {
  api: string;
  geoApi: string;
  airApi: string;
  apiKey: string;
}

export interface StrichConfigSchema {
  licenseKey: string;
}

export interface NestConfigSchema {
  nestApi: string;
}

export interface Analytics {
  logEvent: (eventName: string, eventData: { [key: string]: unknown }) => void;
}

export interface LocaleInterface {
  [key: string]: string | LocaleInterface;
}

export interface LocaleContextValue {
  locale: LocaleInterface;
  loading: boolean;
}

export interface LocaleGenericProvider {
  children: React.ReactNode;
  localeMock?: LocaleInterface;
  locale: string;
  pageId: number;
}
export interface ValidateTrackingNumberResponseValidNumber {
  isValid: true;
  steps: string[];
}
export interface ValidateTrackingNumberResponseInvalidNumber {
  isValid: false;
}

export type ValidateTrackingNumberResponse = {
  trackingNumber: string;
} & (
  | ValidateTrackingNumberResponseValidNumber
  | ValidateTrackingNumberResponseInvalidNumber
);
export interface LaunchDarklySchema {
  clientId: string;
}

export interface PersonaSchema {
  personaTemplateId: string;
  personaEnvironmentId: string;
}

export interface FullScriptSchema {
  fullScriptClientId: string;
  fullScriptRedirectUri: string;
  fullScriptEnv: string;
  authorizationUrl: string;
}

export interface AcuitySchema {
  appointmentCodexIdFormId: string;
  acuityCalendarBaseUrl: string;
}

interface InactivityModalCfg {
  show: boolean;
  timeout: number;
  promtBeforeIdle: number;
}

interface UserLogoutCfg {
  logoutBasedOnLastActivity: boolean;
  logoutBasedOnLastActivityTimeLimit: number;
}

export interface CodexFeatureFlags {
  barcodeScanningDisabled: boolean;
  inactivityModalFlag: InactivityModalCfg;
  userLogoutFlag: UserLogoutCfg;
  myvisitSummaryModalDisabledFlag: boolean;
  appointmentRoutesDisabledFlag: boolean;
  enableSignupProvider: boolean;
  useFhirFlow: boolean;
  myPatientsRoutesDisabled: boolean;
  providersSelectionDisabled: boolean;
  hormoneQuestionnaireRouteDisabled: boolean;
  zrtTestKitsFlowDisabled: boolean;
  enableDermscoreTabs: boolean;
  appointmentDetailsModalDisabled: boolean;
  enablePersonaVerification: boolean;
  medicationHistoryDetailsModalDisabled: boolean;
  appointmentsSelectionDisabled: boolean;
  patientDashboardUpcomingAppointmentDisabled: boolean;
  newFlowMobilePreWork: boolean;
}

export interface CodexFeatureFlagsUppercase {
  APPOINTMENT_ROUTES_DISABLED_FLAG: boolean;
  BARCODE_SCANNING_DISABLED: boolean;
  ENABLE_SIGNUP_PROVIDER: boolean;
  INACTIVITY_MODAL_FLAG: InactivityModalCfg;
  MYVISIT_SUMMARY_MODAL_DISABLED_FLAG: boolean;
  MY_PATIENTS_ROUTES_DISABLED: boolean;
  USER_LOGOUT_FLAG: UserLogoutCfg;
  USE_FHIR_FLOW: boolean;
  PROVIDERS_SELECTION_DISABLED: boolean;
  ZRT_TEST_KITS_FLOW_DISABLED: boolean;
  ENABLE_DERMSCORE_TABS: boolean;
  APPOINTMENT_DETAILS_MODAL_DISABLED: boolean;
}

export enum CodexFeatureFlagsKinds {
  patient = 'patient',
  provider = 'provider',
}

export interface SkintelligentOrderByFhirArg {
  field: SkintelligentOrderByCriterias;
  direction: OrderByDirections;
}

export enum SUPPORTED_GENDERS_ASSIGNED_AT_BIRTH {
  MALE = 'Man',
  FEMALE = 'Woman',
}

export enum FHIR_APPOINTMENT_STATUS {
  PROPOSED = 'proposed',
  PENDING = 'pending',
  BOOKED = 'booked',
  ARRIVED = 'arrived',
  FULFILLED = 'fulfilled',
  CANCELLED = 'cancelled',
  NOSHOW = 'noshow',
  ENTEREDINERROR = 'entered-in-error',
  CHECKEDIN = 'checked-in',
  WAITLIST = 'waitlist',
}

export enum ORDER_BY_DIRECTION_NUMERIC {
  ASC = 1,
  DESC = 0,
}

export enum SORT_FHIR_APPOINTMENT_BY {
  SUMMARY_DATE = 'summaryDate',
  LAST_UPDATE = 'lastUpdate',
  SUBJECT = 'subject',
  TYPE = 'type',
  PROVIDER_NAME = 'providerName',
  DATE = 'date',
}

export interface AlertExtraActions {
  route: string;
  actionName: string;
  type?: string;
  customAction?: CustomNotificationActions;
  customActionParams?: Record<string, unknown>;
}

export enum ResourcesTypes {
  PATIENT = 'Patient',
  PROVIDER = 'Practitioner',
  LOCATION = 'Location',
  PROVIDER_ROLE = 'PractitionerRole',
  ORGANIZATION = 'Organization',
  OBSERVATION = 'Observation',
  DIAGNOSTIC_REPORT = 'DiagnosticReport',
  MEDIA = 'Media',
  QUESTIONNAIRE_RESPONSE = 'QuestionnaireResponse',
  QUESTIONNAIRE = 'Questionnaire',
  SERVICE_REQUEST = 'ServiceRequest',
  ENCOUNTER = 'Encounter',
  APPOINTMENT = 'Appointment',
  MEDICATION_REQUEST = 'MedicationRequest',
  CONDITION = 'Condition',
  GROUP = 'Group',
  LIST = 'List',
  COMMUNICATION = 'Communication',
  HEALTH_CARE_SERVICE = 'HealthcareService',
  BASIC = 'Basic',
  MEDICATION = 'Medication',
}

export interface NotificationsConfigSchema {
  lookbackDays: number;
}

export interface ISortValue {
  order: OrderByDirections;
  searchCriteria: string;
}
