import React, { useMemo } from 'react';
import { questionnaire_questions_question } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import DatePicker, {
  isToday,
  isYesterday,
} from 'components/datePicker/DatePicker';
import Time from './Time';
import DescriptionField from './DescriptionField';
import { useSampleCollectionHook } from './useSampleCollectionHook';

export interface IQustionerySectionProps {
  questionList: questionnaire_questions_question[];
  handleDataChange: (
    date: string,
    property: string | null,
    id: number | null | undefined,
  ) => void;
  localFormData: Record<string, string>;
  persistentData: Record<number, string | null>;
}

const SampleCollectionHeavyMetal: React.FC<IQustionerySectionProps> = ({
  questionList,
  handleDataChange,
  localFormData,
  persistentData,
}) => {
  const [blood, time, urine, morning, night] = useMemo(
    () => questionList,
    [questionList],
  );

  const { handleChangeDate, handleTimeChange } = useSampleCollectionHook(
    localFormData,
    handleDataChange,
  );

  return (
    <div className="desktop:min-w-[565px] flex flex-col gap-4 py-[30px] desktop:pl-[120px]">
      <div className="flex flex-col desktop:flex-row gap-5">
        <div>
          <div className="mb-0 text-neutral-800 text-base font-semibold">
            {blood.questionnaire_questions_question.text}
            <span className="text-alert-negative">*</span>
          </div>
          <DatePicker
            onChange={(e) => {
              handleDataChange(
                e.target.value,
                null,
                blood.questionnaire_questions_question.id,
              );
              handleChangeDate(
                e.target.value,
                String(time.questionnaire_questions_question.response_property),
              );
            }}
            value={
              persistentData[blood.questionnaire_questions_question.id] ||
              undefined
            }
            isOpen={false}
            inputClassNames="min-w-[200px] h-[38px]"
            hasError={
              persistentData[blood.questionnaire_questions_question.id]
                ?.length === 0
            }
            isAllowedDateToSelect={(date) => {
              return isYesterday(date) || isToday(date);
            }}
          />
          <DescriptionField>Today or yesterday</DescriptionField>
        </div>
        <Time
          label={
            <>
              {String(time.questionnaire_questions_question.text)}
              <span className="text-alert-negative">*</span>
            </>
          }
          handleTimeChange={(timeValue) =>
            handleTimeChange(
              timeValue,
              persistentData[blood.questionnaire_questions_question.id] ||
                undefined,
              String(time.questionnaire_questions_question.response_property),
            )
          }
        />
      </div>

      <div className="flex flex-col desktop:flex-row gap-5">
        <div>
          <div className="mb-0 text-neutral-800 text-base font-semibold">
            {urine.questionnaire_questions_question.text}
            <span className="text-alert-negative">*</span>
          </div>
          <DatePicker
            isAllowedDateToSelect={(date) => {
              return isYesterday(date) || isToday(date);
            }}
            onChange={(e) => {
              handleDataChange(
                e.target.value,
                null,
                urine.questionnaire_questions_question.id,
              );
              handleChangeDate(
                e.target.value,
                String(
                  morning.questionnaire_questions_question.response_property,
                ),
              );
              handleChangeDate(
                e.target.value,
                String(
                  night.questionnaire_questions_question.response_property,
                ),
              );
            }}
            inputClassNames="min-w-[200px] h-[38px]"
            value={
              persistentData[urine.questionnaire_questions_question.id] ||
              undefined
            }
            isOpen={false}
            hasError={
              persistentData[urine.questionnaire_questions_question.id]
                ?.length === 0
            }
          />
          <DescriptionField>Today and yesterday</DescriptionField>
        </div>
        <Time
          label={
            <>
              {String(morning.questionnaire_questions_question.text)}
              <span className="text-alert-negative">*</span>
            </>
          }
          minTime="5:00"
          maxTime="8:59"
          handleTimeChange={(timeValue) =>
            handleTimeChange(
              timeValue,
              persistentData[urine.questionnaire_questions_question.id] ||
                undefined,
              String(
                morning.questionnaire_questions_question.response_property,
              ),
            )
          }
        />
        <Time
          label={
            <>
              {String(night.questionnaire_questions_question.text)}
              <span className="text-alert-negative">*</span>
            </>
          }
          minTime="21:00"
          maxTime="23:59"
          handleTimeChange={(timeValue) =>
            handleTimeChange(
              timeValue,
              persistentData[urine.questionnaire_questions_question.id] ||
                undefined,
              String(night.questionnaire_questions_question.response_property),
            )
          }
        />
      </div>
    </div>
  );
};

export default SampleCollectionHeavyMetal;
