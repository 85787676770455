import InputComponent from 'components/inputComponent';
import {
  DynamicQuestionnaireCurrentIndexOfSection,
  HandleOnInputChangeType,
  ModuleFormErrors,
  TestKitData,
} from '../interfaces/dynamicQuestionnaire.interface';
import {
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  questionStatusI,
  questionnaire_questions_question,
  RequiredIdsList,
  DropdownItem,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import {
  MedicationItemResponse,
  getUiControlType,
  hasMonthElapsed,
  parseQuestionOptionsForDropdownWithLookup,
  updateStateWithMedicationValues,
  validateQuestionResponse,
} from '../utils/utils';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UI_CONTROL } from '../interfaces/uiControl.interface';
import {
  HORMONE_MEDICATION_MAX_LENGTH,
  HORMONE_QUESTIONNAIRE_SECTIONS,
  HORMONE_QUESTIONNAIRE_SPECIFIC_IDS,
  medicationIdsFromHormoneQuestionnaire,
  hormoneValidationFunctionsRules,
  MEDICATIONS_IDS,
} from '../utils/constants';
import DatePicker from 'components/datePicker/DatePicker';
import CreatableSelect from 'react-select/creatable';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  REGEX_ONLY_NUMBERS_AND_COLONS,
  componentIds,
  questionnairesIds,
} from 'utilities/constants';
import { SingleValue } from 'react-select';
import Alert from 'components/alertComponent';
import { useGetFhirPatientbyCodexIdQuery } from 'graphql/generated/remote-schema-hasura';

import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import Slider from 'rc-slider';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { HormoneQuestionnairBanner } from '../components/HormoneQuestionnairBanner';
import { MenstrualStatusQuestionFactory } from '../components/MenstrualStatusQuestionFactory';
import SampleCollectionSection from '../../../app/manage-test-kits/components/questionare/SampleCollectionSection';
import { IndividualInformation } from '../components/IndividualInformation';
import { SymptomsComponent } from '../components/SymptomsComponent';
import { HormonesAndMedications } from '../components/HormonesAndMedications';

const AM = 'AM';
const PM = 'PM';
const FIRST_SAMPLE_DATE_ID = 12;
const FIRST_SAMPLE_TIME_ID = 13;
const FIRST_SAMPLE_AM_PM_ID = 14;
const FULL_DATE_LENGTH = 3;
const VALID_TIME_LENGTH = 5;
const INITIAL_SAMPLE_INDEX = 0;
const SECOND_SAMPLE_INDEX = 1;
const THIRD_SAMPLE_INDEX = 2;
const FOURTH_SAMPLE_INDEX = 3;

const COLLECTION_TIMES_BY_TEST_TYPE = {
  ZRT_INFLAMMATORY: 0,
  ZRT_HORMONE: 3,
  ZRT_HEAVY_METALS: 2,
  ZRT_NEUROTRANSMITTERS: 3,
};

interface LocalFormData {
  firstname: string;
  lastname: string;
  height5: string;
  height8: string;
  waist: string;
  weight: string;
  fast_time: string;
  blood_sample_time13: string;
  blood_sample_time16: string;
  blood_sample_time19: string;
  blood_sample_time22: string;
  blood_sample_time14: string;
  blood_sample_time17: string;
  blood_sample_time20: string;
  blood_sample_time23: string;
  dob?: string;
  gender?: string;
}
interface SliderValue {
  currentIndexOfSection: number;
  currentSection: string;
  questionID: number;
  response: string;
}

interface HormoneQuestionnaireModule {
  questionList: questionnaire_questions_question[];
  questionnaireData: QuestionnaireResponseInterface[];
  currentIndexSection: DynamicQuestionnaireCurrentIndexOfSection;
  radioCheckedState: QuestionnaireResponseInterface[];
  checkboxesCheckedState: QuestionnaireResponseInterface[];
  section: DynamicQuestionResponseSection;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  setRadioCheckedState: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  setCheckboxesCheckedState?: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  matchSelectedRadio: (
    question: number,
    stringToMatch: string,
    latestState: QuestionnaireResponseInterface[],
  ) => QuestionnaireResponseInterface | undefined;
  handleRadioClick: (question: number, value: string | undefined) => void;
  nestedQuestionList: questionStatusI[];
  renderNestedQuestions: (
    questionStatus: questionStatusI,
    question: questionnaire_questions_question,
    questionParentID: number,
    childrenOriginalQuestionID: string | null | undefined,
    handleCheckboxToggle: (
      answer: string,
      questionID: number | undefined,
      questionParentID?: number,
      childrenOriginalQuestionID?: string,
    ) => void,
    clearCheckboxState: (questionID: number) => void,
    currentSection: DynamicQuestionResponseSection,
    radioCheckedState?: QuestionnaireResponseInterface[],
    setRadioCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    checkboxesCheckedState?: QuestionnaireResponseInterface[],
    setCheckboxesCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    textFieldResponses?: QuestionnaireResponseInterface[],
    setTextFieldResponses?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
  ) => ReactNode;
  clearCheckboxState: (questionID: number) => void;
  textFieldResponses: QuestionnaireResponseInterface[];
  setTextFieldResponses: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  requiredQuestionsIds: RequiredIdsList;
  getIncompleteMainQuestion: () => string[];
  submitWasTriggered: boolean;
  setPersistentData: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  questionnaireID: number;
  persistentData: QuestionnaireResponseInterface[];
  onDoubleTextFieldChange: (
    value: QuestionnaireResponseInterface[],
    questionID: number,
  ) => void;
  setModuleFormErrors: Dispatch<SetStateAction<ModuleFormErrors | null>>;
  currentSection: DynamicQuestionResponseSection;
  hormoneTableMedicationsSelected: QuestionnaireResponseInterface[];
  setHormoneTableMedicationsSelected: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  hormoneTableCheckboxesCheckedState: QuestionnaireResponseInterface[];
  setHormoneTableCheckboxesCheckedState: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  hormoneTableCheckboxCheckAll: boolean;
  setHormoneTableCheckboxAll: Dispatch<React.SetStateAction<boolean>>;
  testKitData?: TestKitData;
}

const HormoneQuestionnaireModule: React.FC<HormoneQuestionnaireModule> = ({
  questionList,
  section,
  onChange,
  textFieldResponses,
  setTextFieldResponses,
  requiredQuestionsIds,
  getIncompleteMainQuestion,
  submitWasTriggered,
  setPersistentData,
  matchSelectedRadio,
  radioCheckedState,
  handleRadioClick,
  nestedQuestionList,
  handleCheckboxToggle,
  clearCheckboxState,
  setRadioCheckedState,
  checkboxesCheckedState,
  setCheckboxesCheckedState,
  renderNestedQuestions,
  questionnaireID,
  persistentData,
  setModuleFormErrors,
  currentSection,
  testKitData,
}) => {
  const COLLECTION_TIMES = testKitData?.testType
    ? COLLECTION_TIMES_BY_TEST_TYPE[
        testKitData?.testType as keyof typeof COLLECTION_TIMES_BY_TEST_TYPE
      ]
    : 0;

  const isLoadedData = useRef(false);

  const getPersistentDataBySection = useCallback(
    (section: string) => {
      return persistentData.filter((data) => data.currentSection === section);
    },
    [persistentData],
  );

  const processUserDataIntoQuestionnaire = (user: LocalFormData) => {
    if (isLoadedData.current) return;

    const idsToMatch = [
      { key: 'firstname', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FIRST_NAME },
      { key: 'lastname', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.LAST_NAME },
      {
        key: 'height5',
        id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_FIRST_FIELD,
      },
      {
        key: 'height8',
        id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_SECOND_FIELD,
      },
      { key: 'waist', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.WAIST },
      { key: 'weight', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.WEIGHT },
      { key: 'dob', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.DOB },
      { key: 'gender', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.GENDER },
    ];

    const processedItems = idsToMatch.map((item) => {
      return {
        currentIndexOfSection: 0,
        currentSection: HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION,
        questionID: item.id,
        response: user[item.key as keyof LocalFormData],
      };
    });

    setPersistentData((prevState) => [...prevState, ...processedItems]);
    isLoadedData.current = true;
  };

  const { loading: fhirPatientDataLoading } = useGetFhirPatientbyCodexIdQuery({
    onCompleted(data) {
      if (data?.getFHIRPatientbyCodexID.users.length > 0) {
        const user = data?.getFHIRPatientbyCodexID.users[0];
        setLocalFormData({
          firstname: user?.SENSITIVE_firstname || '',
          lastname: user?.SENSITIVE_lastname || '',
          height5: user.SENSITIVE_user_measurements?.heightFt
            ? String(user?.SENSITIVE_user_measurements?.heightFt)
            : '',
          height8: user.SENSITIVE_user_measurements?.heightIn
            ? String(user?.SENSITIVE_user_measurements?.heightIn)
            : '',
          waist: user.SENSITIVE_user_measurements?.waist
            ? String(user?.SENSITIVE_user_measurements?.waist)
            : '',
          weight: user.SENSITIVE_user_measurements?.weight
            ? String(user?.SENSITIVE_user_measurements?.weight)
            : '',
          fast_time: '',
          blood_sample_time13: '',
          blood_sample_time16: '',
          blood_sample_time19: '',
          blood_sample_time22: '',
          blood_sample_time14: '',
          blood_sample_time17: '',
          blood_sample_time20: '',
          blood_sample_time23: '',
        });
        setDateOfBirth(user?.SENSITIVE_dob || '');
        setGenderSelected(user?.SENSITIVE_gender || '');
        processUserDataIntoQuestionnaire({
          firstname: user?.SENSITIVE_firstname || '',
          lastname: user?.SENSITIVE_lastname || '',
          height5: user.SENSITIVE_user_measurements?.heightFt
            ? String(user?.SENSITIVE_user_measurements?.heightFt)
            : '',
          height8: user.SENSITIVE_user_measurements?.heightIn
            ? String(user?.SENSITIVE_user_measurements?.heightIn)
            : '',
          waist: user.SENSITIVE_user_measurements?.waist
            ? String(user?.SENSITIVE_user_measurements?.waist)
            : '',
          weight: user.SENSITIVE_user_measurements?.weight
            ? String(user?.SENSITIVE_user_measurements?.weight)
            : '',
          dob: user?.SENSITIVE_dob || '',
          gender: user?.SENSITIVE_gender || '',
          fast_time: '',
          blood_sample_time13: '',
          blood_sample_time16: '',
          blood_sample_time19: '',
          blood_sample_time22: '',
          blood_sample_time14: '',
          blood_sample_time17: '',
          blood_sample_time20: '',
          blood_sample_time23: '',
        });
      }
    },
  });

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [genderSelected, setGenderSelected] = useState('');
  const [bloodSampleDate, setBloodSampleDate] = useState('');
  const [bloodSampleDate2, setBloodSampleDate2] = useState('');
  const [bloodSampleDate3, setBloodSampleDate3] = useState('');
  const [bloodSampleDate4, setBloodSampleDate4] = useState('');
  const [lastMensesDate, setLastMensesDate] = useState('');
  const [userLastUpdatedDate, setUserLastUpdatedDate] = useState('');

  const [userAnsweredBloodWorkQuestions, setUserAnsweredBloodworkQuestions] =
    useState({
      [INITIAL_SAMPLE_INDEX]: {
        date: false,
        hour: false,
        timeOfDay: false,
      },
      [SECOND_SAMPLE_INDEX]: {
        date: false,
        hour: false,
        timeOfDay: false,
      },
      [THIRD_SAMPLE_INDEX]: {
        date: false,
        hour: false,
        timeOfDay: false,
      },
      [FOURTH_SAMPLE_INDEX]: {
        date: false,
        hour: false,
        timeOfDay: false,
      },
    });

  useEffect(() => {
    setUserAnsweredBloodworkQuestions(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.fromEntries(
        new Array(COLLECTION_TIMES + 1)
          .fill(null)
          .map((_, i) => [i, { date: false, hour: false, timeOfDay: false }]),
      ),
    );
  }, [COLLECTION_TIMES]);

  const [sliderValues, setSliderValues] = useState<SliderValue[]>([]);

  const [medicationList, setMedicationList] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [textLocalFieldResponses, setTextLocalFieldResponses] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [showTimeElapsedAlert, setShowTimeElapsedAlert] = useState(false);

  const {
    data: dynamicQuestionnaireLocale,
    loading: dynamicQuestionnaireLocaleLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.HORMONE_QUESTIONNAIRE_MODULE,
  });

  const { data: fhirUserData, loading: fhirLoadingUserData } =
    useGetFhirPatientbyCodexIdQuery({
      onCompleted: (data) => {
        if (data.getFHIRPatientbyCodexID.users.length > 0) {
          setUserLastUpdatedDate(
            data.getFHIRPatientbyCodexID.users[0].updated_at || '',
          );
        }
      },
    });

  const bloodSamplesCollection = [
    {
      bloodSampleDate: bloodSampleDate2,
      setBloodSampleDate: setBloodSampleDate2,
    },
    {
      bloodSampleDate: bloodSampleDate3,
      setBloodSampleDate: setBloodSampleDate3,
    },
    {
      bloodSampleDate: bloodSampleDate4,
      setBloodSampleDate: setBloodSampleDate4,
    },
  ];

  const userHasOutdatedProfileInformation =
    hasMonthElapsed(userLastUpdatedDate);

  const allBloodWorkQuestionsAnswered = Object.values(
    userAnsweredBloodWorkQuestions,
  ).every((value) => Object.values(value).every((item) => item));

  const isRequiredQuestion = (questionId: string): boolean => {
    const isRequired = requiredQuestionsIds.find((id) => id === questionId);
    return Boolean(isRequired);
  };

  const missingMainQuestionIds = getIncompleteMainQuestion();

  const sortedQuestionList = [...questionList].sort(
    (a, b) =>
      (a.questionnaire_questions_question.id ?? 0) -
      (b.questionnaire_questions_question.id ?? 0),
  );

  const bloodSampleTimeValidator = (value: string) =>
    value.length === VALID_TIME_LENGTH;

  const validationRules: ValidationRules = {
    firstname: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    lastname: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    height5: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    height8: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    weight: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    waist: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    fast_time: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    blood_sample_time13: [
      {
        validator: bloodSampleTimeValidator,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    blood_sample_time16: [
      {
        validator: bloodSampleTimeValidator,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    blood_sample_time19: [
      {
        validator: bloodSampleTimeValidator,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    blood_sample_time22: [
      {
        validator: bloodSampleTimeValidator,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
  };

  const { errors, validateForm } = useFormValidation(validationRules);

  const [localFormData, setLocalFormData] = useState<LocalFormData>({
    firstname: '',
    lastname: '',
    height5: '',
    height8: '',
    waist: '',
    weight: '',
    fast_time: '',
    blood_sample_time13: '',
    blood_sample_time16: '',
    blood_sample_time19: '',
    blood_sample_time22: '',
    blood_sample_time14: '',
    blood_sample_time17: '',
    blood_sample_time20: '',
    blood_sample_time23: '',
  });

  const handleValidation = useCallback(
    (providerData: LocalFormData) => {
      const formData = JSON.parse(JSON.stringify(providerData));
      return validateForm(formData);
    },
    [validateForm],
  );

  const prevLocalFormDataRef = useRef<LocalFormData | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(localFormData);
      }
      validationExecutedRef.current = true;
    }
  }, [localFormData, handleValidation]);

  const handleTextFieldResponses = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
    shouldUseCustomId?: boolean,
  ) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.questionID === questionID,
      );

      if (existingIndex !== -1) {
        // If the questionID exists, update the existing item
        const updatedResponses = [...textFieldResponses];
        updatedResponses[existingIndex].response = value;
        setTextFieldResponses(updatedResponses);
        onChange(
          (value as string) || '',
          shouldUseCustomId
            ? questionID
            : question?.questionnaire_questions_question.id || 0,
        );
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionID,
          response: value,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);

        onChange(
          (value as string) || '',
          shouldUseCustomId
            ? questionID
            : question?.questionnaire_questions_question.id || 0,
        );
      }
    }
  };

  const handleInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => {
    if (questionID) {
      // handles manually setting question ids
      if (
        validateQuestionResponse(
          question,
          e.target.value,
          hormoneValidationFunctionsRules,
          `${question.questionnaire_questions_question.response_property}${questionID}`,
        )
      ) {
        textFieldResponses &&
          handleTextFieldResponses(e.target.value, questionID, {
            ...question,
            questionnaire_questions_question: {
              ...question.questionnaire_questions_question,
              id: questionID,
            },
          });
        setLocalFormData({
          ...localFormData,
          [`${question.questionnaire_questions_question.response_property}${questionID}`]:
            e.target.value,
        });
      }
    } else {
      if (
        validateQuestionResponse(
          question,
          e.target.value,
          hormoneValidationFunctionsRules,
        )
      ) {
        textFieldResponses &&
          handleTextFieldResponses(
            e.target.value,
            question.questionnaire_questions_question.id,
            question,
          );
        setLocalFormData({
          ...localFormData,
          [question.questionnaire_questions_question
            .response_property as string]: e.target.value,
        });
      }
    }
  };

  const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>): string => {
    let inputText = e.target.value;
    const cursorPosition = e.target.selectionStart ?? 0;

    // Check if input contains only numbers and colons
    if (!REGEX_ONLY_NUMBERS_AND_COLONS.test(inputText)) {
      // If not, clear the input
      e.target.value = inputText.replace(/[^\d:]/g, '');
      return '';
    }

    // Limit hours to not exceed 23
    if (inputText.length >= 2) {
      const hours = parseInt(inputText.substring(0, 2), 10);
      const minutes = parseInt(inputText.substring(3, 5), 10);
      if (hours > 23) {
        inputText = '00' + inputText.substring(2);
      }
      if (minutes > 59) {
        inputText = inputText.substring(0, 3) + '59';
      }
    }

    // Insert semicolon after second character if cursor is beyond the third character
    if (
      inputText.length > 2 &&
      cursorPosition > 2 &&
      inputText.charAt(2) !== ':'
    ) {
      inputText = inputText.slice(0, 2) + ':' + inputText.slice(2);
    }

    return inputText;
  };

  const handleDateInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => {
    const inputText = handleTimeInput(e);

    if (questionID) {
      // handles manually setting question ids
      textFieldResponses &&
        handleTextFieldResponses(inputText, questionID, {
          ...question,
          questionnaire_questions_question: {
            ...question.questionnaire_questions_question,
            id: questionID,
          },
        });
      setLocalFormData({
        ...localFormData,
        [`${question.questionnaire_questions_question.response_property}${questionID}`]:
          inputText,
      });
    } else {
      textFieldResponses &&
        handleTextFieldResponses(
          inputText,
          question.questionnaire_questions_question.id,
          question,
        );
      setLocalFormData({
        ...localFormData,
        [question.questionnaire_questions_question.response_property as string]:
          inputText,
      });
    }
  };

  const handleDatepickerChange = (
    question: questionnaire_questions_question,
    value: string,
    type: 'dob' | 'bloodsample' | 'menses',
    id?: number,
  ) => {
    const isIdDefined = !!id || id === 0;
    const setSampleDate = isIdDefined
      ? bloodSamplesCollection[id].setBloodSampleDate
      : setBloodSampleDate;

    if (type === 'dob') {
      setDateOfBirth(value);
    }

    if (type === 'bloodsample') {
      setSampleDate(value);
    }

    if (type === 'menses') {
      setLastMensesDate(value);
    }

    handleTextFieldResponses(
      value,
      isIdDefined
        ? FIRST_SAMPLE_DATE_ID + FULL_DATE_LENGTH * (id + 1)
        : question.questionnaire_questions_question.id,
      question,
      true,
    );
  };

  const getAmPmPickerValue = (index: number) => {
    const value =
      localFormData[
        `blood_sample_time${
          FIRST_SAMPLE_AM_PM_ID + FULL_DATE_LENGTH * (index + 1)
        }` as keyof typeof localFormData
      ];

    if (!value) return null;

    return {
      value:
        localFormData[
          `blood_sample_time${
            FIRST_SAMPLE_AM_PM_ID + FULL_DATE_LENGTH * (index + 1)
          }` as keyof typeof localFormData
        ],
      label:
        localFormData[
          `blood_sample_time${
            FIRST_SAMPLE_AM_PM_ID + FULL_DATE_LENGTH * (index + 1)
          }` as keyof typeof localFormData
        ],
    };
  };

  const getFirstAmPmPickerValue = () => getAmPmPickerValue(-1);

  const handleDropdownOptionSelected = (
    value: SingleValue<string | DropdownItem>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    if (questionID) {
      // handles manually setting question ids
      textFieldResponses &&
        handleTextFieldResponses(selectedValue, questionID, {
          ...question,
          questionnaire_questions_question: {
            ...question.questionnaire_questions_question,
            id: questionID,
          },
        });
    } else {
      textFieldResponses &&
        handleTextFieldResponses(
          selectedValue,
          question.questionnaire_questions_question.id,
          question,
        );
      setGenderSelected(selectedValue);
    }
  };

  const handleSliderChange = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
  ) => {
    textFieldResponses &&
      handleTextFieldResponses(value, questionID, {
        ...question,
        questionnaire_questions_question: {
          ...question.questionnaire_questions_question,
          id: questionID,
        },
      });
  };

  const handleMedicationTextFieldResponses = (
    value: string,
    questionID: number,
    medicationID: number,
    responseProperty: string | null | undefined,
  ) => {
    // Ensure that textLocalFieldResponses is initialized as an empty array initially
    if (!Array.isArray(textLocalFieldResponses)) {
      setTextLocalFieldResponses([]);
    }

    // Find an existing response with the same questionID and medicationID
    const existingResponseIndex = textLocalFieldResponses.findIndex(
      (response) =>
        response.medicationID === medicationID &&
        response.questionID === questionID,
    );

    if (existingResponseIndex !== -1) {
      // If the response exists, update it
      const updatedResponses = [...textLocalFieldResponses];
      updatedResponses[existingResponseIndex].response = value;
      setTextLocalFieldResponses(updatedResponses);
    } else {
      // If the response doesn't exist, create a new response
      const newResponse: MedicationItemResponse = {
        questionID: questionID,
        medicationID: medicationID,
        response: value,
      };

      setTextLocalFieldResponses([...textLocalFieldResponses, newResponse]);
    }

    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationID) {
        const existingAnswers = medicationItem.answers || [];

        // Check if the questionID already exists in the answers
        const existingAnswerIndex = existingAnswers.findIndex(
          (answer) => answer.questionID === questionID,
        );

        const updatedAnswers =
          existingAnswerIndex !== -1
            ? existingAnswers.map((answer) =>
                answer.questionID === questionID
                  ? {
                      ...answer,
                      response: value,
                      response_property: `${responseProperty}_${medicationID}`,
                    } // Update existing answer
                  : answer,
              )
            : [
                ...existingAnswers,
                {
                  questionID,
                  response: value,
                  response_property: `${responseProperty}_${medicationID}`,
                },
              ]; // Add new answer

        // Create a new object with the updated or new answers and assign it
        medicationItem = { ...medicationItem, answers: updatedAnswers };
      }

      return medicationItem;
    });

    setMedicationList(updatedMedicationList);
  };

  const renderIndividualInformationQuestionFactory = (
    question: questionnaire_questions_question,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION as string)
    ) {
      return null;
    }

    const dynamicProperty =
      question &&
      question.questionnaire_questions_question &&
      question.questionnaire_questions_question.response_property;
    const errorStatus = dynamicProperty ? errors[dynamicProperty] : null;
    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.TEXT:
        if (questionnaire_questions_question.measurement_unit !== 'HEIGHT') {
          return (
            <div className="desktop:min-w-[435px] desktop:max-w-[435px]">
              <InputComponent
                isDisabled={true}
                value={
                  localFormData[
                    question.questionnaire_questions_question
                      .response_property as keyof LocalFormData
                  ]
                }
                noMarginBottom={true}
                type={getUiControlType(
                  questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => handleInputTextChange(e, question)}
                errorStatus={!!errorStatus}
                errorMsgWithIcon={errorStatus}
              />
            </div>
          );
        } else if (
          questionnaire_questions_question.measurement_unit === 'HEIGHT' &&
          question.questionnaire_questions_question.id ===
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_FIRST_FIELD
        ) {
          return (
            <div className="flex">
              <div className="w-full flex flex-row items-center mr-[35px] max-w-[100px]">
                <InputComponent
                  isDisabled={true}
                  value={localFormData.height5}
                  noMarginBottom={true}
                  type={getUiControlType(
                    question.questionnaire_questions_question.ui_control,
                  )}
                  onChange={(e) => {
                    handleInputTextChange(
                      e,
                      question,
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_FIRST_FIELD,
                    );
                  }}
                  errorStatus={!!errors?.height5}
                />
                <p className="ml-[10px] font-semibold text-med-gray">ft.</p>
              </div>
              <div className="w-full flex flex-row items-center max-w-[100px]">
                <InputComponent
                  isDisabled={true}
                  value={localFormData.height8}
                  noMarginBottom={true}
                  type={getUiControlType(
                    question.questionnaire_questions_question.ui_control,
                  )}
                  onChange={(e) => {
                    handleInputTextChange(
                      e,
                      question,
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_SECOND_FIELD,
                    );
                  }}
                  errorStatus={!!errors?.height8}
                />
                <p className="ml-[10px] font-semibold text-med-gray">
                  {dynamicQuestionnaireLocale?.in}
                </p>
              </div>
            </div>
          );
        }
        break;

      case UI_CONTROL.DATE:
        return (
          <DatePicker
            onChange={(e) =>
              handleDatepickerChange(question, e.target.value, 'dob')
            }
            value={dateOfBirth}
            isOpen={false}
            isDisabled={true}
          />
        );

      case UI_CONTROL.SMALL_TEXT:
        if (
          question.questionnaire_questions_question.id ===
          HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD
        ) {
          return (
            <div className="w-[135px] mt-[10px]">
              <InputComponent
                value={
                  localFormData[
                    question.questionnaire_questions_question
                      .response_property as keyof LocalFormData
                  ]
                }
                noMarginBottom={true}
                type={getUiControlType(
                  question.questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => {
                  handleInputTextChange(e, question);
                }}
                errorStatus={!!errorStatus}
              />
            </div>
          );
        } else {
          return (
            <div className="w-[100px] flex items-center">
              <InputComponent
                isDisabled={true}
                value={
                  localFormData[
                    question.questionnaire_questions_question
                      .response_property as keyof LocalFormData
                  ]
                }
                noMarginBottom={true}
                type={getUiControlType(
                  question.questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => {
                  handleInputTextChange(e, question);
                }}
                errorStatus={!!errorStatus}
              />
              {question.questionnaire_questions_question.id ===
              HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.WEIGHT ? (
                <p className="ml-[10px] font-semibold text-med-gray">
                  {dynamicQuestionnaireLocale?.lbs}
                </p>
              ) : (
                <p className="ml-[10px] font-semibold text-med-gray">
                  {dynamicQuestionnaireLocale?.in}
                </p>
              )}
            </div>
          );
        }

      case UI_CONTROL.DROPDOWN_50_PERCENT:
        return (
          <div className="w-full desktop:max-w-[10rem]">
            <CreatableSelect
              value={
                {
                  label: genderSelected,
                  value: genderSelected,
                } as SingleValue<string | DropdownItem>
              }
              isDisabled={true}
              className="dropdown"
              classNamePrefix="react-select"
              options={parseQuestionOptionsForDropdownWithLookup(
                question.questionnaire_questions_question.answers
                  ? question.questionnaire_questions_question.answers
                  : [],
              )}
              onChange={(e) => {
                handleDropdownOptionSelected(e, question);
              }}
            />
          </div>
        );

      case UI_CONTROL.DATE_WITH_HOURS:
        return (
          <div className="flex flex-col mt-[10px]">
            <div className="flex flex-col desktop:flex-row gap-4 desktop:gap-0">
              <div className="desktop:mr-10">
                <DatePicker
                  onChange={(e) => {
                    setUserAnsweredBloodworkQuestions((prevState) => ({
                      ...prevState,
                      [INITIAL_SAMPLE_INDEX]: {
                        ...prevState[INITIAL_SAMPLE_INDEX],
                        date: true,
                      },
                    }));
                    handleDatepickerChange(
                      question,
                      e.target.value,
                      'bloodsample',
                    );
                  }}
                  value={bloodSampleDate}
                  isOpen={false}
                  hasError={bloodSampleDate.length === 0}
                />
              </div>
              <div className="flex">
                <div className="w-full max-w-[120px] mr-[15px]">
                  <InputComponent
                    value={localFormData.blood_sample_time13}
                    placeholder="hh:mm"
                    noMarginBottom={true}
                    type={getUiControlType(
                      question.questionnaire_questions_question.ui_control,
                    )}
                    onChange={(e) => {
                      if (e.target.value.length !== 0) {
                        setUserAnsweredBloodworkQuestions((prevState) => ({
                          ...prevState,
                          [INITIAL_SAMPLE_INDEX]: {
                            ...prevState[INITIAL_SAMPLE_INDEX],
                            hour: true,
                          },
                        }));
                      } else {
                        setUserAnsweredBloodworkQuestions((prevState) => ({
                          ...prevState,
                          [INITIAL_SAMPLE_INDEX]: {
                            ...prevState[INITIAL_SAMPLE_INDEX],
                            hour: false,
                          },
                        }));
                      }
                      handleDateInputTextChange(
                        e,
                        question,
                        FIRST_SAMPLE_TIME_ID,
                      );
                    }}
                    maxLengthValue={5}
                    errorStatus={
                      userAnsweredBloodWorkQuestions[INITIAL_SAMPLE_INDEX]
                        .hour === false ||
                      (userAnsweredBloodWorkQuestions[INITIAL_SAMPLE_INDEX]
                        .hour === true &&
                        !!errors.blood_sample_time13)
                    }
                  />
                </div>
                <div className="w-full max-w-[120px]">
                  <CreatableSelect
                    value={getFirstAmPmPickerValue()}
                    placeholder={dynamicQuestionnaireLocale?.time}
                    className="dropdown"
                    classNamePrefix={
                      !userAnsweredBloodWorkQuestions[INITIAL_SAMPLE_INDEX]
                        .timeOfDay
                        ? 'react-select-with-error'
                        : 'react-select'
                    }
                    options={[
                      { label: AM, value: AM },
                      { label: PM, value: PM },
                    ]}
                    onChange={(e) => {
                      setUserAnsweredBloodworkQuestions((prevState) => ({
                        ...prevState,
                        [INITIAL_SAMPLE_INDEX]: {
                          ...prevState[INITIAL_SAMPLE_INDEX],
                          timeOfDay: true,
                        },
                      }));
                      handleDropdownOptionSelected(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        e,
                        question,
                        FIRST_SAMPLE_AM_PM_ID,
                      );

                      setLocalFormData({
                        ...localFormData,
                        [`${question.questionnaire_questions_question.response_property}${FIRST_SAMPLE_AM_PM_ID}`]:
                          e?.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {new Array(COLLECTION_TIMES).fill(null).map((_, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col desktop:flex-row gap-4 desktop:gap-0 mt-2.5"
                >
                  <div className="desktop:mr-10">
                    <DatePicker
                      onChange={(e) => {
                        setUserAnsweredBloodworkQuestions((prevState) => ({
                          ...prevState,
                          [index + 1]: {
                            ...prevState[(index + 1) as keyof typeof prevState],
                            date: true,
                          },
                        }));
                        handleDatepickerChange(
                          question,
                          e.target.value,
                          'bloodsample',
                          index,
                        );
                      }}
                      value={bloodSamplesCollection[index].bloodSampleDate}
                      isOpen={false}
                      hasError={
                        bloodSamplesCollection[index].bloodSampleDate.length ===
                        0
                      }
                    />
                  </div>
                  <div className="flex">
                    <div className="w-full max-w-[120px] mr-[15px]">
                      <InputComponent
                        value={
                          localFormData[
                            `blood_sample_time${
                              FIRST_SAMPLE_TIME_ID +
                              FULL_DATE_LENGTH * (index + 1)
                            }` as keyof typeof localFormData
                          ]
                        }
                        placeholder="hh:mm"
                        noMarginBottom={true}
                        type={getUiControlType(
                          question.questionnaire_questions_question.ui_control,
                        )}
                        onChange={(e) => {
                          if (e.target.value.length !== 0) {
                            setUserAnsweredBloodworkQuestions((prevState) => ({
                              ...prevState,
                              [index + 1]: {
                                ...prevState[
                                  (index + 1) as keyof typeof prevState
                                ],
                                hour: true,
                              },
                            }));
                          } else {
                            setUserAnsweredBloodworkQuestions((prevState) => ({
                              ...prevState,
                              [index + 1]: {
                                ...prevState[
                                  (index + 1) as keyof typeof prevState
                                ],
                                hour: false,
                              },
                            }));
                          }
                          handleDateInputTextChange(
                            e,
                            question,
                            FIRST_SAMPLE_TIME_ID +
                              FULL_DATE_LENGTH * (index + 1),
                          );
                        }}
                        maxLengthValue={5}
                        errorStatus={
                          userAnsweredBloodWorkQuestions[
                            (index +
                              1) as keyof typeof userAnsweredBloodWorkQuestions
                          ].hour === false
                        }
                      />
                    </div>
                    <div className="w-full max-w-[120px]">
                      <CreatableSelect
                        value={getAmPmPickerValue(index)}
                        placeholder={dynamicQuestionnaireLocale?.time}
                        className="dropdown"
                        classNamePrefix={
                          !userAnsweredBloodWorkQuestions[
                            (index +
                              1) as keyof typeof userAnsweredBloodWorkQuestions
                          ].timeOfDay
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        options={[
                          { label: AM, value: AM },
                          { label: PM, value: PM },
                        ]}
                        onChange={(e) => {
                          setUserAnsweredBloodworkQuestions((prevState) => ({
                            ...prevState,
                            [index + 1]: {
                              ...prevState[
                                (index + 1) as keyof typeof prevState
                              ],
                              timeOfDay: true,
                            },
                          }));
                          handleDropdownOptionSelected(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            e,
                            question,
                            FIRST_SAMPLE_AM_PM_ID +
                              FULL_DATE_LENGTH * (index + 1),
                          );

                          setLocalFormData({
                            ...localFormData,
                            [`${
                              question.questionnaire_questions_question
                                .response_property
                            }${
                              FIRST_SAMPLE_AM_PM_ID +
                              FULL_DATE_LENGTH * (index + 1)
                            }`]: e?.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );

      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const renderMenstrualStatusQuestionFactory = (
    question: questionnaire_questions_question,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS as string)
    ) {
      return null;
    }

    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.DATE:
        return (
          <DatePicker
            onChange={(e) =>
              handleDatepickerChange(question, e.target.value, 'menses')
            }
            value={lastMensesDate}
            isOpen={false}
            inputClassNames="desktop:min-w-[330px] desktop:max-w-[435px]"
          />
        );
      case UI_CONTROL.RADIO_BUTTON:
        return (
          <div className="desktop:min-w-[435px] desktop:max-w-[435px]">
            <div className="flex-row">
              {question.questionnaire_questions_question.answers &&
                question.questionnaire_questions_question.answers.map(
                  (answer) => {
                    return (
                      <div key={answer} className="flex flex-col">
                        <InputComponent
                          key={answer}
                          type={getUiControlType(
                            question.questionnaire_questions_question
                              .ui_control,
                          )}
                          radioInputProps={{
                            radioError: null,
                            radioInputValue: answer,
                            radioInputLabel: answer,
                            radioInputCheckedValue: matchSelectedRadio(
                              question.questionnaire_questions_question.id,
                              answer,
                              radioCheckedState,
                            )
                              ? answer
                              : undefined,
                            onRadioClick: () =>
                              handleRadioClick(
                                question.questionnaire_questions_question.id,
                                answer,
                              ),
                          }}
                        />
                      </div>
                    );
                  },
                )}
            </div>

            {nestedQuestionList.map((questionStatus, key) => (
              <div className="desktop:pl-10 pb-1" key={key}>
                {renderNestedQuestions(
                  questionStatus,
                  question,
                  question.questionnaire_questions_question.id,
                  questionStatus.dependency,
                  handleCheckboxToggle,
                  clearCheckboxState,
                  section,
                  radioCheckedState,
                  setRadioCheckedState,
                  checkboxesCheckedState,
                  setCheckboxesCheckedState,
                  textFieldResponses,
                  setTextFieldResponses,
                )}
              </div>
            ))}
          </div>
        );

      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const renderSymptomsQuestionFactory = (
    question: questionnaire_questions_question,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS as string)
    ) {
      return null;
    }

    const numberOfMarks = 3;
    const range = 100;

    const marks: { [key: number]: string } = {};

    // makes sure that the marks are always splitted between them equally
    for (let i = 0; i <= numberOfMarks; i++) {
      const value = Math.round((range / numberOfMarks) * i);
      const label = `${i} - ${question?.questionnaire_questions_question.answers?.[i]}`;
      marks[value] = label;
    }

    const getValueForSlider = persistentData.filter(
      (item) =>
        item.questionID === question.questionnaire_questions_question.id,
    );

    let matchingKey;
    Object.entries(marks).forEach(([key, value]) => {
      if (value === getValueForSlider[0]?.response) {
        matchingKey = parseInt(key);
      }
    });

    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.SLIDER:
      case UI_CONTROL.TWENTY_FIVE_PRECENT:
        return (
          <div className="w-[100%] mt-[20px] mb-[60px]">
            <Slider
              defaultValue={0}
              value={matchingKey}
              marks={marks}
              step={null}
              onChange={(idx) => {
                handleSliderChange(
                  marks[idx as number],
                  questionnaire_questions_question.id,
                  question,
                );
              }}
            />
          </div>
        );
      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const insertAfter = (
    id: number,
    medication: QuestionnaireResponseInterface,
  ) => {
    const array = [...medicationList];
    const index = medicationList.findIndex((item) => item.id === id);
    array.splice(index + 1, 0, medication);
    setMedicationList(array);
  };

  const insertAfterLastMedication = () => {
    const lastMedication = medicationList[medicationList.length - 1];
    if (lastMedication && lastMedication.id !== undefined) {
      insertAfter(lastMedication.id, {
        id: medicationList.length,
        title: `Medication List ${medicationList.length + 1}`,
        completed: false,
        answers: [],
      });
    } else if (lastMedication === undefined) {
      insertAfter(0, {
        id: 0,
        title: 'Medication List 1',
        completed: false,
        answers: [],
      });
    }
  };

  const removeMedication = (idToRemove: number) => {
    const updatedArray = medicationList
      .filter((medication) => medication.id !== idToRemove)
      .map((medication, index) => ({
        ...medication,
        id: index, // Resetting the id based on the new index
      }));
    setMedicationList(updatedArray);
  };

  useEffect(() => {
    // Check if it's the initial render or if localFormData is different from the previous data
    if (
      prevLocalFormDataRef.current === undefined ||
      localFormData !== prevLocalFormDataRef.current
    ) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = localFormData;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [localFormData, handleValidationCallback, fhirPatientDataLoading]);

  const generateDefaultValues = useCallback(() => {
    const defaultValues: SliderValue[] = [];

    const generatedSymptomsValue = (
      startId: number,
      endId: number,
      currentIndexOfSection: number,
      currentSection: string,
      additionalQuestion: number[] | undefined,
      skipQuestion: number[],
    ) => {
      const result = Array.from({ length: endId - startId + 1 }, (_, index) => {
        const id = startId + index;
        return {
          currentIndexOfSection,
          currentSection,
          questionID: id,
          response: '0 - None',
        };
      });

      if (additionalQuestion) {
        const additionalArray = additionalQuestion.map((id) => {
          return {
            currentIndexOfSection,
            currentSection,
            questionID: id,
            response: '0 - None',
          };
        });

        result.push(...additionalArray);
      }

      return result.filter((item) => !skipQuestion.includes(item.questionID));
    };

    const FEMALE_QUESTIONNAIRE = [
      questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE,
      questionnairesIds.NEUROTRANSMITTER_FEMALE,
      questionnairesIds.HS_CRP_FEMALE,
      questionnairesIds.HEAVY_METALS_FEMALE,
    ];

    const MALE_QUESTIONNAIRE = [
      questionnairesIds.HORMONE_QUESTIONNAIRE_MALE,
      questionnairesIds.NEUROTRANSMITTER_MALE,
      questionnairesIds.HS_CRP_MALE,
      questionnairesIds.HEAVY_METALS_MALE,
    ];

    if (FEMALE_QUESTIONNAIRE.includes(questionnaireID)) {
      const symptomQuestions = [
        {
          indexSection: 3,
          section: HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS_STEP1,
          firstQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_FIRST_STEP_FIRST_QUESTION,
          lastQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_FIRST_STEP_LAST_QUESTION,
          additionalQuestion: [],
          skipQuestion: [],
        },
        {
          section: HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS_STEP2,
          indexSection: 4,
          firstQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_SECOND_STEP_FIRST_QUESTION,
          lastQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_SECOND_STEP_LAST_QUESTION,
          additionalQuestion: [],
          skipQuestion:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_SKIP_IDS,
        },
      ];

      symptomQuestions.forEach(
        ({
          firstQuestionId,
          lastQuestionId,
          section,
          indexSection,
          additionalQuestion,
          skipQuestion,
        }) => {
          const newValue = generatedSymptomsValue(
            firstQuestionId,
            lastQuestionId,
            indexSection,
            section,
            additionalQuestion,
            skipQuestion,
          );

          defaultValues.push(...newValue);
        },
      );
    } else if (MALE_QUESTIONNAIRE.includes(questionnaireID)) {
      const symptomQuestions = [
        {
          indexSection: 2,
          section: HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS_STEP1,
          firstQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_FIRST_STEP_FIRST_QUESTION,
          lastQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_FIRST_STEP_LAST_QUESTION,
          additionalQuestion:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_FIRST_STEP_ADDITION_QUESTION,
          skipQuestion: [],
        },
        {
          section: HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS_STEP2,
          indexSection: 3,
          firstQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_SECOND_STEPS_FIRST_QUESTION,
          lastQuestionId:
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_SECOND_STEP_LAST_QUESTION,
          skipQuestion: [],
        },
      ];

      symptomQuestions.forEach(
        ({
          firstQuestionId,
          lastQuestionId,
          section,
          indexSection,
          additionalQuestion,
          skipQuestion,
        }) => {
          const newValue = generatedSymptomsValue(
            firstQuestionId,
            lastQuestionId,
            indexSection,
            section,
            additionalQuestion,
            skipQuestion,
          );

          defaultValues.push(...newValue);
        },
      );
    }

    return defaultValues;
  }, [questionnaireID]);

  useEffect(() => {
    const defaultValues = generateDefaultValues();

    // Set slider values default values only if it's empty
    if (sliderValues.length === 0) {
      // Update persistentData with the newValues, maintaining the already existing ones filled by the user
      // and generating the ones that are not present based on default values
      setPersistentData((prevState) => {
        const updatedData = [
          ...prevState,
          ...defaultValues.filter(
            (newValue) =>
              !prevState.some(
                (item) => item.questionID === newValue.questionID,
              ),
          ),
        ];

        return updatedData;
      });
      setSliderValues(defaultValues);
    }
  }, [
    generateDefaultValues,
    setPersistentData,
    sliderValues.length,
    submitWasTriggered,
  ]);

  const updateErrorsInSections = useCallback(
    (
      prevState: ModuleFormErrors | null,
      currentSectionName: string,
    ): ModuleFormErrors => {
      const updatedSections = (prevState?.sectionsHasErrors ?? []).map(
        (section) =>
          section === currentSection.name ? currentSection.name : '',
      );

      // Add the value if it doesn't exist
      if (!updatedSections.includes(currentSectionName)) {
        updatedSections.push(currentSectionName);
      }

      return {
        sectionsHasErrors: updatedSections,
      };
    },
    [currentSection.name],
  );

  const checkSectionForNullErrors = useCallback(
    (persistentData: QuestionnaireResponseInterface[]): boolean => {
      let status = false;
      const medicationList: QuestionnaireResponseInterface[] =
        persistentData.filter(
          (data) =>
            data.questionID === MEDICATIONS_IDS.MEDICATIONS_DYNAMIC_LIST,
        );

      const validQuestionIDs = medicationIdsFromHormoneQuestionnaire;

      const hasValidMedicationAnswers = medicationList.every((medication) => {
        const medicationAnswers =
          medication &&
          medication.response &&
          Array.isArray(medication.response) &&
          medication.response.map((item) => item.answers);

        const validLength =
          medicationAnswers &&
          medicationAnswers.every((answers) => {
            return (
              answers &&
              answers.length &&
              answers.length === HORMONE_MEDICATION_MAX_LENGTH
            );
          });

        const hasAllValidIDs =
          medicationAnswers &&
          medicationAnswers.every((answers) => {
            const hasAllValidIDsForAnswers = validQuestionIDs.every(
              (id) =>
                answers &&
                answers.some((answer) => answer.questionID === id) &&
                answers.every((answer) => answer.response !== ''),
            );
            return hasAllValidIDsForAnswers;
          });

        return validLength && hasAllValidIDs;
      });

      // Search for questionID hasMedicationDynamicList and hasTableMedication in persistentData
      const hasMedicationDynamicList = persistentData.some((data) => {
        return (
          data.questionID === MEDICATIONS_IDS.MEDICATIONS_DYNAMIC_LIST &&
          data.response &&
          Array.isArray(data.response) &&
          data.response.length != 0
        );
      });
      const hasTableMedication = persistentData.some(
        (data) => data.questionID === MEDICATIONS_IDS.TABLE_MEDICATIONS,
      );

      if (!hasMedicationDynamicList && !hasTableMedication) {
        return true;
      }

      if (hasTableMedication && !hasMedicationDynamicList) {
        status = true;
      }

      if (hasMedicationDynamicList && !hasTableMedication) {
        status = hasValidMedicationAnswers;
      }

      if (hasTableMedication && hasMedicationDynamicList) {
        status = hasValidMedicationAnswers;
      }

      return status;
    },
    [],
  );

  const handleErrorsUpdate = useCallback(
    (
      prevState: ModuleFormErrors | null,
      currentSectionName: string,
      checkForNullErrors: boolean,
    ): ModuleFormErrors => {
      if (!checkForNullErrors) {
        return updateErrorsInSections(prevState, currentSectionName);
      } else {
        const updatedSections = (prevState?.sectionsHasErrors ?? []).filter(
          (section) => section !== currentSectionName,
        );
        return {
          sectionsHasErrors: updatedSections,
        };
      }
    },
    [updateErrorsInSections],
  );

  useEffect(() => {
    const isSectionValid = checkSectionForNullErrors(persistentData);

    setModuleFormErrors((prevState: ModuleFormErrors | null) =>
      handleErrorsUpdate(prevState, currentSection.name, isSectionValid),
    );
  }, [
    errors,
    setModuleFormErrors,
    currentSection,
    handleErrorsUpdate,
    checkSectionForNullErrors,
    persistentData,
  ]);

  useEffect(() => {
    if (
      section.name === HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS
    ) {
      setPersistentData((prevState) =>
        updateStateWithMedicationValues(
          prevState,
          section,
          medicationList,
          MEDICATIONS_IDS.MEDICATIONS_DYNAMIC_LIST,
        ),
      );
    }
  }, [section, medicationList, textLocalFieldResponses, setPersistentData]);

  useEffect(() => {
    setPersistentData((prevState) => [
      ...prevState,
      {
        currentSection: HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS,
        currentIndexOfSection: 2,
        questionID: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.ENABLE_SHARE_DATA_ID,
        response: 'enable',
      },
    ]);
  }, [setPersistentData]);

  if (
    dynamicQuestionnaireLocaleLoading &&
    !dynamicQuestionnaireLocale &&
    !fhirUserData &&
    fhirLoadingUserData &&
    fhirPatientDataLoading
  ) {
    return null;
  }

  if (currentSection.name === HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS) {
    return (
      <>
        <MenstrualStatusQuestionFactory
          questions={sortedQuestionList}
          testKitData={testKitData}
          submitWasTriggered={submitWasTriggered}
          missingMainQuestionIds={missingMainQuestionIds}
          isRequiredQuestion={isRequiredQuestion}
          renderMenstrualStatusQuestionFactory={
            renderMenstrualStatusQuestionFactory
          }
        />
        <hr className="my-5" />
      </>
    );
  }

  if (
    currentSection.name ===
    HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION
  ) {
    return (
      <>
        <IndividualInformation
          firstName={localFormData.firstname}
          lastName={localFormData.lastname}
          dob={dateOfBirth}
          heightFt={localFormData.height5}
          heightIn={localFormData.height8}
          gender={genderSelected}
          weight={localFormData.weight}
          waist={localFormData.waist}
          testType={testKitData?.testType as string}
          hasWeightError={!!errors?.weight}
          hasWaistError={!!errors?.waist}
          questionList={sortedQuestionList}
          handleInputTextChange={handleInputTextChange}
        />
        <hr className="my-5" />
      </>
    );
  }

  if (
    currentSection.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS_STEP1 ||
    currentSection.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS_STEP2
  ) {
    return (
      <>
        <SymptomsComponent
          testType={testKitData?.testType as string}
          handleSliderChange={handleSliderChange}
          questionList={sortedQuestionList}
          persistentData={persistentData}
        />
        <hr className="my-5" />
      </>
    );
  }

  if (section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SAMPLE_COLLECTIONS) {
    return (
      <>
        <HormoneQuestionnairBanner testType={testKitData?.testType as string} />
        <SampleCollectionSection
          questionnaireId={questionnaireID}
          questionList={sortedQuestionList}
          onChange={onChange}
          persistentData={getPersistentDataBySection(
            HORMONE_QUESTIONNAIRE_SECTIONS.SAMPLE_COLLECTIONS,
          )}
          localFormData={localFormData as unknown as Record<string, string>}
          setLocalFormData={
            setLocalFormData as unknown as React.Dispatch<
              React.SetStateAction<Record<string, string>>
            >
          }
          section={section}
        />
        <hr className="my-5" />
      </>
    );
  }

  if (section.name === HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS) {
    return (
      <>
        <HormonesAndMedications
          handleMedicationTextFieldResponses={
            handleMedicationTextFieldResponses
          }
          setShowTimeElapsedAlert={setShowTimeElapsedAlert}
          removeMedication={removeMedication}
          insertAfterLastMedication={insertAfterLastMedication}
          testType={testKitData?.testType as string}
          questionList={sortedQuestionList}
          medicationList={medicationList}
          showTimeElapsedAlert={showTimeElapsedAlert}
        />
      </>
    );
  }

  return (
    <>
      <HormoneQuestionnairBanner testType={testKitData?.testType as string} />
      <div className="mt-3">
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS &&
          showTimeElapsedAlert && (
            <div className="grid gap-y-2.5 mb-5">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.timeElapsedAlert}
              />
            </div>
          )}

        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION &&
          !allBloodWorkQuestionsAnswered && (
            <div className="grid gap-y-2.5 mb-5">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.salivaAlert}
              />
            </div>
          )}
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION &&
          userHasOutdatedProfileInformation !== null &&
          userHasOutdatedProfileInformation === true && (
            <div className="grid gap-y-2.5 mb-5">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.outdatedInfoAlert}
              />
            </div>
          )}
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION && (
          <p className="text-h7 font-bold uppercase mb-[15px]">
            {dynamicQuestionnaireLocale?.patientDetails}
          </p>
        )}

        {section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS && (
          <p className="pb-10 text-base font-semibold text-dark-gray">
            {dynamicQuestionnaireLocale?.paragraphSymptoms}
          </p>
        )}

        {sortedQuestionList.map((question, idx) => {
          if (
            medicationIdsFromHormoneQuestionnaire.includes(
              question.questionnaire_questions_question.id,
            )
          ) {
            return null; // Skip rendering for these IDs
          }
          return (
            <div key={idx}>
              {question.questionnaire_questions_question.id ===
                HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD && (
                <>
                  <hr className="my-[25px]" />
                  <p className="text-h7 font-bold uppercase mb-[15px] text-neutral-800">
                    {dynamicQuestionnaireLocale?.backgroundInformation}
                  </p>
                </>
              )}
              <div
                className={`w-full flex flex-col justify-start mb-4 
                ${
                  section.name ===
                  HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS
                    ? 'w-full desktop:flex-col'
                    : ''
                }
                ${
                  section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS
                    ? 'w-full desktop:flex-col px-[22px] desktop:px-[120px]'
                    : ''
                }
                ${
                  question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD ||
                  question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.BLOOD_SAMPLE ||
                  question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                    ? 'w-full desktop:flex-col'
                    : 'desktop:flex-row'
                }`}
                key={question.questionnaire_questions_question.id}
              >
                <div
                  className={`flex w-full items-center 
                  ${
                    section.name ===
                    HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS
                      ? 'w-full !max-w-[100%]'
                      : ''
                  }
                  
                  ${
                    question.questionnaire_questions_question.id ===
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD ||
                    question.questionnaire_questions_question.id ===
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.BLOOD_SAMPLE ||
                    question.questionnaire_questions_question.id ===
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                      ? 'w-full'
                      : 'max-w-[250px]'
                  }`}
                >
                  <p
                    className={`
                  flex
                  ${
                    missingMainQuestionIds.includes(
                      question.questionnaire_questions_question.id.toString(),
                    ) && submitWasTriggered
                      ? 'text-clc-red'
                      : 'text-dark-gray'
                  } font-semibold `}
                  >
                    {question.questionnaire_questions_question.text}
                    {isRequiredQuestion(
                      question.questionnaire_questions_question.id.toString(),
                    ) && (
                      <span className="text-base font-bold text-clc-red">
                        {' '}
                        *
                      </span>
                    )}
                  </p>
                  {question.questionnaire_questions_question.subtitle && (
                    <p className="italic text-med-gray">
                      {question.questionnaire_questions_question.subtitle}
                    </p>
                  )}
                </div>

                {section.name ===
                  HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION &&
                  renderIndividualInformationQuestionFactory(question)}
                {questionnaireID ===
                  questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE &&
                  section.name ===
                    HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS &&
                  renderMenstrualStatusQuestionFactory(question)}

                {section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS && (
                  <div className="desktop:px-[60px]">
                    {renderSymptomsQuestionFactory(question)}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS && (
          <>
            <div className="w-full">
              <p className="text-lg text-dark-gray font-semibold mb-5">
                {dynamicQuestionnaireLocale?.medicationHormoneParagraph}
              </p>

              {medicationList.map((medication, medicationIdx) => {
                return (
                  <div key={medicationIdx}>
                    {sortedQuestionList.map((question, idx) => {
                      return (
                        <div key={idx}>
                          <div
                            className={`w-full flex flex-col justify-start mb-4 
                ${
                  question.questionnaire_questions_question.id ===
                  HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                    ? 'w-full desktop:flex-col'
                    : 'desktop:flex-row'
                }`}
                            key={question.questionnaire_questions_question.id}
                          >
                            <div
                              className={`flex w-full items-center 
                  
                  ${
                    question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                      ? 'w-full'
                      : 'max-w-[250px]'
                  }`}
                            >
                              <p
                                className={`
                  flex
                  ${
                    missingMainQuestionIds.includes(
                      question.questionnaire_questions_question.id.toString(),
                    ) && submitWasTriggered
                      ? 'text-clc-red'
                      : 'text-dark-gray'
                  } font-semibold `}
                              >
                                {question.questionnaire_questions_question.text}
                                {isRequiredQuestion(
                                  question.questionnaire_questions_question.id.toString(),
                                ) && (
                                  <span className="text-base font-bold text-clc-red">
                                    {' '}
                                    *
                                  </span>
                                )}
                              </p>
                              {question.questionnaire_questions_question
                                .subtitle && (
                                <p className="italic text-med-gray">
                                  {
                                    question.questionnaire_questions_question
                                      .subtitle
                                  }
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex pb-8">
                      {
                        <button
                          className="flex mr-6"
                          onClick={() => removeMedication(medicationIdx)}
                        >
                          <MinusIcon className="text-clc-blue mr-2" />
                          <span className="text-clc-blue font-bold underline uppercase">
                            {dynamicQuestionnaireLocale.removeMedication}
                          </span>
                        </button>
                      }
                    </div>
                  </div>
                );
              })}
              {medicationList.length === 0 && (
                <div className="min-h-[103px]">
                  <p className="text-base">
                    {dynamicQuestionnaireLocale?.medicationPlaceholderParagraph}
                  </p>
                </div>
              )}

              <div>
                <hr />
                <button
                  className="flex mr-3 my-5"
                  onClick={insertAfterLastMedication}
                >
                  <PlusIcon className="mr-2" />
                  <span className="text-clc-blue font-bold underline uppercase">
                    {medicationList.length > 0
                      ? dynamicQuestionnaireLocale.addAnotherMedication
                      : dynamicQuestionnaireLocale.addMedication}
                  </span>
                </button>
              </div>
            </div>

            <hr className="mt-5" />
            <div className="grid gap-y-2.5 mt-5 mb-1">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.alertShareData}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HormoneQuestionnaireModule;
