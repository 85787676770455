import JoiBase from 'joi';

const globalMessages = {};

const Joi = JoiBase.defaults((schema) =>
  schema
    .options({ abortEarly: false, convert: true, stripUnknown: true })
    .messages(globalMessages),
);

export default Joi;
