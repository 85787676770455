import { monthDaysGridProps } from './tabs/dayTab';

export const MonthDaysGrid: React.FC<monthDaysGridProps> = ({
  monthDays,
  selectedMonth,
  selectedDate,
  onClick,
  isAllowedDateToSelect = () => true,
}) => {
  const generateDateID = (dateValue: Date) => {
    // with magic number +1 we're able to count 12 months instead of 11
    const getMonth = dateValue.getMonth() + 1;
    return `${dateValue.getFullYear()}-${getMonth}-${dateValue.getDate()}`;
  };

  return (
    <tbody>
      {monthDays.map((week, index) => (
        <tr key={week.toString() + index}>
          {
            // Matches off-month, selected, in the future will match available dates
            week.map((date, index) => {
              const disabled = !isAllowedDateToSelect(date);

              let className =
                '__day w-8 h-8 ml-[5px] mr-[5px] text-med-gray-2 text-center text-xs font-medium rounded-[5px]';
              if (date.getMonth() !== selectedMonth) {
                className += ' off-month opacity-0';
              }
              if (
                selectedDate &&
                date.toDateString() === selectedDate.toDateString()
              ) {
                className += ' selected bg-light-blue';
              }

              return (
                <td
                  key={date.toString() + index}
                  className={`${className} ${
                    disabled
                      ? 'opacity-50'
                      : 'cursor-pointer hover:bg-light-blue'
                  }`}
                  id={generateDateID(date)}
                  onClick={disabled ? undefined : onClick}
                >
                  {date.getDate()}
                </td>
              );
            })
          }
        </tr>
      ))}
    </tbody>
  );
};
