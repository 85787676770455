import React from 'react';

export enum DotBodyGroup {
  HEAD = 'head',
  BODY_LEFT = 'bodyLeft',
  UPPER_EXTREMITIES_LEFT = 'upperExtremitiesLeft',
  LOWER_EXTREMITIES_LEFT = 'lowerExtremitiesLeft',
  BODY_RIGHT = 'bodyRight',
  UPPER_EXTREMITIES_RIGHT = 'upperExtremitiesRight',
  LOWER_EXTREMITIES_RIGHT = 'lowerExtremitiesRight',
}

export enum SvgImageTag {
  HEAD_BACK_SVG = 'headBack',
  HEAD_FRONT_SVG = 'headFront',
  BODY_BACK_SVG = 'bodyBack',
  BODY_FRONT_SVG = 'bodyFront',
}

export interface Dot {
  id: number;
  location?: string;
  selected?: boolean;
  strapiKey: string;
  group: DotBodyGroup;
  bodyPoints?: {
    area?: string;
    x: string;
    y: string;
  };
  headPoints?: {
    area?: string;
    x: string;
    y: string;
  };
}

export interface DotCollection {
  front: Dot[];
  back: Dot[];
}

export interface ImageObject {
  image: string;
  mediaId: string;
  description?: string;
  location?: string;
}

export interface AppointmentImage extends ImageObject {
  id: string;
}

export interface BodySymptomsPlacerProps {
  noForm?: boolean;
  frontDots: Dot[];
  backDots: Dot[];
  setFrontDots: React.Dispatch<React.SetStateAction<Dot[]>>;
  setBackDots: React.Dispatch<React.SetStateAction<Dot[]>>;
  mediaPerBodyLocation: Map<string, string | ImageObject>;
  onClick?: (
    label: string,
    direction: 'front' | 'back',
    bodyId: number,
  ) => void;
}

export interface IBodySection {
  dots: Dot[];
  handleBodyParsing: (
    direction: 'front' | 'back',
    id: number,
    isDetails?: boolean,
  ) => void;
  mediaPerBodyLocation: Map<string, string | ImageObject>;
}

export enum BodyParts {
  Abdomen = 1,
  InstepRight,
  ArmCreaseLeft,
  KneeLeft,
  ArmCreaseRight,
  KneeRight,
  ArmLowerFrontLeft,
  LegLowerFrontLeft,
  ArmLowerFrontRight,
  LegLowerFrontRight,
  ArmUpperFrontLeft,
  LegUpperFrontLeft,
  ArmUpperFrontRight,
  LegUpperFrontRight,
  ChecksLeft,
  LipsMouth,
  ChecksRight,
  NeckThroat,
  ChestLeft,
  PalmLeft,
  ChestMiddle,
  PalmRight,
  ChestRight,
  Pelvis,
  ChinJawline,
  Philtrum,
  EyesEyelidsFrontLeft,
  ShouldersFrontLeft,
  EyesEyelidsFrontRight,
  ShouldersFrontRight,
  FootLeft,
  FootRight,
  SideOfBodyLeft,
  SideOfBodyRight,
  Forehead,
  ToesLeft,
  Groin,
  ToesRight,
  HipsLeft,
  WristLeft,
  HipsRight,
  WristRight,
  InstepLeft,

  AnkleLeft,
  FootSoleRight,
  AnkleRight,
  HandBackLeft,
  ArmLowerBackLeft,
  HandBackRight,
  ArmLowerBackRight,
  HeadBack,
  ArmUpperBackLeft,
  HeelLeft,
  ArmUpperBackRight,
  HeelRight,
  BackLower,
  KneeBackLeft,
  BackMiddle,
  KneeBackRight,
  BackUpper,
  LegLowerBackLeft,
  ButtocksLeft,
  LegLowerBackRight,
  ButtocksRight,
  LegUpperBackLeft,
  EarLeft,
  LegUpperBackRight,
  EarRight,
  NeckBack,
  ElbowLeft,
  Scalp,
  ElbowRight,
  ShoulderBackLeft,
  FingersLeft,
  ShoulderBackRight,
  FingersRight,
  ShoulderBladeLeft,
  FootSoleLeft,
  ShoulderBladeRight,
}
