import React from 'react';
import DatePicker, {
  isToday,
  isYesterday,
} from 'components/datePicker/DatePicker';
import Time from './Time';
import { IQustionerySectionProps } from './SampleCollectionHeavyMetal';
import { useSampleCollectionHook } from './useSampleCollectionHook';
import DescriptionField from './DescriptionField';
import { ReactComponent as LargeTube } from '../../../../assets/icons/testKits/largeTube.svg';
import { ReactComponent as SmallTube } from '../../../../assets/icons/testKits/smallTube.svg';

const timeRanges = [
  { min: '5:00', max: '11:59' },
  { min: '12:00', max: '16:59' },
  { min: '17:00', max: '20:59' },
  { min: '21:00', max: '23:59' },
];

const SampleCollectionHormone: React.FC<IQustionerySectionProps> = ({
  questionList,
  localFormData,
  handleDataChange,
  persistentData,
}) => {
  const [date, ...times] = questionList;

  const { handleChangeDate, handleTimeChange } = useSampleCollectionHook(
    localFormData,
    handleDataChange,
  );

  const handleSalivaDate = (dateValue: string) => {
    handleDataChange(dateValue, null, date.questionnaire_questions_question.id);
    times.forEach(({ questionnaire_questions_question }) =>
      handleChangeDate(
        dateValue,
        String(questionnaire_questions_question.response_property),
      ),
    );
  };

  return (
    <div className="desktop:min-w-[565px] flex flex-col gap-4 py-5 desktop:pl-[120px]">
      <div>
        <div className="mb-2 text-neutral-800 text-base font-semibold">
          {date.questionnaire_questions_question.text}
          <span className="text-alert-negative">*</span>
        </div>
        <DatePicker
          onChange={(e) => {
            handleSalivaDate(e.target.value);
          }}
          value={
            persistentData[date.questionnaire_questions_question.id] ||
            undefined
          }
          isOpen={false}
          hasError={
            persistentData[date.questionnaire_questions_question.id]?.length ===
            0
          }
          isAllowedDateToSelect={(date) => {
            return isYesterday(date) || isToday(date);
          }}
        />
        <DescriptionField>Today or yesterday</DescriptionField>
      </div>
      <div className="desktop:grid grid-cols-4 gap-5">
        {times.map((time, index) => (
          <Time
            key={time.questionnaire_questions_question.id}
            label={
              <p className={'text-[18px]'}>
                {String(time.questionnaire_questions_question.text)}{' '}
                <span className="text-alert-negative">*</span>
                <div className="text-neutral-800 text-base font-semibold flex items-center mb-2">
                  {!index ? (
                    <>
                      <LargeTube />
                      <span className={'ml-1'}>Use large tube</span>
                    </>
                  ) : (
                    <>
                      <SmallTube />
                      <span className={'ml-1'}>Use small tube</span>
                    </>
                  )}
                </div>
              </p>
            }
            minTime={timeRanges[index].min}
            maxTime={timeRanges[index].max}
            handleTimeChange={(timeValue) =>
              handleTimeChange(
                timeValue,
                persistentData[date.questionnaire_questions_question.id] ||
                  undefined,
                String(time.questionnaire_questions_question.response_property),
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

export default SampleCollectionHormone;
