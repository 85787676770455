import InputComponent from 'components/inputComponent';
import {
  questionnaire_questions_question,
  QuestionnaireResponseInterface,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import { HormoneQuestionnairBanner } from './HormoneQuestionnairBanner';

interface ISymptomsComponent {
  testType: string;
  questionList: questionnaire_questions_question[];
  persistentData: QuestionnaireResponseInterface[];
  handleSliderChange: (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
  ) => void;
}

export const SymptomsComponent = ({
  testType,
  questionList,
  persistentData,
  handleSliderChange,
}: ISymptomsComponent) => {
  return (
    <div>
      <HormoneQuestionnairBanner testType={testType} />
      <div className="my-[40px] grid grid-cols-4 gap-4 gap-y-[10px] gap-x-[30px]">
        {questionList.map((question) => {
          const getSelectedValue = persistentData.filter(
            (item) =>
              item.questionID === question.questionnaire_questions_question.id,
          );

          return (
            <div className="w-[210px] bg-base-content rounded-[10px] p-5">
              <p className="text-base font-semibold text-btn-black mb-1">
                {question.questionnaire_questions_question.text}
                <span className="text-alert-negative">*</span>
              </p>
              {question.questionnaire_questions_question.answers?.map(
                (answer, i) => {
                  return (
                    <InputComponent
                      type="radio"
                      radioInputProps={{
                        radioError: null,
                        radioInputValue: `${i} - ${answer}`,
                        radioInputLabel: `${i} - ${answer}`,
                        radioInputCheckedValue: (getSelectedValue[0]
                          ?.response ?? '0 - None') as string,
                        onRadioClick: (value) => {
                          handleSliderChange(
                            value,
                            question.questionnaire_questions_question.id,
                            question,
                          );
                        },
                      }}
                    />
                  );
                },
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
