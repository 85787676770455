import {
  CustomClassNamesProps,
  KeyMapProps,
  KeyMapPropValues,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { ReactComponent as SortDownIcon } from 'assets/icons/sortDown.svg';
import { ReactComponent as SortUpIcon } from 'assets/icons/sortUp.svg';
import { capitalizeFirstLetter } from 'utilities/functions';
import { Order_By } from 'graphql/generated/hasura';
import {
  Record,
  Record_Order_By,
} from 'components/codexTable/interfaces/record.interfaces';
import { twMerge } from 'tailwind-merge';
import { PROVIDERS_PROVIDER_BIO } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';

const getKeyProps = (
  keyMapPropValues: KeyMapPropValues,
  orderBy: Record_Order_By,
) => {
  const { isActive, direction } = keyMapPropValues;

  return {
    isActive: isActive(orderBy),
    direction: direction(orderBy),
  };
};

const getClasses = (
  key: string,
  keyMap: KeyMapProps,
  orderBy: Record_Order_By,
  sortUpIconDirection: Order_By,
  sortDownIconDirection: Order_By,
  customClassesProp?: CustomClassNamesProps,
) => {
  if (key in keyMap) {
    const { isActive, direction } = getKeyProps(keyMap[key], orderBy);
    const customClasses = (customClassesProp || {})[key];
    return {
      thClass: twMerge(
        customClasses?.thClass,
        isActive ? 'highlight' : 'text-charcoal-gray',
      ),
      spanClass: twMerge(
        'text-charcoal-gray font-semibold',
        customClasses?.spanClass,
      ),
      sortUpIconClass: twMerge(
        isActive && direction === sortUpIconDirection
          ? 'fill-clc-blue'
          : 'fill-gray-opacity-15',
        customClasses?.sortUpIconClass,
      ),
      sortDownIconClass: twMerge(
        isActive && direction === sortDownIconDirection
          ? 'fill-clc-blue'
          : 'fill-gray-opacity-15',
        customClasses?.sortDownIconClass,
      ),
      flexContainerClass: customClasses?.flexContainerClass,
    };
  }

  return {
    thClass: 'text-dark-gray',
    spanClass: 'text-dark-gray',
    sortUpIconClass: 'fill-gray-opacity-15',
    sortDownIconClass: 'fill-gray-opacity-15',
    flexContainerClass: '',
  };
};

export type localeType = {
  [key: string]: string;
};
interface TableContentProps<T extends Record> {
  columnKeys: string[];
  data: T[];
  orderBy: Record_Order_By;
  loading?: boolean;
  onColumnSortChange: (nextSort: Record_Order_By) => void;
  keyMap: KeyMapProps;
  actions?: React.FC<T & { locale?: localeType }>;
  customClasses?: CustomClassNamesProps;
  theadClassName?: string;
  excludeSort?: string[];
  locale?: localeType;
  bottomBorder?: boolean;
  noDataMessage?: string;
  className?: string;
}

const TableContent = <T extends Record>({
  columnKeys,
  data,
  orderBy,
  loading = false,
  onColumnSortChange,
  keyMap,
  actions: Actions,
  customClasses,
  className,
  theadClassName,
  excludeSort = [],
  locale,
  bottomBorder = false,
  noDataMessage,
}: TableContentProps<T>) => {
  const navigate = useNavigate();
  const requestSort = (key: string) => {
    if (!loading && key in keyMap) {
      const { column, table, direction } = keyMap[key];

      onColumnSortChange(
        table
          ? {
              [table]: {
                [column]:
                  direction(orderBy) === Order_By.Desc
                    ? Order_By.Asc
                    : Order_By.Desc,
              },
            }
          : {
              [column]:
                direction(orderBy) === Order_By.Desc
                  ? Order_By.Desc
                  : Order_By.Asc,
            },
      );
    }
  };

  const handleProviderNameClick = (providerId: string) => {
    navigate(PROVIDERS_PROVIDER_BIO, { state: { providerId } });
  };

  const customActionsClasses = customClasses?.actions?.customRowClassName || '';

  return data.length ? (
    <table
      className={`min-w-full border-collapse hidden desktop:table ${className} ${
        loading ? 'pointer-events-none opacity-50' : ''
      }`}
    >
      <thead>
        <tr className={theadClassName}>
          {columnKeys.map((key) => {
            const {
              thClass,
              spanClass,
              sortUpIconClass,
              sortDownIconClass,
              flexContainerClass,
            } = getClasses(
              key,
              keyMap,
              orderBy,
              Order_By.Asc,
              Order_By.Desc,
              customClasses,
            );
            return (
              <th
                key={key}
                className={`text-left min-w-20 py-2 px-auto ${thClass}`}
              >
                <div className={`flex items-center ${flexContainerClass}`}>
                  <div
                    className={`flex flex-row items-center ${
                      !excludeSort.includes(key) ? 'hover:cursor-pointer' : ''
                    }`}
                    onClick={() =>
                      !excludeSort.includes(key) && requestSort(key)
                    }
                  >
                    <span className={`p-2.5 ${spanClass}`}>
                      {locale
                        ? locale[key] || capitalizeFirstLetter(key)
                        : capitalizeFirstLetter(key)}
                    </span>
                    {!excludeSort.includes(key) && (
                      <>
                        <SortDownIcon
                          className={`inline-block ${sortDownIconClass}`}
                        />
                        <SortUpIcon
                          className={`inline-block ${sortUpIconClass}`}
                        />
                      </>
                    )}
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="border border-transparent border-b-gray-opacity-15">
        {data.map((each, rowIndex) => (
          <tr key={each.id}>
            {columnKeys.map((key, index) => {
              const FormatValue =
                key in keyMap ? keyMap[key].FormatValue : undefined;
              const customClass =
                customClasses &&
                (key in customClasses
                  ? customClasses[key].customRowClassName
                  : '');
              const value = each[key as keyof Record];
              return (
                <td
                  key={key}
                  className={twMerge(
                    `text-base min-w-20 text-med-gray font-semibold py-[21.5px] border-t ${
                      index === 0
                        ? 'text-left'
                        : index === columnKeys.length - 1
                        ? 'pl-[3%]'
                        : 'text-center'
                    }`,
                    customClass,
                    key === 'providerName' && 'cursor-pointer',
                    bottomBorder && rowIndex === data.length - 1 && 'border-b',
                  )}
                  onClick={
                    key === 'providerName'
                      ? () =>
                          handleProviderNameClick(
                            each['providerId' as keyof Record],
                          )
                      : undefined
                  }
                >
                  {FormatValue ? (
                    <FormatValue
                      key={key}
                      value={value}
                      entry={each}
                      locale={locale}
                    />
                  ) : (
                    value
                  )}
                </td>
              );
            })}
            {!!Actions && (
              <td
                className={twMerge(
                  'p-2 border-t',
                  customActionsClasses,
                  bottomBorder && rowIndex === data.length - 1 && 'border-b',
                )}
              >
                <Actions {...each} locale={locale} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <>
      <hr className="hidden desktop:flex desktop:flex-row w-full items-center h-px bg-black-blur" />
      <p className="w-full text-med-gray font-semibold hidden desktop:flex">
        {noDataMessage}
      </p>
    </>
  );
};

export default TableContent;
