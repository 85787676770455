import React from 'react';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';

const ProviderBalance: React.FC<{ balance?: number | null }> = ({
  balance = 0,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  if (!locale || loading) {
    return null;
  }

  return (
    <div className="py-2.5 px-5 bg-white rounded-md flex justify-between gap-4 max-w-[420px]">
      <p className="text-med-gray-3 text-sm font-medium max-w-[260px]">
        {locale?.paymentTime}
      </p>
      <p className="text-med-gray-3 text-sm font-medium text-right">
        {locale?.currentBalance}
        <div className="font-semibold text-base text-neutral-800">
          ${balance ?? 0}
        </div>
      </p>
    </div>
  );
};

export default ProviderBalance;
