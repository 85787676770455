import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import ButtonComponent from 'components/button/buttonComponent';
import {
  DynamicQuestionResponseSection,
  DynamicQuestionnaireDependencie,
  QuestionnaireResponseInterface,
  questionStatusI,
  questionStatusType,
  questionnaire_questions_question,
  RequiredIdsList,
  RequiredNestedIdsList,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import {
  DynamicQuestionnaireCurrentIndexOfSection,
  HandleOnInputChangeType,
  ModuleFormErrors,
  TestKitData,
} from '../interfaces/dynamicQuestionnaire.interface';

import NestedQuestionsComponent from './NestedQuestionsComponent';
import DNAQuestionnaireModule from '../modules/DNAQuestionnaireModule';
import {
  componentIds,
  genericActionsIds,
  questionnairesIds,
  unauthenticatedQuestionnairesIds,
} from 'utilities/constants';
import HealthQuestionnaireModule from '../modules/HealthQuestionnaireModule';
import InputComponent from 'components/inputComponent';
import { GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery } from 'graphql/generated/hasura';
import SignUpProviderQuestionnaireModule from '../modules/SignUpProviderModule';
import HormoneQuestionnaireModule from '../modules/HormoneQuestionnaireModule';
import NavigateBlockModal from 'components/NavigateBlockModal';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetComponent } from 'hooks/useGetComponent';
import { twMerge } from 'tailwind-merge';
import { TimeSlotType } from 'hooks/useSignUpProviderSchedule.';
import { BillingInfoProps } from '../interfaces/SignUpProviderModule.interface';

export interface CheckboxResponse {
  questionID: number | undefined;
  response: string;
  section: string;
  checked: boolean;
}

export interface DynamicQuestionnaireAnswer {
  id: number;
  response: string;
  section?: string;
  checked?: boolean;
}

interface dynamicQuestionnaireMainViewProps {
  questionnaireID: number;
  responseQuestions: questionnaire_questions_question[];
  currentIndexSection: DynamicQuestionnaireCurrentIndexOfSection;
  section: DynamicQuestionResponseSection;
  questions: questionnaire_questions_question[];
  questionnaireData: Array<QuestionnaireResponseInterface>;
  ctaLabel: string | number | undefined;
  disableSubmit?: boolean;
  onChange: HandleOnInputChangeType;
  onDropdownWithMultiSelectChange: (
    value: string[],
    questionID: number,
  ) => void;
  onDoubleTextFieldChange: (
    value: QuestionnaireResponseInterface[],
    questionID: number,
  ) => void;
  enableSubmit: () => void;
  setPersistentData: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  selectsFromStorage?: QuestionnaireResponseInterface[];
  medicationsDataFromStorage?: QuestionnaireResponseInterface[];
  radioCheckedState: QuestionnaireResponseInterface[];
  checkboxesCheckedState: QuestionnaireResponseInterface[];
  textFieldResponses: QuestionnaireResponseInterface[];
  setRadioCheckedState: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  setCheckboxesCheckedState: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  setTextFieldResponses: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  enableSubmitCheckbox: {
    enable: boolean;
    content?: string | null | undefined;
    link?: string | null | undefined;
    linkLabel?: string;
    highlightLink?: boolean;
    showLinkOnAllViews?: boolean;
  };
  onSubmitCheckboxLink: () => void;
  calculateLastSectionPosition: boolean;
  isSubmitCheckboxChecked: boolean;
  handleOnSubmitCheckbox: Dispatch<SetStateAction<boolean>>;
  requiredQuestionsIds: RequiredIdsList;
  requiredNestedQuestionsIds: RequiredNestedIdsList;
  refetchData:
    | (() => Promise<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery>)
    | undefined;
  getIncompleteMainQuestion: () => string[];
  getIncompleteNestedQuestions: () => RequiredNestedIdsList;
  submitWasTriggered: boolean;
  setModuleFormErrors: Dispatch<SetStateAction<ModuleFormErrors | null>>;
  isUnathenticatedQuestionnaire?: boolean;
  persistentData: QuestionnaireResponseInterface[];
  hormoneTableMedicationsSelected: QuestionnaireResponseInterface[];
  setHormoneTableMedicationsSelected: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  hormoneTableCheckboxesCheckedState: QuestionnaireResponseInterface[];
  setHormoneTableCheckboxesCheckedState: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  hormoneTableCheckboxCheckAll: boolean;
  setHormoneTableCheckboxAll: Dispatch<React.SetStateAction<boolean>>;
  moduleFormErrors: ModuleFormErrors | null;
  testKitData?: TestKitData;
  onSkipSection?: () => void;
  schedule?: Array<Array<TimeSlotType>>;
  setSchedule?: Dispatch<React.SetStateAction<Array<Array<TimeSlotType>>>>;
  billingInfoProps: BillingInfoProps;
}

export interface radioCheckedStateInterface {
  section: DynamicQuestionResponseSection;
  value: string;
  questionID: number;
}

const DynamicQuestionnaireMainView: React.FC<
  dynamicQuestionnaireMainViewProps
> = ({
  persistentData,
  setPersistentData,
  questionnaireID,
  responseQuestions,
  currentIndexSection,
  section,
  questions,
  questionnaireData,
  ctaLabel,
  disableSubmit = false,
  onChange,
  onDropdownWithMultiSelectChange,
  onDoubleTextFieldChange,
  enableSubmit,
  radioCheckedState,
  checkboxesCheckedState,
  textFieldResponses,
  setRadioCheckedState,
  setCheckboxesCheckedState,
  setTextFieldResponses,
  selectsFromStorage,
  medicationsDataFromStorage,
  enableSubmitCheckbox,
  onSubmitCheckboxLink,
  calculateLastSectionPosition,
  handleOnSubmitCheckbox,
  isSubmitCheckboxChecked,
  requiredQuestionsIds,
  requiredNestedQuestionsIds,
  refetchData,
  getIncompleteMainQuestion,
  getIncompleteNestedQuestions,
  submitWasTriggered,
  setModuleFormErrors,
  isUnathenticatedQuestionnaire = false,
  hormoneTableMedicationsSelected,
  setHormoneTableMedicationsSelected,
  hormoneTableCheckboxesCheckedState,
  setHormoneTableCheckboxesCheckedState,
  hormoneTableCheckboxCheckAll,
  setHormoneTableCheckboxAll,
  moduleFormErrors,
  testKitData,
  onSkipSection,
  schedule,
  setSchedule,
  billingInfoProps,
}) => {
  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.REQUIRED],
  });

  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [mfaPhone, setMfaPhone] = useState<string>('');
  const [discardMfaPhone, setDiscardMfaPhone] = useState<boolean>(false);
  const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false);
  const [isBlockSignUp, setIsBlockSignUp] = useState<boolean>(true);

  const handleSubmitClick = () => {
    if (!isSubmitClicked) {
      setIsSubmitClicked(true);
    }
    enableSubmit();
  };

  const isHideHeaderContentComponent =
    questionnaireID === questionnairesIds.SIGN_UP_PROVIDER &&
    currentIndexSection.index === 6;

  const clearCheckboxState = (questionID: number) => {
    const filteredCheckboxResponses = (
      prevState: QuestionnaireResponseInterface[],
    ) =>
      prevState.filter(
        (checkbox: QuestionnaireResponseInterface) =>
          checkbox.section !== section.name ||
          checkbox.questionID !== questionID,
      );
    setCheckboxesCheckedState((prevState) =>
      filteredCheckboxResponses(prevState),
    );
  };

  const enableBorderRadius = useMemo(() => {
    if (currentIndexSection.index === 0) {
      return 'desktop:rounded-b-medium desktop:rounded-tr-medium';
    } else if (currentIndexSection.index === currentIndexSection.length - 1) {
      return 'desktop:rounded-t-medium desktop:rounded-br-medium';
    }

    return 'desktop:rounded-medium';
  }, [currentIndexSection]);

  const getQuestionsList = () => {
    const questionList: questionnaire_questions_question[] = [];

    questions.forEach((value) => {
      let tempQuestion: questionnaire_questions_question = {
        questionnaire_questions_question: {
          id: value.questionnaire_questions_question.id,
          text: value.questionnaire_questions_question.text,
          subtitle: value.questionnaire_questions_question.subtitle,
          ui_control: value.questionnaire_questions_question.ui_control,
          answers: value.questionnaire_questions_question.answers,
          dont_know_answer:
            value.questionnaire_questions_question.dont_know_answer,
          double_text_combo_helper_texts:
            value.questionnaire_questions_question
              .double_text_combo_helper_texts,
          dropdown_combo_answers:
            value.questionnaire_questions_question.dropdown_combo_answers,
          dropdown_label_1:
            value.questionnaire_questions_question.dropdown_label_1,
          dropdown_label_2:
            value.questionnaire_questions_question.dropdown_label_2,
          response_property:
            value.questionnaire_questions_question.response_property,
          has_tooltip: value.questionnaire_questions_question.has_tooltip,
          tooltip_content:
            value.questionnaire_questions_question.tooltip_content,
          measurement_unit:
            value.questionnaire_questions_question.measurement_unit,
          placeholder: value.questionnaire_questions_question.placeholder,
        },
      };
      section.properties.filter((sectionId) => {
        if (sectionId == value.questionnaire_questions_question.id.toString()) {
          tempQuestion = {
            questionnaire_questions_question: {
              id: value.questionnaire_questions_question.id,
              text: value.questionnaire_questions_question.text,
              subtitle: value.questionnaire_questions_question.subtitle,
              ui_control: value.questionnaire_questions_question.ui_control,
              answers: value.questionnaire_questions_question.answers,
              dont_know_answer:
                value.questionnaire_questions_question.dont_know_answer,
              double_text_combo_helper_texts:
                value.questionnaire_questions_question
                  .double_text_combo_helper_texts,
              dropdown_combo_answers:
                value.questionnaire_questions_question.dropdown_combo_answers,
              dropdown_label_1:
                value.questionnaire_questions_question.dropdown_label_1,
              dropdown_label_2:
                value.questionnaire_questions_question.dropdown_label_2,
              response_property:
                value.questionnaire_questions_question.response_property,
              has_tooltip: value.questionnaire_questions_question.has_tooltip,
              tooltip_content:
                value.questionnaire_questions_question.tooltip_content,
              measurement_unit:
                value.questionnaire_questions_question.measurement_unit,
              placeholder: value.questionnaire_questions_question.placeholder,
            },
          };
          questionList.push(tempQuestion);
        }
      });
    });

    return questionList;
  };

  const getQuestionIdFromDependency = (
    dependency: DynamicQuestionnaireDependencie,
  ) => {
    const propertyId = Object.keys(dependency.if.properties)[0];
    return propertyId.toString();
  };

  const getQuestionStatus = (
    question: questionnaire_questions_question,
    conditionType: questionStatusType,
  ) => {
    const propertyId = question.questionnaire_questions_question.id;
    const isPropertyPresent =
      (propertyId && section.properties.includes(propertyId.toString())) ||
      false;

    // Initialize the dependency value, type, and questionId to null
    let dependencyValue = null;
    let dependencyType = null;
    let questionId = null;

    // Check if the question should meet the specified condition based on dependencies
    const isConditionMet =
      section &&
      section.dependencies?.some((dependency) => {
        if (
          conditionType === 'enabled' &&
          question.questionnaire_questions_question.id &&
          question.questionnaire_questions_question.id.toString() ===
            Object.keys(dependency.if.properties)[0]
        ) {
          dependencyValue = dependency.then.enabled?.[0];
          dependencyType = 'enabled';
          questionId = getQuestionIdFromDependency(dependency);
          return true;
        }
        if (
          conditionType === 'disabled' &&
          question.questionnaire_questions_question.id &&
          question.questionnaire_questions_question.id.toString() ===
            dependency.then.disabled?.[0]
        ) {
          dependencyValue = dependency.then.disabled?.[0];
          dependencyType = 'disabled';
          questionId = getQuestionIdFromDependency(dependency);
          return true;
        }
        return false;
      });

    const questionStatusResponse: questionStatusI = {
      status: isPropertyPresent && isConditionMet,
      dependency: dependencyValue,
      type: dependencyType,
      questionId: questionId,
    };

    return questionStatusResponse;
  };

  const findQuestionByIdInResponseQuestions = (id: string) => {
    const foundQuestion = responseQuestions.find(
      (question: questionnaire_questions_question) => {
        return question.questionnaire_questions_question.id.toString() === id;
      },
    );
    return foundQuestion || null;
  };

  const renderNestedQuestions = (
    questionStatus: questionStatusI,
    question: questionnaire_questions_question,
    questionParentID: number,
    childrenOriginalQuestionID: string | null | undefined,
    handleCheckboxToggle: (
      answer: string,
      questionID: number | undefined,
      questionParentID?: number,
      childrenOriginalQuestionID?: string,
    ) => void,
    clearCheckboxState: (questionID: number) => void,
    currentSection: DynamicQuestionResponseSection,
    radioCheckedState?: QuestionnaireResponseInterface[],
    setRadioCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    checkboxesCheckedState?: QuestionnaireResponseInterface[],
    setCheckboxesCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    textFieldResponses?: QuestionnaireResponseInterface[],
    setTextFieldResponses?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
  ) => {
    const originalQuestion = questionList.find(
      (q) =>
        q.questionnaire_questions_question.id.toString() ===
        questionStatus.questionId,
    );

    if (
      originalQuestion?.questionnaire_questions_question.id ==
      question.questionnaire_questions_question.id
    ) {
      const dependencyQuestion = questionStatus && questionStatus.dependency;

      let questionToRender: questionnaire_questions_question | null = null;

      if (dependencyQuestion) {
        questionToRender =
          findQuestionByIdInResponseQuestions(dependencyQuestion);
      } else {
        return null;
      }

      let thirdLevelQuestionData = null;

      if (questionToRender !== null) {
        thirdLevelQuestionData = getQuestionStatus(questionToRender, 'enabled');
      }

      let thirdLevelQuestionFinalRender = null;

      if (
        thirdLevelQuestionData?.questionId !== undefined &&
        thirdLevelQuestionData?.questionId ===
          thirdLevelQuestionData?.questionId
      ) {
        const nestedDependencyQuestion = thirdLevelQuestionData?.dependency;

        if (nestedDependencyQuestion !== null) {
          thirdLevelQuestionFinalRender = findQuestionByIdInResponseQuestions(
            nestedDependencyQuestion,
          );
        }
      }

      const answerChecked = questionnaireData.find(
        (value) =>
          value.questionID === questionParentID &&
          currentIndexSection.index === value.currentIndexOfSection,
      )?.response;

      const findNestedAnswerChecked = () => {
        for (const value of questionnaireData) {
          if (
            value.questionID === questionParentID &&
            currentIndexSection.index === value.currentIndexOfSection
          ) {
            if (Array.isArray(value.response)) {
              const target = value.response.find(
                (item) =>
                  item.questionID?.toString() === childrenOriginalQuestionID,
              );
              if (target) {
                return target.response;
              }
            }
          }
        }
        return undefined; // Return undefined if no match is found
      };

      if (
        thirdLevelQuestionData !== null ||
        questionToRender !== null ||
        thirdLevelQuestionFinalRender !== null
      ) {
        return (
          <NestedQuestionsComponent
            thirdLevelParentId={thirdLevelQuestionData?.questionId}
            thirdLevelQuestionToRender={thirdLevelQuestionFinalRender}
            questionStatus={questionStatus}
            questionToRender={questionToRender}
            answerChecked={answerChecked as string}
            nestedAnswerChecked={findNestedAnswerChecked() as string}
            onChange={onChange}
            questionParentID={questionParentID}
            childrenOriginalQuestionID={childrenOriginalQuestionID}
            handleCheckboxToggle={handleCheckboxToggle}
            clearCheckboxState={clearCheckboxState}
            currentSection={section}
            radioCheckedState={radioCheckedState}
            setRadioCheckedState={setRadioCheckedState}
            checkboxesCheckedState={checkboxesCheckedState}
            setCheckboxesCheckedState={setCheckboxesCheckedState}
            textFieldResponses={textFieldResponses}
            setTextFieldResponses={setTextFieldResponses}
            requiredNestedQuestionsIds={requiredNestedQuestionsIds}
            getIncompleteNestedQuestions={getIncompleteNestedQuestions}
            submitWasTriggered={submitWasTriggered}
          />
        );
      } else {
        return;
      }
    }

    return null;
  };

  const questionList = getQuestionsList();

  const nestedQuestionList = questionList.map((question) =>
    getQuestionStatus(question, 'enabled'),
  );

  const handleCheckboxToggle = (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => {
    const existingIndex = checkboxesCheckedState.findIndex(
      (checkbox: QuestionnaireResponseInterface) => {
        return (
          checkbox.response === answer &&
          checkbox.section === section.name &&
          checkbox.questionID === questionID
        );
      },
    );

    let updatedAnswerChecked: QuestionnaireResponseInterface[];

    if (existingIndex !== -1) {
      // Toggle the checked property
      updatedAnswerChecked = checkboxesCheckedState.map(
        (checkbox: QuestionnaireResponseInterface) =>
          checkbox.response === answer &&
          checkbox.section === section.name &&
          checkbox.questionID === questionID
            ? { ...checkbox, checked: !checkbox.checked }
            : checkbox,
      );
    } else {
      // Checkbox doesn't exist, add it with checked set to true
      const newCheckbox: CheckboxResponse = {
        questionID: questionID,
        response: answer,
        section: section.name,
        checked: true,
      };
      updatedAnswerChecked = [...checkboxesCheckedState, newCheckbox];
    }

    // Remove checkboxes with checked set to false
    updatedAnswerChecked = updatedAnswerChecked.filter(
      (checkbox: QuestionnaireResponseInterface) => checkbox.checked !== false,
    );

    // Filter responses based on current section.name and questionID
    const filteredResponses = updatedAnswerChecked.filter(
      (checkbox: QuestionnaireResponseInterface) =>
        checkbox.section === section.name && checkbox.questionID === questionID,
    );

    setCheckboxesCheckedState(updatedAnswerChecked);

    onChange(
      filteredResponses,
      questionID || 0,
      undefined,
      questionParentID,
      childrenOriginalQuestionID,
    );
  };

  const handleRadioClick = (question: number, value: string | undefined) => {
    const questionID = question;
    const formattedValue: string | DynamicQuestionnaireAnswer[] = value || '';

    const getExistingRadio = (prevState: QuestionnaireResponseInterface[]) => {
      const existingSectionIndex = prevState.findIndex(
        (item: QuestionnaireResponseInterface) =>
          item.questionID === questionID,
      );

      if (existingSectionIndex !== -1) {
        const updatedState = [...prevState];
        updatedState[existingSectionIndex] = {
          section: section.name,
          response: value,
          questionID,
        };
        return updatedState;
      } else {
        return [
          ...prevState,
          { section: section.name, response: value, questionID },
        ];
      }
    };

    if (setRadioCheckedState) {
      setRadioCheckedState(
        (radioCheckedState: QuestionnaireResponseInterface[]) =>
          getExistingRadio(radioCheckedState),
      );
    }

    onChange(formattedValue, question, undefined);
  };

  const matchSelectedRadio = (question: number, stringToMatch: string) => {
    return radioCheckedState?.find(
      (item: QuestionnaireResponseInterface) =>
        item.response === stringToMatch && item.questionID === question,
    );
  };

  const getSectionHeader = () => {
    if (
      questionnaireID !== questionnairesIds.SIGN_UP_PROVIDER ||
      currentIndexSection.index === 6
    )
      return null;

    if (!currentIndexSection.index) {
      return (
        <p className="my-[10px] text-base text-med-gray-3 font-medium">
          {locale?.title1}{' '}
          <span className="text-btn-black font-semibold">{locale?.title2}</span>
          {locale?.title3}{' '}
          <span className="text-btn-black font-semibold">
            {locale?.subtitle}
          </span>
        </p>
      );
    }

    if (currentIndexSection.index === 2) {
      return (
        <p className="my-[10px] text-base text-med-gray-3 font-medium">
          {locale?.titleMfa}
        </p>
      );
    }

    if (currentIndexSection.index === 5) {
      return (
        <p className="my-[10px] text-base text-med-gray-3 font-medium">
          {locale?.titleSchedule}
        </p>
      );
    }

    return null;
  };

  return (
    <div
      className={`flex flex-col w-full bg-white ${
        questionnaireID !== questionnairesIds.SIGN_UP_PROVIDER
          ? 'py-[30px] px-[60px] '
          : currentIndexSection.index === 6
          ? '!bg-transparent p-0 m-0'
          : 'desktop:py-[30px] desktop:px-[60px] p-[20px] mb-[30px]'
      } max-[1300px]:rounded-[10px] ${enableBorderRadius}`}
    >
      {!isHideHeaderContentComponent && (
        <div>
          {
            <>
              <div className="w-full flex justify-between items-center mb-2">
                <h3 className="text-h6 font-semibold text-btn-black desktop:text-h4">
                  {section.name}
                </h3>
                <p className="hidden desktop:flex text-sm text-alert-negative font-semibold">
                  * Required
                </p>
              </div>

              {getSectionHeader()}
            </>
          }
          <hr />
          <p className="flex desktop:hidden text-sm text-alert-negative font-bold mt-2">
            {genericAction?.[genericActionsIds.REQUIRED].required}
          </p>
        </div>
      )}

      {((questionnaireID === questionnairesIds.HORMONE_QUESTIONNAIRE_MALE ||
        questionnaireID === questionnairesIds.HS_CRP_MALE ||
        questionnaireID === questionnairesIds.NEUROTRANSMITTER_MALE ||
        questionnaireID === questionnairesIds.HEAVY_METALS_MALE) &&
        !isUnathenticatedQuestionnaire && (
          <>
            <HormoneQuestionnaireModule
              questionList={questionList}
              questionnaireData={questionnaireData}
              currentIndexSection={currentIndexSection}
              radioCheckedState={radioCheckedState}
              checkboxesCheckedState={checkboxesCheckedState}
              section={section}
              onChange={onChange}
              handleCheckboxToggle={handleCheckboxToggle}
              setRadioCheckedState={setRadioCheckedState}
              setCheckboxesCheckedState={setCheckboxesCheckedState}
              matchSelectedRadio={matchSelectedRadio}
              handleRadioClick={handleRadioClick}
              nestedQuestionList={nestedQuestionList}
              renderNestedQuestions={renderNestedQuestions}
              clearCheckboxState={clearCheckboxState}
              textFieldResponses={textFieldResponses}
              setTextFieldResponses={setTextFieldResponses}
              requiredQuestionsIds={requiredQuestionsIds}
              getIncompleteMainQuestion={getIncompleteMainQuestion}
              submitWasTriggered={submitWasTriggered}
              setPersistentData={setPersistentData}
              questionnaireID={questionnaireID}
              persistentData={persistentData}
              onDoubleTextFieldChange={onDoubleTextFieldChange}
              currentSection={section}
              setModuleFormErrors={setModuleFormErrors}
              hormoneTableMedicationsSelected={hormoneTableMedicationsSelected}
              setHormoneTableMedicationsSelected={
                setHormoneTableMedicationsSelected
              }
              hormoneTableCheckboxesCheckedState={
                hormoneTableCheckboxesCheckedState
              }
              setHormoneTableCheckboxesCheckedState={
                setHormoneTableCheckboxesCheckedState
              }
              hormoneTableCheckboxCheckAll={hormoneTableCheckboxCheckAll}
              setHormoneTableCheckboxAll={setHormoneTableCheckboxAll}
              testKitData={testKitData}
            />

            <NavigateBlockModal isBlock={true} />
          </>
        )) ||
        ((questionnaireID === questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE ||
          questionnaireID === questionnairesIds.HS_CRP_FEMALE ||
          questionnaireID === questionnairesIds.NEUROTRANSMITTER_FEMALE ||
          questionnaireID === questionnairesIds.HEAVY_METALS_FEMALE) &&
          !isUnathenticatedQuestionnaire && (
            <>
              <HormoneQuestionnaireModule
                questionList={questionList}
                questionnaireData={questionnaireData}
                currentIndexSection={currentIndexSection}
                radioCheckedState={radioCheckedState}
                checkboxesCheckedState={checkboxesCheckedState}
                section={section}
                onChange={onChange}
                handleCheckboxToggle={handleCheckboxToggle}
                setRadioCheckedState={setRadioCheckedState}
                setCheckboxesCheckedState={setCheckboxesCheckedState}
                matchSelectedRadio={matchSelectedRadio}
                handleRadioClick={handleRadioClick}
                nestedQuestionList={nestedQuestionList}
                renderNestedQuestions={renderNestedQuestions}
                clearCheckboxState={clearCheckboxState}
                textFieldResponses={textFieldResponses}
                setTextFieldResponses={setTextFieldResponses}
                requiredQuestionsIds={requiredQuestionsIds}
                getIncompleteMainQuestion={getIncompleteMainQuestion}
                submitWasTriggered={submitWasTriggered}
                setPersistentData={setPersistentData}
                questionnaireID={questionnaireID}
                persistentData={persistentData}
                onDoubleTextFieldChange={onDoubleTextFieldChange}
                currentSection={section}
                setModuleFormErrors={setModuleFormErrors}
                hormoneTableMedicationsSelected={
                  hormoneTableMedicationsSelected
                }
                setHormoneTableMedicationsSelected={
                  setHormoneTableMedicationsSelected
                }
                hormoneTableCheckboxesCheckedState={
                  hormoneTableCheckboxesCheckedState
                }
                setHormoneTableCheckboxesCheckedState={
                  setHormoneTableCheckboxesCheckedState
                }
                hormoneTableCheckboxCheckAll={hormoneTableCheckboxCheckAll}
                setHormoneTableCheckboxAll={setHormoneTableCheckboxAll}
                testKitData={testKitData}
              />

              <NavigateBlockModal isBlock={true} />
            </>
          )) ||
        (questionnaireID ===
          unauthenticatedQuestionnairesIds.SIGN_UP_PROVIDER &&
          isUnathenticatedQuestionnaire && (
            <>
              <SignUpProviderQuestionnaireModule
                questionList={questionList}
                questionnaireData={questionnaireData}
                currentIndexSection={currentIndexSection}
                radioCheckedState={radioCheckedState}
                checkboxesCheckedState={checkboxesCheckedState}
                section={section}
                onChange={onChange}
                handleCheckboxToggle={handleCheckboxToggle}
                setRadioCheckedState={setRadioCheckedState}
                setCheckboxesCheckedState={setCheckboxesCheckedState}
                matchSelectedRadio={matchSelectedRadio}
                handleRadioClick={handleRadioClick}
                nestedQuestionList={nestedQuestionList}
                renderNestedQuestions={renderNestedQuestions}
                clearCheckboxState={clearCheckboxState}
                textFieldResponses={textFieldResponses}
                setTextFieldResponses={setTextFieldResponses}
                requiredQuestionsIds={requiredQuestionsIds}
                moduleFormErrors={moduleFormErrors}
                setModuleFormErrors={setModuleFormErrors}
                currentSection={section}
                onDropdownWithMultiSelectChange={
                  onDropdownWithMultiSelectChange
                }
                setPersistentData={setPersistentData}
                isSubmitClicked={isSubmitClicked}
                setIsSubmitClicked={setIsSubmitClicked}
                mfaPhone={mfaPhone}
                setMfaPhone={setMfaPhone}
                discardMfaPhone={discardMfaPhone}
                setDiscardMfaPhone={setDiscardMfaPhone}
                isVerifyOpen={isVerifyOpen}
                setIsVerifyOpen={setIsVerifyOpen}
                schedule={schedule}
                setSchedule={setSchedule}
                billingInfoProps={billingInfoProps}
                setIsBlockSignUp={setIsBlockSignUp}
              />

              <NavigateBlockModal isBlock={isBlockSignUp} />
            </>
          )) ||
        (questionnaireID === questionnairesIds.HEALTH_QUESTIONNAIRE &&
          !isUnathenticatedQuestionnaire && (
            <HealthQuestionnaireModule
              setPersistentData={setPersistentData}
              questionList={questionList}
              questionnaireData={questionnaireData}
              currentIndexSection={currentIndexSection}
              radioCheckedState={radioCheckedState}
              checkboxesCheckedState={checkboxesCheckedState}
              section={section}
              onChange={onChange}
              handleCheckboxToggle={handleCheckboxToggle}
              setRadioCheckedState={setRadioCheckedState}
              matchSelectedRadio={matchSelectedRadio}
              handleRadioClick={handleRadioClick}
              textFieldResponses={textFieldResponses}
              setTextFieldResponses={setTextFieldResponses}
              onDropdownWithMultiSelectChange={onDropdownWithMultiSelectChange}
              onDoubleTextFieldChange={onDoubleTextFieldChange}
              selectsFromStorage={selectsFromStorage}
              medicationsDataFromStorage={medicationsDataFromStorage}
              requiredQuestionsIds={requiredQuestionsIds}
              refetchData={refetchData}
            />
          )) ||
        (questionnaireID === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT &&
          !isUnathenticatedQuestionnaire && (
            <>
              <DNAQuestionnaireModule
                questionList={questionList}
                questionnaireData={questionnaireData}
                currentIndexSection={currentIndexSection}
                radioCheckedState={radioCheckedState}
                checkboxesCheckedState={checkboxesCheckedState}
                section={section}
                onChange={onChange}
                handleCheckboxToggle={handleCheckboxToggle}
                setRadioCheckedState={setRadioCheckedState}
                setCheckboxesCheckedState={setCheckboxesCheckedState}
                matchSelectedRadio={matchSelectedRadio}
                handleRadioClick={handleRadioClick}
                nestedQuestionList={nestedQuestionList}
                renderNestedQuestions={renderNestedQuestions}
                clearCheckboxState={clearCheckboxState}
                textFieldResponses={textFieldResponses}
                setTextFieldResponses={setTextFieldResponses}
                requiredQuestionsIds={requiredQuestionsIds}
                getIncompleteMainQuestion={getIncompleteMainQuestion}
                submitWasTriggered={submitWasTriggered}
              />

              <NavigateBlockModal isBlock={true} />
            </>
          ))}

      <div className="flex flex-col mt-auto">
        {![
          questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE,
          questionnairesIds.HORMONE_QUESTIONNAIRE_MALE,
        ].includes(questionnaireID) && <hr className="pb-5" />}
        <div className="flex desktop:flex-row flex-col">
          {calculateLastSectionPosition &&
            enableSubmitCheckbox.enable &&
            enableSubmitCheckbox.content &&
            enableSubmitCheckbox.linkLabel && (
              <div className="flex desktop:mr-5">
                <InputComponent
                  type="checkbox"
                  name="labSampleAndResultPrivacyStatement"
                  checkboxProps={{
                    checked: isSubmitCheckboxChecked,
                    onCheckboxChange: handleOnSubmitCheckbox,
                  }}
                />
                <label
                  className="ml-3 group"
                  htmlFor="labSampleAndResultPrivacyStatement"
                >
                  <span className="group-hover:cursor-pointer">
                    {enableSubmitCheckbox.content}
                  </span>{' '}
                  <span
                    onClick={onSubmitCheckboxLink}
                    className="text-base font-bold text-clc-blue underline cursor-pointer"
                  >
                    {enableSubmitCheckbox.linkLabel}
                  </span>
                </label>
              </div>
            )}

          <div
            className={twMerge(
              'w-full flex flex-col desktop:flex-row justify-end',
              questionnaireID === questionnairesIds.SIGN_UP_PROVIDER &&
                currentIndexSection.index === 2 &&
                'justify-between',
            )}
          >
            {questionnaireID === questionnairesIds.SIGN_UP_PROVIDER &&
              currentIndexSection.index === 2 && (
                <ButtonComponent
                  type="underline"
                  className="!text-sm !font-bold"
                  onClick={() => {
                    setDiscardMfaPhone(true);
                    setIsVerifyOpen(false);
                  }}
                >
                  {locale?.changePhoneNumber}
                </ButtonComponent>
              )}
            <div className="flex flex-col desktop:flex-row gap-[30px]">
              {questionnaireID === questionnairesIds.SIGN_UP_PROVIDER &&
                [5, 6].includes(currentIndexSection.index) && (
                  <ButtonComponent
                    type="underline"
                    className="!text-sm !font-bold"
                    onClick={onSkipSection}
                  >
                    {locale?.skipStep}
                  </ButtonComponent>
                )}
              <ButtonComponent
                onClick={handleSubmitClick}
                className="desktop:w-[112px] !text-sm !rounded mobile:mx-auto mt-[15px] desktop:mr-0 desktop:mt-0"
                disabled={disableSubmit}
                showSpinner={
                  questionnaireID !== questionnairesIds.SIGN_UP_PROVIDER
                }
              >
                {ctaLabel}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicQuestionnaireMainView;
