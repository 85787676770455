import {
  questionnaire_questions_question,
  QuestionnaireResponseInterface,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import { HormoneQuestionnairBanner } from './HormoneQuestionnairBanner';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import InputComponent from 'components/inputComponent';
import {
  hasMonthElapsed,
  MedicationItemResponse,
  MedicationItemResponseForFiltering,
} from '../utils/utils';
import DatePicker from '../../datePicker/DatePicker';
import React, { Dispatch, useContext, useState } from 'react';
import TimePickerComponent from '../../../app/manage-test-kits/components/questionare/Time';
import Alert from '../../alertComponent';
import { useGetComponent } from '../../../hooks/useGetComponent';
import {
  componentIds,
  GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS,
} from '../../../utilities/constants';
import {
  formatDateToCustomFormat,
  interpolateVariables,
} from '../../../utilities/functions';
import {
  FhirQuestionnaireNames,
  useGetFhirQuestionnaireResponseQuery,
} from '../../../graphql/generated/remote-schema-hasura';
import { AuthContext } from '../../../auth/context/AuthContext';

interface IHormonesAndMedications {
  testType: string;
  questionList: questionnaire_questions_question[];
  medicationList: QuestionnaireResponseInterface[];
  insertAfterLastMedication: () => void;
  removeMedication: (index: number) => void;
  showTimeElapsedAlert: boolean;
  setShowTimeElapsedAlert: Dispatch<React.SetStateAction<boolean>>;
  handleMedicationTextFieldResponses: (
    value: string,
    questionID: number,
    medicationID: number,
    responseProperty: string | null | undefined,
  ) => void;
}

export const HormonesAndMedications = ({
  testType,
  questionList,
  medicationList,
  insertAfterLastMedication,
  removeMedication,
  handleMedicationTextFieldResponses,
  showTimeElapsedAlert,
  setShowTimeElapsedAlert,
}: IHormonesAndMedications) => {
  const { data: dynamicQuestionnaireLocale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.HORMONE_QUESTIONNAIRE_MODULE,
  });

  const [medicationLastUpdatedDate, setMedicationLastUpdatedDate] =
    useState('');

  const { user: loggedUser } = useContext(AuthContext);

  const { loading: isfhirQuestionnaireDataLoading } =
    useGetFhirQuestionnaireResponseQuery({
      variables: {
        codexPatientId: loggedUser?.uuid || '',
        identifier: FhirQuestionnaireNames.HealthQuestionnaire,
        system: GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS.codex,
      },
      onCompleted(data) {
        setMedicationLastUpdatedDate(
          data?.getFHIRQuestionnaireResponse?.questionnaire_response
            ?.updated_at as string,
        );
        setShowTimeElapsedAlert(
          hasMonthElapsed(
            data.getFHIRQuestionnaireResponse.questionnaire_response
              .updated_at as string,
          ) as boolean,
        );
      },
    });

  const getValueByfilterAnswers = (
    answers: MedicationItemResponseForFiltering[] | undefined,
    questionID: number,
    inputLabel?: string | null,
    parseAsSelect?: boolean,
  ) => {
    if (answers && answers.length > 0) {
      const filteredAnswers = answers.filter(
        (answer) => answer.questionID === questionID,
      );
      const filteredAnswersWithInputLabel = answers.filter(
        (answer) =>
          answer.questionID === questionID && answer.inputLabel === inputLabel,
      );

      if (filteredAnswers.length > 0 && !parseAsSelect && inputLabel === null) {
        return filteredAnswers[0].response;
      } else if (
        filteredAnswers.length > 0 &&
        parseAsSelect &&
        inputLabel === null
      ) {
        return {
          value: filteredAnswers[0].response,
          label: filteredAnswers[0].response,
        };
      } else if (
        filteredAnswersWithInputLabel.length > 0 &&
        inputLabel &&
        !parseAsSelect
      ) {
        return filteredAnswersWithInputLabel[0].response;
      } else if (
        filteredAnswersWithInputLabel.length > 0 &&
        inputLabel &&
        parseAsSelect
      ) {
        return {
          value: filteredAnswersWithInputLabel[0].response,
          label: filteredAnswersWithInputLabel[0].response,
        };
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return (
    <div>
      <HormoneQuestionnairBanner testType={testType} />
      {showTimeElapsedAlert && isfhirQuestionnaireDataLoading && (
        <div className="grid my-5">
          <Alert
            type="info"
            text={
              <div>
                <div className="font-semibold">
                  {dynamicQuestionnaireLocale?.timeElapsedAlertTitle}
                </div>
                <div>
                  {interpolateVariables(
                    dynamicQuestionnaireLocale?.timeElapsedAlertDescription,
                    {
                      date: formatDateToCustomFormat(medicationLastUpdatedDate),
                    },
                  )}
                </div>
              </div>
            }
          />
        </div>
      )}
      {!medicationList.length && (
        <div className="flex flex-col items-center py-[30px] gap-2">
          <p className="text-h5 font-semibold text-btn-black">No medications</p>
          <p className="text-base font-medium text-med-gray-3">
            Press “Add medication” to start adding your medications
          </p>
          <button
            className="flex mb-[30px] mx-auto"
            onClick={insertAfterLastMedication}
          >
            <PlusIcon className="mr-2" />
            <span className="text-sm text-clc-blue font-bold underline uppercase">
              Add medication
            </span>
          </button>
        </div>
      )}
      {medicationList.map((medication, index) => {
        const getCurrentIndexOfMedicationList = medicationList.filter(
          (obj: QuestionnaireResponseInterface) => {
            if (obj.id === index) {
              return obj;
            } else {
              return obj;
            }
          },
        );

        const getCurrentIndexAnswers = () => {
          const medicationEntry =
            getCurrentIndexOfMedicationList[medication.id ?? 0];
          if (
            medicationEntry &&
            medicationEntry.answers !== undefined &&
            medicationEntry.answers.length > 0
          ) {
            const medicationAnswers = medicationEntry.answers;
            return medicationAnswers;
          } else {
            return [];
          }
        };

        return (
          <div className="max-w-[690px] h-[579px] rounded-[10px] mx-auto my-[30px] p-[30px] bg-base-content">
            <div className="flex items-center justify-between mb-5">
              <p className="text-h5 font-semibold text-charcoal-gray">{`Medication ${
                index + 1
              }`}</p>
              <button className="flex" onClick={() => removeMedication(index)}>
                <DeleteIcon className="mr-2" />
                <span className="text-sm text-clc-blue font-bold underline uppercase">
                  Remove medication
                </span>
              </button>
            </div>
            <div>
              <div className="mb-5">
                <label className="text-base font-semibold text-btn-black">
                  {questionList[0].questionnaire_questions_question.text}
                </label>
                <InputComponent
                  value={
                    getValueByfilterAnswers(
                      getCurrentIndexAnswers() as Pick<
                        MedicationItemResponse,
                        'questionID' | 'response'
                      >[],
                      questionList[0].questionnaire_questions_question.id,
                      null,
                    ) as string
                  }
                  noMarginBottom={true}
                  type="text"
                  placeholder={
                    dynamicQuestionnaireLocale?.medicationNamePlaceholder
                  }
                  onChange={(e) =>
                    handleMedicationTextFieldResponses(
                      e.target.value,
                      questionList[0].questionnaire_questions_question.id,
                      index,
                      questionList[0].questionnaire_questions_question
                        .response_property,
                    )
                  }
                />
              </div>

              <div className="mb-5">
                <label className="text-base font-semibold text-btn-black">
                  {questionList[1].questionnaire_questions_question.text}
                  <span className="text-clc-red font-bold">*</span>
                </label>
                <InputComponent
                  value={
                    getValueByfilterAnswers(
                      getCurrentIndexAnswers() as Pick<
                        MedicationItemResponse,
                        'questionID' | 'response'
                      >[],
                      questionList[1].questionnaire_questions_question.id,
                      null,
                    ) as string
                  }
                  noMarginBottom={true}
                  type="select"
                  placeholder={
                    dynamicQuestionnaireLocale?.deliveryMethodPlaceholder
                  }
                  selectInputProps={{
                    defaultValue:
                      dynamicQuestionnaireLocale?.deliveryMethodPlaceholder,
                    selectValues: (questionList[1]
                      ?.questionnaire_questions_question?.answers ||
                      []) as string[],
                    onSelectChange: (e) => {
                      handleMedicationTextFieldResponses(
                        e.target.value,
                        questionList[1].questionnaire_questions_question.id,
                        index,
                        questionList[1].questionnaire_questions_question
                          .response_property,
                      );
                    },
                    fullWidth: true,
                  }}
                />
              </div>

              <div className="flex mb-5 items-baseline gap-[60px]">
                <div className="w-1/2 flex flex-grow-0 gap-4">
                  <div className="w-full">
                    <label className="text-base h-6 font-semibold text-btn-black">
                      {questionList[2].questionnaire_questions_question.text}
                      <span className="text-clc-red font-bold">*</span>
                    </label>

                    <InputComponent
                      value={
                        getValueByfilterAnswers(
                          getCurrentIndexAnswers() as Pick<
                            MedicationItemResponse,
                            'questionID' | 'response'
                          >[],
                          questionList[2].questionnaire_questions_question.id,
                          null,
                        ) as string
                      }
                      noMarginBottom={true}
                      type="number"
                      placeholder={
                        dynamicQuestionnaireLocale?.integerInputPlaceholder
                      }
                      onChange={(e) =>
                        handleMedicationTextFieldResponses(
                          e.target.value,
                          questionList[2].questionnaire_questions_question.id,
                          index,
                          questionList[2].questionnaire_questions_question
                            .response_property,
                        )
                      }
                    />
                  </div>

                  <div className="w-full">
                    <label className="text-base h-6 block font-semibold text-btn-black">
                      {questionList[3].questionnaire_questions_question.text}
                    </label>

                    <InputComponent
                      value={
                        getValueByfilterAnswers(
                          getCurrentIndexAnswers() as Pick<
                            MedicationItemResponse,
                            'questionID' | 'response'
                          >[],
                          questionList[3].questionnaire_questions_question.id,
                          null,
                        ) as string
                      }
                      noMarginBottom={true}
                      type="select"
                      selectInputProps={{
                        defaultValue:
                          dynamicQuestionnaireLocale.selectInputPlaceholder,
                        selectValues: (questionList[3]
                          ?.questionnaire_questions_question
                          ?.dropdown_combo_answers || []) as string[],
                        onSelectChange: (e) => {
                          handleMedicationTextFieldResponses(
                            e.target.value,
                            questionList[3].questionnaire_questions_question.id,
                            index,
                            questionList[3].questionnaire_questions_question
                              .response_property,
                          );
                        },
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>

                <div className="flex-shrink">
                  <label className="text-base h-6 w-full font-semibold text-btn-black">
                    {questionList[4].questionnaire_questions_question.text}
                    <span className="text-clc-red font-bold">*</span>
                  </label>

                  <div className="flex items-center gap-4">
                    <InputComponent
                      value={
                        getValueByfilterAnswers(
                          getCurrentIndexAnswers() as Pick<
                            MedicationItemResponse,
                            'questionID' | 'response'
                          >[],
                          questionList[4].questionnaire_questions_question.id,
                          null,
                        ) as string
                      }
                      noMarginBottom={true}
                      type="number"
                      placeholder={
                        dynamicQuestionnaireLocale?.integerInputPlaceholder
                      }
                      onChange={(e) =>
                        handleMedicationTextFieldResponses(
                          e.target.value,
                          questionList[4].questionnaire_questions_question.id,
                          index,
                          questionList[4].questionnaire_questions_question
                            .response_property,
                        )
                      }
                    />
                    <span className="text-[#666666] whitespace-nowrap">
                      {dynamicQuestionnaireLocale?.frequencyPostfix}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex mb-5 items-baseline gap-[60px]">
                <div className="w-1/2 flex flex-grow-0 gap-4">
                  <div className="w-full">
                    <label className="text-base h-6 font-semibold text-btn-black">
                      {questionList[5].questionnaire_questions_question.text}
                      <span className="text-clc-red font-bold">*</span>
                    </label>

                    <div className="flex gap-4">
                      <InputComponent
                        value={
                          getValueByfilterAnswers(
                            getCurrentIndexAnswers() as Pick<
                              MedicationItemResponse,
                              'questionID' | 'response'
                            >[],
                            questionList[5].questionnaire_questions_question.id,
                            null,
                          ) as string
                        }
                        noMarginBottom={true}
                        type="number"
                        placeholder={
                          dynamicQuestionnaireLocale?.integerInputPlaceholder
                        }
                        onChange={(e) =>
                          handleMedicationTextFieldResponses(
                            e.target.value,
                            questionList[5].questionnaire_questions_question.id,
                            index,
                            questionList[5].questionnaire_questions_question
                              .response_property,
                          )
                        }
                      />

                      <InputComponent
                        value={
                          getValueByfilterAnswers(
                            getCurrentIndexAnswers() as Pick<
                              MedicationItemResponse,
                              'questionID' | 'response'
                            >[],
                            questionList[6].questionnaire_questions_question.id,
                            null,
                          ) as string
                        }
                        noMarginBottom={true}
                        type="select"
                        selectInputProps={{
                          defaultValue:
                            dynamicQuestionnaireLocale?.selectInputPlaceholder,
                          selectValues: (questionList[6]
                            ?.questionnaire_questions_question
                            ?.dropdown_combo_answers || []) as string[],
                          onSelectChange: (e) => {
                            handleMedicationTextFieldResponses(
                              e.target.value,
                              questionList[6].questionnaire_questions_question
                                .id,
                              index,
                              questionList[6].questionnaire_questions_question
                                .response_property,
                            );
                          },
                          fullWidth: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex mb-5 items-baseline gap-[60px]">
                <div className="w-1/2 flex flex-grow-0 gap-4">
                  <div className="w-full">
                    <label className="text-base h-6 font-semibold text-btn-black">
                      {questionList[7].questionnaire_questions_question.text}
                      <span className="text-clc-red font-bold">*</span>
                    </label>

                    <DatePicker
                      onChange={(e) => {
                        handleMedicationTextFieldResponses(
                          e.target.value,
                          questionList[7].questionnaire_questions_question.id,
                          index,
                          questionList[7].questionnaire_questions_question
                            .response_property,
                        );
                      }}
                      value={
                        getValueByfilterAnswers(
                          getCurrentIndexAnswers() as Pick<
                            MedicationItemResponse,
                            'questionID' | 'response'
                          >[],
                          questionList[7].questionnaire_questions_question.id,
                          null,
                        ) as string
                      }
                      isOpen={false}
                      inputClassNames="min-w-[171px]"
                    />
                  </div>
                  <div className="w-full ">
                    <TimePickerComponent
                      label={String(
                        questionList[8].questionnaire_questions_question.text,
                      )}
                      handleTimeChange={(timeValue) =>
                        handleMedicationTextFieldResponses(
                          timeValue,
                          questionList[8].questionnaire_questions_question.id,
                          index,
                          questionList[8].questionnaire_questions_question
                            .response_property,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {!!medicationList.length && (
        <button
          className="flex mb-[30px] mx-auto"
          onClick={insertAfterLastMedication}
        >
          <PlusIcon className="mr-2" />
          <span className="text-sm text-clc-blue font-bold underline uppercase">
            Add another medication
          </span>
        </button>
      )}

      <hr className="mt-5" />
      <div className="grid mb-[30px] mt-5">
        <Alert
          type="info"
          text={
            <div>
              {dynamicQuestionnaireLocale?.alertShareData}
              <a className="text-blue-600 underline cursor-pointer">
                {' '}
                Cameron Williamson
              </a>
            </div>
          }
        />
      </div>
    </div>
  );
};
