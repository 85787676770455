import moment from 'moment';

export const getAppointmentFullTimeDate = (date: string | undefined) => {
  if (!date) {
    return;
  }
  const time = moment(date);
  return time.format('dddd, MMMM D, YYYY, hh:mm A');
};

export const getAppointmentDate = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  const time = moment(date);
  return time.format('ddd, MMM, DD');
};

export const get12HourFormat = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  const [hour, min] = date.split(':');
  const time = moment().hour(+hour).minutes(+min);
  return time.format('hh:mm A');
};
