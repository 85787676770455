import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as Chevrone } from 'assets/icons/chevron-up-fix.svg';
import { TimeSlotType } from 'hooks/useSignUpProviderSchedule.';
import { add15Minutes, substract15Minutes } from '../utils/timeUtils';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { twMerge } from 'tailwind-merge';
import InputComponent from '../../inputComponent';
import ErrorMessageWithIcon from '../../errorMessageWithIcon';

interface ITimeSlotPicker {
  onRemoveHandler: () => void;
  slot: TimeSlotType;
  onSlotChangeHandler: (param: keyof TimeSlotType, value: string) => void;
  errorStatus?: boolean;
  errorMsg?: string;
  errorMsgWithIcon?: string;
}

const defaultTimePeriodOptions = ['AM', 'PM'];

const timeSlotBorderClasses = 'border border-med-gray-3 rounded h-[46px]';

export const TimeSlotPicker = ({
  onRemoveHandler,
  slot,
  onSlotChangeHandler,
  errorStatus,
  errorMsg,
  errorMsgWithIcon,
}: ITimeSlotPicker) => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  const isMobile = useIsMobile();

  const onIncrement = (
    hours: string,
    minutes: string,
    timePeriod: string,
    isPeriodStart: boolean,
  ) => {
    const {
      hours: newHours,
      minutes: newMinutes,
      timePeriod: newTimePeriod,
    } = add15Minutes(hours, minutes, timePeriod);

    if (isPeriodStart) {
      onSlotChangeHandler('fromHours', newHours);
      onSlotChangeHandler('fromMinutes', newMinutes);
      onSlotChangeHandler('fromTimePeriod', newTimePeriod);
    } else {
      onSlotChangeHandler('toHours', newHours);
      onSlotChangeHandler('toMinutes', newMinutes);
      onSlotChangeHandler('toTimePeriod', newTimePeriod);
    }
  };

  const onDecrement = (
    hours: string,
    minutes: string,
    timePeriod: string,
    isPeriodStart: boolean,
  ) => {
    const {
      hours: newHours,
      minutes: newMinutes,
      timePeriod: newTimePeriod,
    } = substract15Minutes(hours, minutes, timePeriod);

    if (isPeriodStart) {
      onSlotChangeHandler('fromHours', newHours);
      onSlotChangeHandler('fromMinutes', newMinutes);
      onSlotChangeHandler('fromTimePeriod', newTimePeriod);
    } else {
      onSlotChangeHandler('toHours', newHours);
      onSlotChangeHandler('toMinutes', newMinutes);
      onSlotChangeHandler('toTimePeriod', newTimePeriod);
    }
  };

  return (
    <div>
      <div className="flex w-full desktop:w-auto flex-row desktop:items-center gap-2.5 mt-2.5 desktop:mt-0">
        <div className="w-full flex flex-col desktop:flex-row desktop:items-center gap-2.5">
          <div className="grid grid-cols-[34px,1fr,20px] desktop:flex items-center gap-2.5">
            <p className="text-base flex-grow-0 text-end desktop:w-auto desktop:text-start font-semibold text-btn-black">
              {locale?.schedule?.from}
            </p>
            <div
              className={twMerge(
                'desktop:w-[157px] flex items-center gap-[5px] pl-2.5 ',
                !isMobile && timeSlotBorderClasses,
                !isMobile && errorStatus
                  ? 'border-alert-negative bg-error-yellow'
                  : '',
              )}
            >
              <input
                className={twMerge(
                  'w-8 h-8 pl-1 bg-transparent focus-visible:outline-none ',
                  isMobile && `${timeSlotBorderClasses} w-full `,
                  isMobile && errorStatus
                    ? 'border-alert-negative bg-error-yellow'
                    : '',
                )}
                value={slot.fromHours}
                onChange={(e) =>
                  onSlotChangeHandler('fromHours', e.currentTarget.value)
                }
                maxLength={2}
              />
              <span>:</span>
              <input
                className={twMerge(
                  'w-8 h-8 pl-1 bg-transparent focus-visible:outline-none ',
                  isMobile && `${timeSlotBorderClasses} w-full `,
                  isMobile && errorStatus
                    ? 'border-alert-negative bg-error-yellow'
                    : '',
                )}
                value={slot.fromMinutes}
                onChange={(e) =>
                  onSlotChangeHandler('fromMinutes', e.currentTarget.value)
                }
                maxLength={2}
              />

              {!isMobile ? (
                <input
                  className={
                    'w-8 h-8 pl-1 bg-transparent focus-visible:outline-none'
                  }
                  value={slot.fromTimePeriod}
                  maxLength={2}
                  onChange={(e) =>
                    onSlotChangeHandler('fromTimePeriod', e.currentTarget.value)
                  }
                />
              ) : (
                <InputComponent
                  type="select"
                  name="graphType"
                  selectInputProps={{
                    onSelectChange: (e) =>
                      onSlotChangeHandler(
                        'fromTimePeriod',
                        e.currentTarget.value,
                      ),
                    selectValues: defaultTimePeriodOptions,
                    fullWidth: true,
                    customClasses: twMerge(
                      'w-10 h-8 pr-3 bg-transparent focus-visible:outline-none ',
                      isMobile && `${timeSlotBorderClasses} w-full `,
                      isMobile && errorStatus
                        ? 'border-alert-negative bg-error-yellow'
                        : '',
                    ),
                  }}
                  value={slot.fromTimePeriod}
                />
              )}

              <div className="hidden desktop:flex h-[46px] border-l border-l-med-gray-3 flex-col justify-center">
                <button
                  onClick={() =>
                    onIncrement(
                      slot.fromHours,
                      slot.fromMinutes,
                      slot.fromTimePeriod,
                      true,
                    )
                  }
                  className="w-[25px] h-5 border-b border-b-med-gray-3 flex items-center justify-center"
                >
                  <Chevrone className="text-med-gray-3" />
                </button>
                <button
                  onClick={() =>
                    onDecrement(
                      slot.fromHours,
                      slot.fromMinutes,
                      slot.fromTimePeriod,
                      true,
                    )
                  }
                  className="w-[25px] h-5 flex items-center justify-center"
                >
                  <Chevrone className="text-med-gray-3 rotate-180" />
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-[34px,1fr,20px] desktop:flex items-center gap-2.5">
            <p className="text-base flex-grow-0 w-[34px] text-end desktop:w-auto desktop:text-start font-semibold text-btn-black">
              {locale?.schedule?.to}
            </p>
            <div
              className={twMerge(
                'desktop:w-[157px] flex items-center gap-[5px] pl-2.5 ',
                !isMobile && timeSlotBorderClasses,
                !isMobile && errorStatus
                  ? 'border-alert-negative bg-error-yellow'
                  : '',
              )}
            >
              <input
                className={twMerge(
                  'w-8 h-8 pl-1 bg-transparent focus-visible:outline-none ',
                  isMobile && `${timeSlotBorderClasses} w-full`,
                  isMobile && errorStatus
                    ? 'border-alert-negative bg-error-yellow'
                    : '',
                )}
                value={slot.toHours}
                maxLength={2}
                onChange={(e) =>
                  onSlotChangeHandler('toHours', e.currentTarget.value)
                }
              />
              <span>:</span>
              <input
                className={twMerge(
                  'w-8 h-8 pl-1 bg-transparent focus-visible:outline-none ',
                  isMobile && `${timeSlotBorderClasses} w-full`,
                  isMobile && errorStatus
                    ? 'border-alert-negative bg-error-yellow'
                    : '',
                )}
                value={slot.toMinutes}
                maxLength={2}
                onChange={(e) =>
                  onSlotChangeHandler('toMinutes', e.currentTarget.value)
                }
              />

              {!isMobile ? (
                <input
                  className={twMerge(
                    'w-8 h-8 pl-1 bg-transparent focus-visible:outline-none ',
                  )}
                  value={slot.toTimePeriod}
                  maxLength={2}
                  onChange={(e) =>
                    onSlotChangeHandler('toTimePeriod', e.currentTarget.value)
                  }
                />
              ) : (
                <InputComponent
                  type="select"
                  name="graphType"
                  selectInputProps={{
                    onSelectChange: (e) =>
                      onSlotChangeHandler(
                        'toTimePeriod',
                        e.currentTarget.value,
                      ),
                    selectValues: defaultTimePeriodOptions,
                    fullWidth: true,
                    customClasses: twMerge(
                      'w-10 h-8 pr-3 bg-transparent focus-visible:outline-none ',
                      isMobile && `${timeSlotBorderClasses} w-full`,
                      isMobile && errorStatus
                        ? 'border-alert-negative bg-error-yellow'
                        : '',
                    ),
                  }}
                  value={slot.toTimePeriod}
                />
              )}

              <div className="hidden desktop:flex h-[46px] border-l border-l-med-gray-3 flex-col justify-center">
                <button
                  onClick={() =>
                    onIncrement(
                      slot.toHours,
                      slot.toMinutes,
                      slot.toTimePeriod,
                      false,
                    )
                  }
                  className="w-[25px] h-5 border-b border-b-med-gray-3 flex items-center justify-center"
                >
                  <Chevrone className="text-med-gray-3" />
                </button>
                <button
                  onClick={() =>
                    onDecrement(
                      slot.toHours,
                      slot.toMinutes,
                      slot.toTimePeriod,
                      false,
                    )
                  }
                  className="w-[25px] h-5 flex items-center justify-center"
                >
                  <Chevrone className="text-med-gray-3 rotate-180" />
                </button>
              </div>
            </div>

            <button
              onClick={onRemoveHandler}
              className="w-6 text-h2 text-clc-blue font-bold"
            >
              <MinusIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="ml-[52px] desktop:ml-[46px]">
        {errorMsg && (
          <p data-testid="pw-error-msg" className="text-sm text-clc-red mt-2">
            {errorMsg}
          </p>
        )}
        {errorMsgWithIcon && (
          <ErrorMessageWithIcon message={errorMsgWithIcon} />
        )}
      </div>
    </div>
  );
};
