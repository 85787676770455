import DynamicSvg from './CodexLoader';
import { v4 as uuid } from 'uuid';

interface ILoader {
  testID?: string;
}

const Loader: React.FC<ILoader> = ({ testID }) => {
  const maskId = uuid();
  return (
    <div
      className="flex flex-col items-center justify-center"
      data-testid={testID}
    >
      <div className="w-[240px] h-[240px] relative">
        <DynamicSvg maskId={maskId} />
      </div>
    </div>
  );
};

export default Loader;
