import React, { useMemo } from 'react';
import DatePicker, {
  isToday,
  isYesterday,
} from 'components/datePicker/DatePicker';
import Time from './Time';
import { IQustionerySectionProps } from './SampleCollectionHeavyMetal';
import { useSampleCollectionHook } from './useSampleCollectionHook';
import DescriptionField from './DescriptionField';

const timeRanges = [
  { min: '5:00', max: '8:59' },
  { min: '9:00', max: '11:59' },
  { min: '17:00', max: '20:59' },
  { min: '21:00', max: '23:59' },
];

const SampleNeurotransmittersCollection: React.FC<IQustionerySectionProps> = ({
  questionList,
  localFormData,
  handleDataChange,
  persistentData,
}) => {
  const [date, ...times] = useMemo(() => questionList, [questionList]);

  const { handleChangeDate, handleTimeChange } = useSampleCollectionHook(
    localFormData,
    handleDataChange,
  );

  const handleUrineDate = (dateValue: string) => {
    handleDataChange(dateValue, null, date.questionnaire_questions_question.id);
    times.forEach(({ questionnaire_questions_question }) =>
      handleChangeDate(
        dateValue,
        String(questionnaire_questions_question.response_property),
      ),
    );
  };

  return (
    <div className="desktop:min-w-[565px] flex flex-col gap-5 py-[30px] desktop:pl-[120px]">
      <div>
        <div className="mb-0 text-neutral-800 text-base font-semibold">
          {date.questionnaire_questions_question.text}
          <span className="text-alert-negative">*</span>
        </div>
        <DatePicker
          onChange={(e) => {
            handleUrineDate(e.target.value);
          }}
          value={
            persistentData[date.questionnaire_questions_question.id] ||
            undefined
          }
          isOpen={false}
          hasError={
            persistentData[date.questionnaire_questions_question.id]?.length ===
            0
          }
          isAllowedDateToSelect={(date) => {
            return isYesterday(date) || isToday(date);
          }}
        />
        <DescriptionField>Today or yesterday</DescriptionField>
      </div>
      <div className="desktop:grid grid-cols-4 gap-5">
        {times.map((time, index) => {
          return (
            <Time
              key={time.questionnaire_questions_question.id}
              label={
                <>
                  {String(time.questionnaire_questions_question.text)}
                  <span className="text-alert-negative">*</span>
                </>
              }
              minTime={timeRanges[index].min}
              maxTime={timeRanges[index].max}
              handleTimeChange={(timeValue) =>
                handleTimeChange(
                  timeValue,
                  persistentData[date.questionnaire_questions_question.id] ||
                    undefined,
                  String(
                    time.questionnaire_questions_question.response_property,
                  ),
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default SampleNeurotransmittersCollection;
