import React, { useCallback, useMemo, useState } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/upArrow.svg';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './index.css';
import { get12HourFormat } from 'utilities/dateHelper';
import DescriptionField from './DescriptionField';
import moment from 'moment/moment';

type TimePickerValue = string | null;

interface ITimePickerProps {
  label: string | JSX.Element;
  handleTimeChange: (value: string) => void;
  maxTime?: string;
  minTime?: string;
  hasError?: boolean;
  defaultValue?: TimePickerValue;
}

const getTimeMomentObject = (time?: string | null) => {
  if (!time) {
    return null;
  }
  const [hour, min] = time.split(':');
  return moment().hour(+hour).minutes(+min);
};

const TimePickerComponent: React.FC<ITimePickerProps> = ({
  label,
  handleTimeChange,
  minTime,
  maxTime,
  hasError,
  defaultValue,
}) => {
  const [time, setTime] = useState<TimePickerValue>(defaultValue || null);

  const onTimeChange = useCallback(
    (time: string) => {
      handleTimeChange(time);
      setTime(time);
    },
    [handleTimeChange],
  );

  const incrementTime = () => {
    if (!time) {
      return;
    }
    const [hour, min] = time.split(':');
    const newTime = moment()
      .hour(+hour)
      .minutes(+min + 1);
    const timeLabel = newTime.format('HH:mm');
    onTimeChange(timeLabel);
  };

  const decrementTime = () => {
    if (!time) {
      return;
    }
    const [hour, min] = time.split(':');
    const newTime = moment()
      .hour(+hour)
      .minutes(+min - 1);
    const timeLabel = newTime.format('HH:mm');
    onTimeChange(timeLabel);
  };

  const hasDisabledUpButton = useMemo(() => {
    const currentTime = getTimeMomentObject(time);
    const maxMomemtTime = getTimeMomentObject(maxTime);
    if (!currentTime || !maxMomemtTime) {
      return false;
    }
    return currentTime.isSameOrAfter(maxMomemtTime);
  }, [maxTime, time]);

  const hasDisableDownButton = useMemo(() => {
    const currentTime = getTimeMomentObject(time);
    const minMomentTime = getTimeMomentObject(minTime);
    if (!currentTime || !minMomentTime) {
      return false;
    }
    return currentTime.isSameOrBefore(minMomentTime);
  }, [minTime, time]);

  return (
    <div className="flex flex-col gap-4 max-w-[160px]">
      <div className="flex flex-col">
        <label className="text-neutral-800 min-h-6 text-base font-semibold">
          {label}
        </label>
        <div className="relative flex items-center">
          <TimePicker
            onChange={(value) => onTimeChange(value as string)}
            value={time}
            disableClock
            format="hh:mm a"
            amPmAriaLabel="AM"
            hourPlaceholder="HH"
            hourAriaLabel="HH"
            minutePlaceholder="MM"
            minuteAriaLabel="MM"
            className={`py-1.5 px-2 pl-0 h-[48px] border bg-white text-sm w-full border-[#666666] border-r-transparent rounded-r-none
                     rounded-[5px] focus:outline-none
                    focus-within:border-blue-400 ${
                      hasError
                        ? 'border-alert-negative bg-error-yellow'
                        : 'input-border__gray'
                    }`}
            maxTime={maxTime}
            minTime={minTime}
            clockIcon={null}
            clearIcon={null}
          />
          <div className="flex flex-col">
            <button
              onClick={incrementTime}
              className="border input-border__gray px-1.5 h-[24px] rounded-tr disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={hasDisabledUpButton}
            >
              <Arrow className="fill-med-gray-3 h-[6px]" />
            </button>
            <button
              onClick={decrementTime}
              className="border input-border__gray px-1.5 h-[24px] rounded-br disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={hasDisableDownButton}
            >
              <Arrow className="rotate-180 fill-med-gray-3 h-[6px]" />
            </button>
          </div>
        </div>
        {minTime && maxTime && (
          <DescriptionField>
            {get12HourFormat(minTime)} - {get12HourFormat(maxTime)}
          </DescriptionField>
        )}
      </div>
    </div>
  );
};

export default TimePickerComponent;
