import { WarningIcon } from 'assets/icons/WarningIcon';
import IconComponent from './iconComponent';

interface ErrorMessageProps {
  message: string | undefined | null;
  classNames?: string;
}

const ErrorMessageWithIcon: React.FC<ErrorMessageProps> = ({
  message = '',
  classNames,
}) => {
  return (
    <div className={`flex items-center pb-1 ${classNames}`}>
      <div className="self-start">
        <IconComponent
          icon={WarningIcon}
          width="w-[15.25px]"
          height="h-[19.11px]"
          fill={'fill-alert-negative'}
        />
      </div>
      <p
        data-testid="pw-error-icon-msg"
        className="ml-1 text-[12px] text-clc-red font-medium"
      >
        {message}
      </p>
    </div>
  );
};

export default ErrorMessageWithIcon;
