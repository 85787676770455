import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as PDFIcon } from 'assets/icons/pdfBig.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { useCallback } from 'react';
import { Test_Types_Enum } from 'graphql/generated/hasura';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';

interface IHormoneQuestionnairBanner {
  testType: string;
}

export const HormoneQuestionnairBanner = ({
  testType,
}: IHormoneQuestionnairBanner) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REGISTER_TEST_KIT,
  });

  const { fetchHormoneInstructionsFromStorage } = useGetFileFromStorage();

  const onClickHandler = async () => {
    try {
      const pdfUrl = await fetchHormoneInstructionsFromStorage(testType);

      if (pdfUrl) {
        const pdfWindow = window.open();

        if (pdfWindow) {
          pdfWindow.location.href = pdfUrl;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const convertEnumTestTypeToLocaleTestType = useCallback(
    (newTestType: Test_Types_Enum): string => {
      switch (newTestType) {
        case Test_Types_Enum.DnaSkin:
          return locale?.dnaVisitSkinOption;

        case Test_Types_Enum.DnaVitamin:
          return locale?.dnaVisitVitaminOption;

        case Test_Types_Enum.ZrtHormone:
          return locale?.zrtHormoneOption;

        case Test_Types_Enum.ZrtHeavyMetals:
          return locale?.zrtheavyMetalsOption;

        case Test_Types_Enum.ZrtInflammatory:
          return locale?.zrtInflammatoryOption;

        case Test_Types_Enum.ZrtNeurotransmitters:
          return locale?.zrtNeurotransmitterOption;

        default:
          return locale?.dnaVisitSkinOption;
      }
    },
    [
      locale?.dnaVisitSkinOption,
      locale?.dnaVisitVitaminOption,
      locale?.zrtheavyMetalsOption,
      locale?.zrtHormoneOption,
      locale?.zrtInflammatoryOption,
      locale?.zrtNeurotransmitterOption,
    ],
  );

  const testTypeString = convertEnumTestTypeToLocaleTestType(
    testType as Test_Types_Enum,
  );

  if (loading) return null;

  return (
    <div className="mt-2.5 flex items-center justify-between bg-base-content py-3 px-5 border rounded-[10px]">
      <div className="flex items-center gap-3">
        <QuestionIcon />
        <span className="text-btn-black text-base font-semibold">{`${locale?.needHelpWithYour} ${testTypeString}?`}</span>
      </div>
      <div className="flex items-center gap-[2px]">
        <PDFIcon />
        <ButtonComponent
          onClick={onClickHandler}
          type="underline"
          className="!text-sm !font-bold !w-auto"
        >
          {`${testTypeString.split(' ')[0]} ${locale?.instruction}`}
        </ButtonComponent>
      </div>
    </div>
  );
};
