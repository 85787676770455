import InputComponent from 'components/inputComponent';
import { HormoneQuestionnairBanner } from './HormoneQuestionnairBanner';
import moment from 'moment';
import { questionnaire_questions_question } from '../interfaces/dynamicQuestionnaireResponse.interface';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

interface IIndividualInformation {
  testType: string;
  heightFt: string;
  heightIn: string;
  firstName: string;
  lastName: string;
  waist: string;
  weight: string;
  dob: string;
  gender: string;
  hasWeightError: boolean;
  hasWaistError: boolean;
  questionList: questionnaire_questions_question[];
  handleInputTextChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => void;
}

export const IndividualInformation = ({
  testType,
  heightIn,
  heightFt,
  dob,
  firstName,
  gender,
  lastName,
  waist,
  weight,
  hasWaistError,
  hasWeightError,
  questionList,
  handleInputTextChange,
}: IIndividualInformation) => {
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.HORMONE_QUESTIONNAIRE_MODULE,
  });

  if (loading) return null;

  return (
    <div>
      <HormoneQuestionnairBanner testType={testType} />
      <div className="max-w-[690px] mx-auto my-[30px]">
        <p className="text-h5 font-semibold text-charcoal-gray">
          {locale?.generalInformation}
        </p>
        <p className="text-base font-medium text-med-gray-3">
          {locale?.editInformation}
        </p>
        <div className="flex flex-col mt-5">
          <div className="flex mb-5">
            <div className="w-1/2">
              <p className="text-base font-semibold text-btn-black">
                {locale?.firstName}
              </p>
              <p className="text-sm font-medium text-med-gray-3">{firstName}</p>
            </div>
            <div className="w-1/2">
              <p className="text-base font-semibold text-btn-black">
                {locale?.lastName}
              </p>
              <p className="text-sm font-medium text-med-gray-3">{lastName}</p>
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2">
              <p className="text-base font-semibold text-btn-black">
                {locale?.dob}
              </p>
              <p className="text-sm font-medium text-med-gray-3">
                {dob ? moment(dob).format('MM/DD/YYYY') : ''}
              </p>
            </div>
            <div className="w-1/2">
              <p className="text-base font-semibold text-btn-black">
                {locale?.gender}
              </p>
              <p className="text-sm font-medium text-med-gray-3">{gender}</p>
            </div>
          </div>
        </div>
        <hr className="my-[30px]" />
        <p className="text-h5 font-semibold text-charcoal-gray">
          {locale?.parameters}
        </p>
        <p className="text-base font-medium text-med-gray-3 mb-5">
          {locale?.checkYourParameters}
        </p>
        <div className="flex gap-[30px]">
          <div className="flex items-end gap-3">
            <div className="flex flex-col">
              <label className="text-base font-semibold text-med-gray-3">
                {locale?.height}
              </label>
              <div className="flex">
                <div className="w-[90px]">
                  <InputComponent
                    isDisabled={true}
                    value={heightFt}
                    noMarginBottom={true}
                    type="text"
                  />
                </div>
                <p className="mt-3 ml-1 text-sm font-semibold text-med-gray-3">
                  {locale?.ft}
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="w-[90px]">
                <InputComponent
                  isDisabled={true}
                  value={heightIn}
                  noMarginBottom={true}
                  type="text"
                />
              </div>
              <p className="mt-3 ml-1 text-sm font-semibold text-med-gray-3">
                {locale?.in}
              </p>
            </div>
          </div>

          <div className="flex items-end gap-5">
            <div className="flex flex-col">
              <label className="text-base font-semibold text-btn-black">
                {locale?.weight}
                <span className="text-alert-negative">*</span>
              </label>
              <div className="flex">
                <div className="w-[90px]">
                  <InputComponent
                    value={weight}
                    onChange={(e) => handleInputTextChange(e, questionList[5])}
                    noMarginBottom={true}
                    type="text"
                    errorStatus={hasWeightError}
                  />
                </div>
                <p className="mt-3 ml-1 text-sm font-semibold text-med-gray-3">
                  {locale?.lbs}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-base font-semibold text-btn-black">
                {locale?.waist}
                <span className="text-alert-negative">*</span>
              </label>
              <div className="flex">
                <div className="w-[90px]">
                  <InputComponent
                    value={waist}
                    onChange={(e) => handleInputTextChange(e, questionList[6])}
                    noMarginBottom={true}
                    type="text"
                    errorStatus={hasWaistError}
                  />
                </div>
                <p className="mt-3 ml-1 text-sm font-semibold text-med-gray-3">
                  {locale?.in}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
