import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DatePickerProps } from './DatePicker.interfaces';
import { TabType } from './DatePicker.types';
import useOutsideClick from './hooks/useOutsideClick';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import { MAXIMUM_BACKWARDS_YEAR, componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import { DayTab } from './components/tabs/dayTab';
import { MonthTab } from './components/tabs/monthTab';
import { YearTab } from './components/tabs/yearTab';
import { addMaskToDate, validateDateFormat } from 'utilities/functions';
import IconComponent from 'components/iconComponent';
import moment from 'moment';

const ADULT_AGE = 18;
const MAXIMUM_AGE = new Date(1920, 0, 1);

const inputValueToDate = (value: string) => {
  return new Date(value.replace(/-/g, '/'));
};

export const formatDate = (date: Date) => {
  const formatNumber = (value: number) => (value < 10 ? '0' + value : value);
  // Magic number of +1 since Jan is 0 and Dec is 11
  const month = formatNumber(date.getMonth() + 1);
  const day = formatNumber(date.getDate());
  const year = date.getFullYear();

  return month + '/' + day + '/' + year;
};

export const isToday = (date: Date) => moment(date).isSame(moment(), 'day');
export const isYesterday = (date: Date) =>
  moment(date).isSame(moment().subtract(1, 'day'), 'day');

const getInitialDateFunc = () => null;

const DatePicker: React.FC<DatePickerProps> = ({
  id,
  value = '',
  name,
  onChange,
  isAllowedDateToSelect = () => true,
  getInitialDate = getInitialDateFunc,
  disableFutureDates = false,
  fullWidthDesktop = false,
  isOpen = false,
  onOpen,
  isDisabled = false,
  hasError = false,
  inputClassNames,
  hasAdultRestriction = false,
  disableInputChange = false,
}) => {
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DATEPICKER,
  });

  const nowDate: Date = useMemo(() => new Date(), []);
  const minimumAge: Date = useMemo(
    () => moment().subtract(ADULT_AGE, 'years').toDate(),
    [],
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(getInitialDate);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    nowDate.getMonth(),
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    nowDate.getFullYear(),
  );

  //Array of month weeks containing array of week dates
  const [displayedWeeks, setDisplayedWeeks] = useState<Date[][]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const [selectionTab, setSelectionTab] = useState<TabType>('day');
  const [yearsViewArray, setYearsViewArray] = useState<number[]>([]);
  const [triggerChange, setTriggerChange] = useState<boolean>(false);

  const datePickerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const resetValues = useCallback(() => {
    setSelectionTab('day');
    const resetMonth = selectedDate
      ? selectedDate.getMonth()
      : nowDate.getMonth();
    const resetYear = selectedDate
      ? selectedDate.getFullYear()
      : nowDate.getFullYear();
    setSelectedMonth(resetMonth);
    setSelectedYear(resetYear);
  }, [nowDate, selectedDate]);

  const closeDatePicker = useCallback(() => {
    setOpened(false);
    resetValues();
  }, [resetValues]);

  const onDayClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as Element;
    // class selection
    const dateElement = target.closest('.__day');
    const isExist = dateElement && dateElement instanceof HTMLElement;

    if (!isExist) return;

    const date = inputValueToDate(dateElement.id);

    if (!isAllowedDateToSelect(date)) {
      return;
    }

    if (disableFutureDates && date > nowDate) {
      return;
    }

    if (hasAdultRestriction && (date < MAXIMUM_AGE || date > minimumAge)) {
      return;
    }

    setSelectedDate(inputValueToDate(dateElement.id));
    setInputValue(dateElement.id);
    setOpened(false);
    setTriggerChange(true);
  };

  const onInputClick = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (opened) {
      closeDatePicker();
    } else {
      setOpened(true);
    }

    if (onOpen) {
      onOpen();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setOpened(true);
    } else {
      closeDatePicker();
    }
  }, [closeDatePicker, isOpen, onOpen]);

  const onNavClick = (
    e: React.MouseEvent<HTMLElement>,
    direction: 'next' | 'prev',
    dateElement: TabType = 'day',
  ) => {
    e.preventDefault();

    switch (dateElement) {
      case 'day': {
        if (direction === 'prev') {
          if (selectedMonth === 0) {
            if (selectedYear > MAXIMUM_BACKWARDS_YEAR) {
              setSelectedMonth(11);
              setSelectedYear(selectedYear - 1);
            }
          } else {
            setSelectedMonth(selectedMonth - 1);
          }
        }

        if (direction === 'next') {
          if (selectedMonth === 11) {
            if (selectedYear < MAXIMUM_BACKWARDS_YEAR) {
              return;
            }
            setSelectedMonth(0);
            setSelectedYear(selectedYear + 1);
          } else {
            setSelectedMonth(selectedMonth + 1);
          }
        }

        break;
      }

      case 'year': {
        if (direction === 'prev') {
          let startYear = yearsViewArray[0] - 20;
          if (startYear < MAXIMUM_BACKWARDS_YEAR) {
            startYear = MAXIMUM_BACKWARDS_YEAR;
          }

          if (startYear !== yearsViewArray[0]) {
            const years: number[] = [];
            for (let value = startYear; value < yearsViewArray[0]; value++) {
              years.push(value);
            }
            setYearsViewArray(years);
          }
        }

        if (direction === 'next') {
          const years: number[] = [];

          for (
            let value = yearsViewArray[yearsViewArray.length - 1] + 1;
            value <= yearsViewArray[yearsViewArray.length - 1] + 20;
            value++
          ) {
            years.push(value);
          }

          setYearsViewArray(years);
        }

        break;
      }
      default:
        break;
    }
  };

  const yearTabTitle = useMemo(
    () => `${yearsViewArray[0]} - ${yearsViewArray[yearsViewArray.length - 1]}`,
    [yearsViewArray],
  );

  // if value prop is passed, update the selectedDate
  useEffect(() => {
    if (!value) {
      const initial = getInitialDate();

      setSelectedDate(initial);
      setInputValue(initial ? formatDate(initial) : '');
    } else {
      const date = inputValueToDate(value);

      setInputValue(formatDate(date));
      setSelectedDate(date);
      setSelectedMonth(date.getMonth());
      setSelectedYear(date.getFullYear());
    }
  }, [getInitialDate, value]);

  // if triggerChange, call the onChange callback fn
  useEffect(() => {
    if (triggerChange && dateInputRef.current !== null && onChange) {
      onChange({ target: dateInputRef.current });
      setTriggerChange(false);
    }
  }, [triggerChange, onChange]);

  // handles outside click
  useOutsideClick(datePickerRef, closeDatePicker, opened);

  // if user changes the selectedYear, update the years array that is displayed
  useEffect(() => {
    const years: number[] = [];
    let startYear = selectedYear - 10;

    if (startYear < MAXIMUM_BACKWARDS_YEAR) {
      startYear = MAXIMUM_BACKWARDS_YEAR;
    }

    for (let value = startYear; value < startYear + 20; value++) {
      years.push(value);
    }

    setYearsViewArray(years);
  }, [selectedYear]);
  //get needed weeks and dates for the day tab view
  useEffect(() => {
    function getMonthLength(year: number, month: number) {
      const monthLastDate = new Date(year, month + 1, 0);
      return monthLastDate.getDate();
    }

    const currentMonthFirstDate: Date = new Date(
      selectedYear,
      selectedMonth,
      1,
    );
    const currentMonthFirstDay: number = currentMonthFirstDate.getDay();
    const currentMonthLastDate: number = getMonthLength(
      selectedYear,
      selectedMonth,
    );
    const calendarArray: Date[][] = [];

    // determine if showing 6 or 5 weeks for the currentMonth
    const numberOfWeeksToDisplay: number =
      currentMonthFirstDay + currentMonthLastDate > 35 ? 6 : 5;
    for (let week = 0; week < numberOfWeeksToDisplay; week++) {
      const weekArray: Date[] = [];
      const dayStartPosition: number = week * 7 - currentMonthFirstDay;

      for (let day = 1; day <= 7; day++) {
        const dayPosition = dayStartPosition + day;
        weekArray.push(new Date(selectedYear, selectedMonth, dayPosition));
      }
      calendarArray.push(weekArray);
    }
    setDisplayedWeeks(calendarArray);
  }, [selectedYear, selectedMonth]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (disableInputChange) return;

    if (!inputValue || value.length > inputValue.length) {
      value = addMaskToDate(value);
    }

    setInputValue(value);

    if (value.length !== 10) return;

    if (!validateDateFormat(value)) {
      setSelectedDate(getInitialDate());
      return;
    }

    const enteredDate = inputValueToDate(value);

    if (enteredDate.getFullYear() < MAXIMUM_BACKWARDS_YEAR) {
      const initial = getInitialDate();

      setSelectedDate(initial);
      setSelectedMonth(nowDate.getMonth());
      setSelectedYear(nowDate.getFullYear());
      setInputValue(initial ? formatDate(initial) : '');
      setTriggerChange(true);
      return;
    }

    if (
      !isAllowedDateToSelect(enteredDate) ||
      (disableFutureDates && enteredDate > nowDate) ||
      (hasAdultRestriction &&
        (enteredDate < MAXIMUM_AGE || enteredDate > minimumAge))
    ) {
      const initial = getInitialDate();

      setSelectedDate(initial);
      setSelectedMonth(nowDate.getMonth());
      setSelectedYear(nowDate.getFullYear());
      setInputValue(initial ? formatDate(initial) : '');
      setTriggerChange(true);
      return;
    }

    setTriggerChange(true);
  };

  const handleOnBlur = () => {
    if (!validateDateFormat(inputValue)) {
      const initial = getInitialDate();

      setSelectedDate(initial);
      setSelectedMonth(nowDate.getMonth());
      setSelectedYear(nowDate.getFullYear());
      setInputValue(initial ? formatDate(initial) : '');
      setTriggerChange(true);
    }
  };

  const isInputFocused = (): boolean => {
    return document.activeElement === dateInputRef.current;
  };

  if (loading && !locale) return null;

  return (
    <div className="w-full">
      <div
        className="inline relative"
        onClick={disableInputChange ? onInputClick : undefined}
      >
        <input
          disabled={isDisabled}
          className={`w-full h-[48px] p-[15px] text-sm text-[#262626] placeholder:font-semibold placeholder:text-[#666666] font-semibold border
                     rounded-[5px] focus:outline-none
                    focus:border-blue-400 ${
                      !fullWidthDesktop && 'desktop:w-40'
                    } ${inputClassNames ?? ''} ${
            hasError
              ? 'border-alert-negative bg-error-yellow'
              : 'input-border__gray'
          }`}
          type="text"
          id={id}
          value={
            selectedDate && !isInputFocused()
              ? formatDate(selectedDate)
              : inputValue
          }
          ref={dateInputRef}
          onBlur={handleOnBlur}
          placeholder="mm/dd/yyyy"
          name={name}
          onChange={handleOnChange}
        />

        <div
          className="absolute top-1/2 -translate-y-2/4 right-3"
          onClick={!isDisabled ? onInputClick : undefined}
        >
          <IconComponent icon={CalendarIcon} fill="fill-clc-blue" />
        </div>
      </div>

      {opened && (
        <div
          className="absolute border border-gray-opacity-15 rounded-[5px] w-[16rem] shadow-container"
          style={{ background: '#fff', zIndex: '1', padding: '20px' }}
          ref={datePickerRef}
        >
          {selectionTab === 'day' && (
            <DayTab
              isAllowedDateToSelect={isAllowedDateToSelect}
              currentMonth={locale.monthNames?.[selectedMonth]}
              setSelectionTab={setSelectionTab}
              selectedYear={selectedYear}
              weekDays={locale.weekDays}
              daysRowHeaderProps={{ weekDays: locale.weekDays }}
              monthDaysGridProps={{
                monthDays: displayedWeeks,
                selectedMonth: selectedMonth,
                selectedDate: selectedDate,
                onClick: onDayClick,
              }}
              datePickerNoticeProps={{ label: locale.availableDates }}
              onNavClickPrev={(e) => {
                onNavClick(e, 'prev', 'day');
              }}
              onNavClickNext={(e) => {
                onNavClick(e, 'next', 'day');
              }}
            />
          )}
          {selectionTab === 'month' && (
            <MonthTab
              title={locale.months}
              monthLabels={locale.monthNames}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              setSelectionTab={setSelectionTab}
            />
          )}
          {selectionTab === 'year' && (
            <YearTab
              title={yearTabTitle}
              yearsLabels={yearsViewArray}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              setSelectionTab={setSelectionTab}
              onNavClickPrev={(e) => {
                onNavClick(e, 'prev', 'year');
              }}
              onNavClickNext={(e) => {
                onNavClick(e, 'next', 'year');
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
