import React, { useMemo } from 'react';
import {
  DynamicQuestionResponseSection,
  questionnaire_questions_question,
  QuestionnaireResponseInterface,
} from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import { questionnairesIds } from 'utilities/constants';
import SampleCollectionHeavyMetal from './SampleCollectionHeavyMetal';
import SampleCollectionHormone from './SampleCollectionHormone';
import SampleHsCrpSection from './SampleHsCrpSection';
import SampleNeurotransmittersCollection from './SampleNeurotransmittersCollection';
import { HandleOnInputChangeType } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaire.interface';
import { HORMONE_QUESTIONNAIRE_SPECIFIC_IDS } from '../../../../components/dynamicQuestionnaire/utils/constants';

function transformPersistentData(dataArray: QuestionnaireResponseInterface[]) {
  return dataArray.reduce((acc, item) => {
    if (!item.questionID) return acc;

    acc[item.questionID as number] = (item.response as string) || null;
    return acc;
  }, {} as Record<number, string | null>);
}

interface IQustionerySectionProps {
  questionnaireId: number;
  questionList: questionnaire_questions_question[];
  section: DynamicQuestionResponseSection;
  localFormData: Record<string, string>;
  onChange: HandleOnInputChangeType;
  setLocalFormData: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  persistentData: QuestionnaireResponseInterface[];
}

const SampleCollectionSection: React.FC<IQustionerySectionProps> = ({
  questionnaireId,
  questionList,
  setLocalFormData,
  localFormData,
  onChange,
  persistentData,
}) => {
  const questionIdByProperty: Record<string, number> = useMemo(
    () =>
      questionList.reduce(
        (acc, { questionnaire_questions_question }) => ({
          ...acc,
          [String(questionnaire_questions_question.response_property)]:
            questionnaire_questions_question.id,
        }),
        {},
      ),
    [questionList],
  );

  const localData = transformPersistentData(persistentData);

  const handleDataChange = (
    date: string,
    property: string | null,
    id?: number | null | undefined,
  ) => {
    const questionID =
      id ||
      (property
        ? questionIdByProperty[property]
        : HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.SALIVA_COLLECTION_DATE);

    onChange(date, questionID);

    if (property) {
      setLocalFormData((prev: object) => ({ ...prev, [property]: date }));
    }
  };

  switch (questionnaireId) {
    case questionnairesIds.HEAVY_METALS_MALE:
    case questionnairesIds.HEAVY_METALS_FEMALE:
      return (
        <SampleCollectionHeavyMetal
          questionList={questionList}
          handleDataChange={handleDataChange}
          localFormData={localFormData}
          persistentData={localData}
        />
      );
    case questionnairesIds.HS_CRP_MALE:
    case questionnairesIds.HS_CRP_FEMALE:
      return (
        <SampleHsCrpSection
          questionList={questionList}
          handleDataChange={handleDataChange}
          localFormData={localFormData}
          persistentData={localData}
        />
      );
    case questionnairesIds.NEUROTRANSMITTER_MALE:
    case questionnairesIds.NEUROTRANSMITTER_FEMALE:
      return (
        <SampleNeurotransmittersCollection
          questionList={questionList}
          handleDataChange={handleDataChange}
          localFormData={localFormData}
          persistentData={localData}
        />
      );
    case questionnairesIds.HORMONE_QUESTIONNAIRE_MALE:
    case questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE:
      return (
        <SampleCollectionHormone
          questionList={questionList}
          handleDataChange={handleDataChange}
          localFormData={localFormData}
          persistentData={localData}
        />
      );
    default:
      return null;
  }
};

export default SampleCollectionSection;
