import Joi from 'utilities/joi';
import { TimeSlotType } from '../../../../hooks/useSignUpProviderSchedule.';

const timeToMinutes = (hours: string, minutes: string, period: string) => {
  let h = parseInt(hours, 10);
  const m = parseInt(minutes, 10);
  if (period === 'PM' && h !== 12) h += 12;
  if (period === 'AM' && h === 12) h = 0;
  return h * 60 + m;
};

const findOverlappingRanges = (ranges: TimeSlotType[]) => {
  if (!Array.isArray(ranges) || ranges.length <= 1) return null;

  const timeRanges = ranges.map((range, index) => ({
    index,
    from: timeToMinutes(
      range.fromHours,
      range.fromMinutes,
      range.fromTimePeriod,
    ),
    to: timeToMinutes(range.toHours, range.toMinutes, range.toTimePeriod),
  }));

  const errors: Record<number, string> = {};

  for (let i = 0; i < timeRanges.length; i++) {
    for (let j = i + 1; j < timeRanges.length; j++) {
      const a = timeRanges[i];
      const b = timeRanges[j];

      if (
        (a.from >= b.from && a.from < b.to) ||
        (a.to > b.from && a.to <= b.to) ||
        (b.from >= a.from && b.from < a.to) ||
        (b.to > a.from && b.to <= a.to)
      ) {
        errors[a.index] = 'scheduleOverlaps';
        errors[b.index] = 'scheduleOverlaps';
      }
    }
  }

  return Object.keys(errors).length > 0 ? errors : null;
};

const daySchema = Joi.array()
  .items(
    Joi.object({
      fromHours: Joi.string().required(),
      fromMinutes: Joi.string().required(),
      fromTimePeriod: Joi.string().valid('AM', 'PM').required(),
      toHours: Joi.string().required(),
      toMinutes: Joi.string().required(),
      toTimePeriod: Joi.string().valid('AM', 'PM').required(),
    }),
  )
  .custom((value, helpers) => {
    if (value.length <= 1) return value;

    const result = findOverlappingRanges(value);

    if (result) {
      return helpers.error('array.overlap', { conflicts: result });
    }
    return value;
  })
  .messages({
    'array.overlap': 'scheduleOverlaps',
  });

export const weekSchema = Joi.array().items(daySchema);
