import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import useOutsideClick from './hooks/useOutsideClick';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import { YearTab } from './components/tabs/yearTab';
import IconComponent from 'components/iconComponent';
import { noop } from 'lodash';

interface YearPickerProps {
  id?: string | undefined;
  value?: string;
  name?: string;
  isOpen?: boolean;
  onOpen?: () => void;
  isDisabled?: boolean;
  hasError?: boolean;
  inputClassNames?: string;
  minYear?: number;
  maxYear?: number;
  onChange?: (value: string) => void;
  placeholder?: string;
}

const DatePicker: React.FC<YearPickerProps> = ({
  id,
  value = '',
  name = '',
  onChange,
  isOpen = false,
  onOpen,
  isDisabled = false,
  hasError = false,
  inputClassNames,
  minYear = 2000,
  maxYear = new Date().getFullYear(),
  placeholder,
}) => {
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DATEPICKER,
  });
  const nowYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState<number | null>(
    value ? Number(value) : null,
  );
  const [yearsViewArray, setYearsViewArray] = useState<number[]>([]);
  const [opened, setOpened] = useState<boolean>(isOpen);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const formatYear = (year: number) => year.toString();

  const closeDatePicker = useCallback(() => {
    setOpened(false);
  }, []);

  const openDatePicker = useCallback(() => {
    if (isDisabled) return setOpened(false);
    setOpened(true);
    if (onOpen) onOpen();
  }, [onOpen, isDisabled]);

  const onYearSelect = (year: number) => {
    if (year < minYear || year > maxYear) return;
    setSelectedYear(year);
    if (onChange) {
      onChange(year.toString());
    }
    setOpened(false);
  };

  useEffect(() => {
    const years: number[] = [];
    const startYear = Math.max((selectedYear || nowYear) - 10, minYear);
    const endYear = Math.min(startYear + 19, maxYear);
    for (let value = startYear; value <= endYear; value++) {
      years.push(value);
    }
    setYearsViewArray(years);
  }, [selectedYear, minYear, maxYear, nowYear]);

  useOutsideClick(datePickerRef, closeDatePicker, opened);

  useEffect(() => {
    if (isOpen) {
      openDatePicker();
    } else {
      closeDatePicker();
    }
  }, [isOpen, openDatePicker, closeDatePicker]);

  useEffect(() => {
    if (value) {
      setSelectedYear(Number(value));
    } else {
      setSelectedYear(null);
    }
  }, [value]);

  if (loading && !locale) return null;

  return (
    <div className="w-full">
      <div className="inline relative" onClick={openDatePicker}>
        <input
          disabled={isDisabled}
          className={`w-full p-[15px] text-sm text-med-gray font-semibold border rounded-[5px] focus:outline-none
                      focus:border-blue-400 ${inputClassNames ?? ''} ${
            hasError
              ? 'border-alert-negative bg-error-yellow'
              : 'input-border__gray'
          }`}
          type="text"
          id={id}
          value={selectedYear !== null ? formatYear(selectedYear) : ''}
          ref={dateInputRef}
          name={name}
          placeholder={placeholder}
          readOnly
        />
        <div className="absolute top-1/2 -translate-y-2/4 right-3">
          <IconComponent icon={CalendarIcon} fill="fill-clc-blue" />
        </div>
      </div>
      {opened && (
        <div
          className="absolute border border-gray-opacity-15 rounded-[5px] w-[16rem] shadow-container"
          style={{ background: '#fff', zIndex: '1', padding: '20px' }}
          ref={datePickerRef}
        >
          <YearTab
            title={`${yearsViewArray[0]} - ${
              yearsViewArray[yearsViewArray.length - 1]
            }`}
            yearsLabels={yearsViewArray}
            selectedYear={selectedYear || nowYear}
            setSelectedYear={onYearSelect as Dispatch<SetStateAction<number>>}
            onNavClickPrev={() =>
              setSelectedYear(Math.max((selectedYear || nowYear) - 20, minYear))
            }
            onNavClickNext={() =>
              setSelectedYear(Math.min((selectedYear || nowYear) + 20, maxYear))
            }
            setSelectionTab={noop}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
