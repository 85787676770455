import React from 'react';
import { questionnaire_questions_question } from '../interfaces/dynamicQuestionnaireResponse.interface';
import { HormoneQuestionnairBanner } from 'components/dynamicQuestionnaire/components/HormoneQuestionnairBanner';
import { TestKitData } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaire.interface';

interface MenstrualStatusQuestionFactory {
  testKitData: TestKitData | undefined;
  questions: questionnaire_questions_question[];
  missingMainQuestionIds: string[];
  submitWasTriggered: boolean;
  isRequiredQuestion: (questionId: string) => boolean;
  renderMenstrualStatusQuestionFactory: (
    question: questionnaire_questions_question,
  ) => null | JSX.Element;
}

export const MenstrualStatusQuestionFactory: React.FC<
  MenstrualStatusQuestionFactory
> = ({
  testKitData,
  questions,
  renderMenstrualStatusQuestionFactory,
  missingMainQuestionIds,
  submitWasTriggered,
  isRequiredQuestion,
}) => {
  return (
    <div>
      <HormoneQuestionnairBanner testType={testKitData?.testType as string} />
      <div className="flex flex-col items-center">
        {questions.map((question) => (
          <div
            className={
              'desktop:w-[450px] flex flex-col justify-start mt-7 mb-4 desktop:flex-col'
            }
            key={question.questionnaire_questions_question.id}
          >
            <p
              className={`
                  flex
                  ${
                    missingMainQuestionIds.includes(
                      question.questionnaire_questions_question.id.toString(),
                    ) && submitWasTriggered
                      ? 'text-clc-red'
                      : 'text-dark-gray'
                  } font-semibold `}
            >
              {question.questionnaire_questions_question.text}
              {isRequiredQuestion(
                question.questionnaire_questions_question.id.toString(),
              ) && <span className="text-base font-bold text-clc-red"> *</span>}
            </p>
            {question.questionnaire_questions_question.subtitle && (
              <p className="italic text-med-gray">
                {question.questionnaire_questions_question.subtitle}
              </p>
            )}
            <div className={'flex w-full items-center !max-w-[100%]'}>
              {renderMenstrualStatusQuestionFactory(question)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
