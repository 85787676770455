import { useState } from 'react';
import { getTokenFromFirebase } from '../firebase/hooks';
import { getNestConfig } from '../utilities/config';
import {
  useGetFileUrlFromStorageMutation,
  useGetSignUrlFromStorageForRequesterMutation,
} from 'graphql/generated/hasura';

export const useGetFileFromStorage = () => {
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const [getFileUrlFromStorageForRequester] =
    useGetSignUrlFromStorageForRequesterMutation({});
  const [loading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { nestApi } = getNestConfig();

  const fetchFileFromStorage = async (
    fileId: string,
    patientId?: string,
  ): Promise<string> => {
    try {
      setIsLoading(true);
      const token = await getTokenFromFirebase();
      const headers = {
        Accept: 'application/pdf',
        Authorization: `Bearer ${token}`,
      };
      const fileUrl = patientId
        ? `${nestApi}/google-storage/file?fileId=${fileId}&patientId=${patientId}`
        : `${nestApi}/google-storage/file?fileId=${fileId}`;

      const options = {
        method: 'GET',
        headers: headers,
      };

      const res = await fetch(fileUrl, options);

      const blob = await res.blob();
      const blobUrl = URL.createObjectURL(blob);
      setIsLoading(false);
      setHasError(false);
      return blobUrl;
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.error(`There was an error fetching file, ${error}`);
      return '';
    }
  };

  const fetchHormoneInstructionsFromStorage = async (
    testType: string,
  ): Promise<string> => {
    try {
      setIsLoading(true);
      const token = await getTokenFromFirebase();
      const headers = {
        Accept: 'application/pdf',
        Authorization: `Bearer ${token}`,
      };

      const fileUrl = `${nestApi}/google-storage/instructions/${testType}`;

      const options = {
        method: 'GET',
        headers: headers,
      };

      const res = await fetch(fileUrl, options);

      const blob = await res.blob();
      const blobUrl = URL.createObjectURL(blob);
      setIsLoading(false);
      setHasError(false);
      return blobUrl;
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.error(`There was an error fetching file, ${error}`);
      return '';
    }
  };

  const fetchImage = async (fileId: string): Promise<string> => {
    try {
      setIsLoading(true);
      const image = await getFileUrlFromStorage({
        variables: {
          fileId,
        },
      });
      setIsLoading(false);
      setHasError(false);

      return image.data?.GetSignUrlFormStorage?.url || '';
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.error(`There was an error fetching file, ${error}`);
      return '';
    }
  };

  const fetchPatientImage = async (
    fileId: string,
    patientId: string,
  ): Promise<string> => {
    try {
      setIsLoading(true);
      const image = await getFileUrlFromStorageForRequester({
        variables: {
          fileId,
          requestedUserId: patientId,
        },
      });
      setIsLoading(false);
      setHasError(false);

      return image.data?.GetSignUrlFromStorageForRequester?.url || '';
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.error(`There was an error fetching file, ${error}`);
      return '';
    }
  };

  return {
    loading,
    hasError,
    fetchFileFromStorage,
    fetchImage,
    fetchPatientImage,
    fetchHormoneInstructionsFromStorage,
  };
};
