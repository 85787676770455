import React from 'react';
import { ReactComponent as Info } from 'assets/icons/info-gray.svg';
import { twMerge } from 'tailwind-merge';

interface IDescriptionFieldProps {
  children: React.ReactNode;
  className?: string;
}

const DescriptionField: React.FC<IDescriptionFieldProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={twMerge('flex items-center mt-0.5 text-[#666666]', className)}
    >
      <Info className="mr-1" />
      <span className="text-xs font-semibold">{children}</span>
    </div>
  );
};

export default DescriptionField;
