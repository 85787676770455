import Toggle from 'components/ToggleComponent';
import { TimeSlotType } from 'hooks/useSignUpProviderSchedule.';
import { Dispatch, useEffect, useState } from 'react';
import { TimeSlotPicker } from '../TimeSlotPicker';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { weekSchema } from './schedule.validation';

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const MAX_TIME_SLOTS_NUMBER = 6;

const INITIAL_TIME_SLOT = {
  fromHours: '00',
  fromMinutes: '00',
  fromTimePeriod: 'AM',
  toHours: '00',
  toMinutes: '00',
  toTimePeriod: 'PM',
};

interface ISignUpProviderSchedule {
  schedule?: Array<Array<TimeSlotType>>;
  setSchedule?: Dispatch<React.SetStateAction<Array<Array<TimeSlotType>>>>;
  isValid?: boolean;
  setIsValid?: (isValid: boolean) => void;
}

export const SignUpProviderSchedule = ({
  schedule,
  setSchedule,
  setIsValid,
}: ISignUpProviderSchedule) => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  const [errors, setErrors] = useState<Record<string, Record<number, string>>>(
    {},
  );

  const isMobile = useIsMobile();

  const onToggleHandler = (checked: boolean, index: number) => {
    setSchedule?.((prev) => {
      const newState = [...prev];
      newState[index] = checked ? [INITIAL_TIME_SLOT] : [];
      return newState;
    });
  };

  const getIsChecked = (index: number) => {
    return !!schedule?.[index].length;
  };

  const onAddButtonToggle = (index: number) => {
    setSchedule?.((prev) => {
      const newState = [...prev];
      newState[index] =
        newState[index].length < MAX_TIME_SLOTS_NUMBER
          ? [...newState[index], INITIAL_TIME_SLOT]
          : newState[index];
      return newState;
    });
  };

  const onRemoveHandler = (dayIndex: number, slotIndex: number) => {
    setSchedule?.((prev) => {
      const newState = [...prev];

      newState[dayIndex] = newState[dayIndex].filter(
        (_, index) => index !== slotIndex,
      );
      return newState;
    });
  };

  const onSlotChangeHandler = (
    dayIndex: number,
    slotIndex: number,
    param: keyof TimeSlotType,
    value: string,
  ) => {
    setSchedule?.((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));

      newState[dayIndex][slotIndex][param] = value;
      return newState;
    });
  };

  useEffect(() => {
    const { error } = weekSchema.validate(schedule);
    if (error) {
      const fieldErrors = error.details.reduce((acc, err) => {
        const dayIndex = err.context?.key as string;
        const slotErrors = err.context?.conflicts;

        if (dayIndex !== undefined && slotErrors) {
          acc[dayIndex] = slotErrors;
        }

        return acc;
      }, {} as Record<string, Record<string, string>>);

      setErrors(fieldErrors);
      return;
    }

    setErrors({});
  }, [schedule]);

  useEffect(() => {
    const isInvalid = Object.values(errors).some(Boolean);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setIsValid(!isInvalid);
  }, [errors, setIsValid]);

  return (
    <div className="mt-[30px]">
      <h5 className="text-title font-semibold text-btn-black mb-5">
        {locale?.schedule?.workingHours}
      </h5>
      {schedule?.map((day, index) => {
        const visibleAddButton = day.length < MAX_TIME_SLOTS_NUMBER;

        return (
          <div
            key={index}
            className="py-5 -ml-[5px] flex flex-col desktop:flex-row items-start"
          >
            <div className="flex items-center justify-between desktop:justify-start w-[calc(100%-30px)] desktop:w-[246px] mt-[9px]">
              <Toggle
                checked={getIsChecked(index)}
                labelLeft=""
                labelRight=""
                onChange={(checked) => onToggleHandler(checked, index)}
              />
              <span className="flex-grow ml-1 text-base font-semibold text-btn-black">
                {isMobile
                  ? DAYS_OF_WEEK[index].slice(0, 3)
                  : DAYS_OF_WEEK[index]}
              </span>
              {getIsChecked(index) ? (
                <span className="text-base flex-grow desktop:flex-grow-0 font-medium text-bright-green">
                  {locale?.schedule?.available}
                </span>
              ) : (
                <span className="text-base flex-grow desktop:flex-grow-0 font-medium text-med-gray-3">
                  {locale?.schedule?.unavailable}
                </span>
              )}
              <button
                onClick={() => onAddButtonToggle(index)}
                className="desktop:hidden text-mobileH2 text-clc-blue font-bold"
              >
                {visibleAddButton && '+'}
              </button>
            </div>
            <div className="flex-grow flex flex-col gap-2.5 items-center">
              {day.map((item, j) => {
                return (
                  <>
                    <TimeSlotPicker
                      slot={item}
                      onRemoveHandler={() => onRemoveHandler(index, j)}
                      errorStatus={!!errors?.[index]?.[j]}
                      errorMsgWithIcon={
                        locale?.validationError?.[errors?.[index]?.[j]]
                      }
                      onSlotChangeHandler={(
                        param: keyof TimeSlotType,
                        value: string,
                      ) => onSlotChangeHandler(index, j, param, value)}
                    />

                    <hr className="desktop:hidden w-full" />
                  </>
                );
              })}
            </div>

            <button
              onClick={() => onAddButtonToggle(index)}
              className="w-6 hidden desktop:block mt-[3px] text-mobileH2 text-clc-blue font-bold"
            >
              {visibleAddButton && '+'}
            </button>
          </div>
        );
      })}
    </div>
  );
};
