import { useCallback } from 'react';
import moment from 'moment/moment';

export const useSampleCollectionHook = (
  localFormData: Record<string, string>,
  handleDataChange: (
    date: string,
    property: string,
    id?: number | null,
  ) => void,
) => {
  const handleTimeChange = useCallback(
    (timeValue: string, date: string | undefined, property: string) => {
      if (!date) {
        return handleDataChange(timeValue, property);
      }
      const [hour, min] = (timeValue || '').split(':');
      const dateTime = moment(date)
        .hour(+hour)
        .minutes(+min)
        .utc(true)
        .toISOString();
      handleDataChange(dateTime, property);
    },
    [handleDataChange],
  );

  const handleChangeDate = (date: string, property: string) => {
    let lastTime = localFormData[property];
    if (!lastTime) {
      return;
    }
    const momentLastTime = moment(lastTime);
    if (momentLastTime.isValid()) {
      lastTime = momentLastTime.utc(false).format('HH:mm');
    }
    handleTimeChange(lastTime, date, property);
  };

  const getTime = (date: string | null) => {
    if (!date) null;
    return moment(date).utc(false).format('HH:mm');
  };

  return { handleTimeChange, handleChangeDate, getTime };
};
