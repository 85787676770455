import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ButtonComponent from 'components/button/buttonComponent';

import { ReactComponent as StripeIcon } from 'assets/icons/stripe.svg';
import { ReactComponent as CheckActiveIcon } from 'assets/icons/checkActive.svg';

import { ReactComponent as DollarIcon } from 'assets/icons/dollar.svg';
import { ModuleFormErrors } from '../interfaces/dynamicQuestionnaire.interface';
import Toggle from '../../ToggleComponent';
import InputComponent from '../../inputComponent';

import { useLocation } from 'react-router-dom';
import { useConnectStripeAccountMutation } from '../../../graphql/generated/hasura';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray.svg';
import { twMerge } from 'tailwind-merge';
import Joi from '../../../utilities/joi';
import toast from 'react-hot-toast';
import { useGetPage } from '../../../hooks/useGetPage';
import { pageIds, PRICE_REGEX } from '../../../utilities/constants';
import ErrorMessageWithIcon from '../../errorMessageWithIcon';

export interface IPriceData {
  isActive: boolean;
  price: number | null;
}
export interface ISignUpBillingService {
  setChatData: (priceData: IPriceData) => void;
  setVideoData: (priceData: IPriceData) => void;
  setModuleFormError: Dispatch<SetStateAction<ModuleFormErrors | null>>;
  moduleFormErrors: ModuleFormErrors | null;
  videoData: IPriceData;
  chatData: IPriceData;
  setIsBlockSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  updateIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IConnectStripeComponent {
  isConnectedStripe: boolean;
  onClickConnect: () => void;
  locale: Record<string, string>;
  errors: Record<string, string>;
}

interface IBillingSettingComponent {
  isConnectedStripe: boolean;
  setChatData: (priceData: IPriceData) => void;
  setVideoData: (priceData: IPriceData) => void;
  videoData: IPriceData;
  chatData: IPriceData;
  errors: Record<string, string>;
  locale: Record<string, string>;
}

interface IListItemWithIconMarker {
  icon: React.ElementType;
  text: string;
}

export const SignUpBillingServices = ({
  setChatData,
  setVideoData,
  videoData,
  chatData,
  updateIsValid,
  setIsBlockSignUp,
}: ISignUpBillingService) => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.BILLING_SERVICE_PROVIDER,
  });

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const [connectStripeAccountMutation] = useConnectStripeAccountMutation();

  const code = queryParams.get('code');

  const [isConnectedStripe, setIsConnectedStripe] = useState(!!code);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const clientId = process.env.REACT_APP_STRIPE_CLIENT_ID;

  const isFirstSendConnectStripe = useRef(true);

  const goToConnectStripePage = () => {
    setIsBlockSignUp(false);

    setTimeout(() => {
      const currentUrl = window.location.origin + window.location.pathname;
      const redirectUrl = `${currentUrl}`;

      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${encodeURIComponent(
        redirectUrl,
      )}`;
    }, 100);
  };

  const handleClickConnect = () => {
    goToConnectStripePage();
  };

  const serviceSchema = useMemo(
    () =>
      Joi.object({
        isActive: Joi.boolean(),
        price: Joi.when('isActive', {
          is: true,
          then: Joi.number()
            .min(20)
            .max(200)
            .custom((value, helpers) => {
              if (!PRICE_REGEX.test(value.toString())) {
                return helpers.error('number.invalidFormat');
              }
              return value;
            })
            .required()
            .messages({
              'number.base': locale?.invalidFormatRate || '',
              'number.invalidFormat': locale?.invalidFormatRate || '',
              'number.min': locale?.minimumRate20 || '',
              'number.max': locale?.maximumRate200 || '',
              'any.required': locale?.enterAmount || '',
            }),
          otherwise: Joi.number().allow(null),
        }),
      }),
    [locale],
  );

  const schema = useMemo(() => {
    return Joi.object({
      rateData: Joi.object({
        videoData: serviceSchema,
        chatData: serviceSchema,
      })
        .custom((value, helpers) => {
          if (!value.chatData?.isActive && !value.videoData?.isActive) {
            return helpers.error('any.invalid');
          }
          return value;
        })
        .messages({
          'any.invalid':
            locale?.atLeastOneServiceActive ||
            'Set at least one billing & service option.',
        }),
      isConnectedStripe: Joi.boolean()
        .valid(true)
        .required()
        .messages({
          'any.only': locale?.connectYourStripeAccount || '',
        }),
    });
  }, [
    locale?.atLeastOneServiceActive,
    locale?.connectYourStripeAccount,
    serviceSchema,
  ]);

  useEffect(() => {
    const { error } = schema.validate(
      { rateData: { chatData, videoData }, isConnectedStripe },
      { abortEarly: false },
    );

    if (error) {
      const newErrors = error.details.reduce((acc, err) => {
        const field = err.path.join('.');
        if (field === '') {
          acc['general'] = err.message;
        } else {
          acc[field] = err.message;
        }
        return acc;
      }, {} as Record<string, string>);

      setErrors(newErrors);
      updateIsValid(false);
    } else {
      setErrors({});
      updateIsValid(true);
    }
  }, [chatData, videoData, isConnectedStripe, schema, updateIsValid]);

  useEffect(() => {
    if (!code || !isFirstSendConnectStripe.current) return;

    isFirstSendConnectStripe.current = false;

    connectStripeAccountMutation({
      variables: {
        code: code,
      },
    })
      .then(() => {
        toast.success(locale?.successConnectStripeMessage);
        setIsConnectedStripe(true);
      })
      .catch(() => {
        toast.error(locale?.errorConnectStripeMessage);
        setIsConnectedStripe(false);
      });
  }, [locale, code, connectStripeAccountMutation]);

  if (!locale) return null;

  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <ConnectStripeComponent
          isConnectedStripe={isConnectedStripe}
          onClickConnect={handleClickConnect}
          locale={locale}
          errors={errors}
        />

        <BillingSetting
          isConnectedStripe={isConnectedStripe}
          setChatData={setChatData}
          setVideoData={setVideoData}
          videoData={videoData}
          chatData={chatData}
          errors={errors}
          locale={locale}
        />
      </div>
    </>
  );
};

function BillingSetting({
  isConnectedStripe,
  setChatData,
  setVideoData,
  chatData,
  videoData,
  errors,
  locale,
}: IBillingSettingComponent) {
  const classForLastItem =
    'w-full desktop:w-[200px] flex justify-start flex-col shrink-0';

  return (
    <div className="desktop:px-12 desktop:py-10 bg-white w-full rounded-2xl ">
      <div className="flex gap-5 mb-2.5">
        <h3 className="text-h6 font-semibold text-btn-black desktop:text-h4">
          {locale.title}
        </h3>

        {!isConnectedStripe ? (
          <div className="border rounded-xl px-2.5 font-bold text-h7 whitespace-nowrap flex items-center text-clc-red border-clc-red bg-[#FDF3F3]">
            {locale.noStripeConnectedBadge}
          </div>
        ) : (
          <div className="border rounded-xl px-2.5 font-bold text-h7 whitespace-nowrap flex items-center text-[#12831B] border-[#12831B] bg-[#F0FDF0]">
            {locale.stripeConnectedBadge}
          </div>
        )}
      </div>

      <div className="text-[#666666]">
        {locale.pleaseSelectServicesDescription}
      </div>
      <div className="text-[#666666] mb-2.5">
        <span className="font-semibold text-black">{locale.pleaseNote}: </span>
        {locale.eachRateForAppointment}
      </div>

      <hr />
      <div className="flex w-full justify-center py-[30px]">
        <div className="w-full max-w-[540px]">
          <div className="hidden desktop:flex w-full font-semibold py-2.5">
            <div className="w-full">{locale.servicesOffered}</div>
            <div className={classForLastItem}>{locale.rate}</div>
          </div>

          <hr />

          <div className="flex flex-col desktop:flex-row w-full justify-between py-4">
            <div className="flex items-center gap-[30px]">
              <Toggle
                onChange={(state) => {
                  setVideoData({
                    isActive: state,
                    price: 0,
                  });
                }}
                checked={videoData.isActive}
                labelLeft={''}
                labelRight={''}
              />

              <div className="flex items-center gap-2.5">
                <VideoIcon />

                <span className="text-[#666666]">{locale.video}</span>
              </div>
            </div>

            <div className={classForLastItem}>
              <InputComponent
                customInputClassname="w-full"
                noMarginBottom
                type="text"
                name="find"
                errorStatus={!!errors['rateData.videoData.price']}
                errorMsgWithIcon={errors['rateData.videoData.price']}
                placeholder={locale.amountPlaceholder}
                isDisabled={!videoData.isActive}
                maxLengthValue={6}
                decorator={<DollarIcon />}
                decoratorLeft
                value={videoData.price}
                onChange={(e) => {
                  setVideoData({
                    isActive: true,
                    price: +e.target.value,
                  });
                }}
              />
              <div
                className={twMerge(
                  'flex gap-1 text-[#666666] text-[12px] items-center ',
                  !videoData.isActive ? 'opacity-50 ' : '',
                  errors['rateData.videoData.price'] ? 'hidden' : '',
                )}
              >
                <InfoIcon />
                {locale.minimumRate20}
              </div>
            </div>
          </div>

          <hr />

          <div className="flex flex-col desktop:flex-row w-full justify-between py-4">
            <div className="flex items-center gap-[30px]">
              <Toggle
                onChange={(state) => {
                  setChatData({
                    isActive: state,
                    price: 0,
                  });
                }}
                checked={chatData.isActive}
                labelLeft={''}
                labelRight={''}
              />

              <div className="flex items-center gap-2.5">
                <ChatIcon />

                <span className="text-[#666666]">{locale.chat}</span>
              </div>
            </div>

            <div className={classForLastItem}>
              <InputComponent
                customInputClassname="w-full"
                noMarginBottom
                type="text"
                name="chatRate"
                errorStatus={!!errors['rateData.chatData.price']}
                errorMsgWithIcon={errors['rateData.chatData.price']}
                placeholder={locale.amountPlaceholder}
                maxLengthValue={6}
                isDisabled={!chatData.isActive}
                decorator={<DollarIcon />}
                decoratorLeft
                value={chatData.price}
                onChange={(e) => {
                  setChatData({
                    isActive: true,
                    price: +e.target.value,
                  });
                }}
              />
              <div
                className={twMerge(
                  'flex gap-1 text-[#666666] text-[12px] items-center ',
                  !chatData.isActive ? 'opacity-50' : '',
                  errors['rateData.chatData.price'] ? 'hidden' : '',
                )}
              >
                <InfoIcon />
                {locale.minimumRate20}
              </div>
            </div>
          </div>

          <ErrorMessageWithIcon
            classNames={errors.rateData ? 'visible' : 'invisible'}
            message={errors.rateData}
          />
        </div>
      </div>
    </div>
  );
}

function ConnectStripeComponent({
  isConnectedStripe,
  onClickConnect,
  locale,
  errors,
}: IConnectStripeComponent) {
  return (
    <div className="desktop:px-12 desktop:py-10 bg-white w-full rounded-2xl">
      <div className="desktop:flex desktop:items-center mb-[30px]">
        <StripeIcon />
        <h3 className="text-h6 font-semibold text-btn-black desktop:text-h5 desktop:ml-7">
          {locale.title2}
        </h3>
      </div>
      {!isConnectedStripe ? (
        <div className="desktop:flex desktop:gap-[60px]">
          <div className="desktop:w-1/2">
            <div className="text-[#666666] mb-5">
              {locale.descriptionNoConnectedStripe}
            </div>

            <div className="font-bold">{locale.descriptionConnectedStripe}</div>
          </div>

          <div className="desktop:w-1/2">
            <div className="desktop:flex desktop:gap-[60px] mb-5">
              <div>
                <ListItemWithIconMarker
                  icon={CheckActiveIcon}
                  text={locale.benefitItem1}
                />
                <ListItemWithIconMarker
                  icon={CheckActiveIcon}
                  text={locale.benefitItem2}
                />
                <ListItemWithIconMarker
                  icon={CheckActiveIcon}
                  text={locale.benefitItem3}
                />
              </div>

              <div>
                <ListItemWithIconMarker
                  icon={CheckActiveIcon}
                  text={locale.benefitItem4}
                />
                <ListItemWithIconMarker
                  icon={CheckActiveIcon}
                  text={locale.benefitItem5}
                />
                <ListItemWithIconMarker
                  icon={CheckActiveIcon}
                  text={locale.benefitItem6}
                />
              </div>
            </div>

            <div className="desktop:flex gap-[30px] items-center">
              <ButtonComponent onClick={onClickConnect}>
                {locale.connectedStripe}
              </ButtonComponent>
              <ErrorMessageWithIcon
                message={errors.isConnectedStripe}
                classNames={errors.isConnectedStripe ? 'visible' : 'invisible'}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="font-bold">{locale.yourStripeAccountConnected}</div>
          <div>{locale.allowsYouToAcceptStripePayments}</div>
        </div>
      )}
    </div>
  );
}

function ListItemWithIconMarker({ icon: Icon, text }: IListItemWithIconMarker) {
  return (
    <div className="flex gap-2.5 items-center">
      <div>
        <Icon />
      </div>
      <div>{text}</div>
    </div>
  );
}
